import { METHOD_GET } from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from '.'

const suffix = 'brands'

export const getBrands = (query_params = {}) =>
  nsfw(METHOD_GET, suffix, query_params)

export const getBrandBySlug = (slug, query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/${slug}`, query_params)

export const getCardsByBrandSlug = (slug, query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/${slug}/cards`, query_params)
