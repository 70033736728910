import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Table from 'components/organisms/Table'
import TableHeader from 'components/molecules/TableHeader'
import {
  getAuditMissionsAction,
  setAuditMissionsPerPageAction,
  setSelectedAuditMissionAction,
  setAuditMissionSearchAction,
} from 'domains/audit_missions/actions'
import { AUDIT_MISSION_CREATE_URL } from 'domains/audit_missions/urls'
import {
  auditMissionListSelector,
  auditMissionPaginationSelector,
  auditMissionPerPageSelector,
  selectedAuditMissionSelector,
  auditMissionSearchSelector,
} from 'domains/audit_missions/selectors'
import { getAuditMissionStatus } from 'domains/audit_missions/formatters'
import AuditMissionInDialog from 'domains/audit_missions/displayers/InDialog'
import WithPadding from 'components/templates/WithPadding'
import Dialog from 'components/templates/Dialog'
import BooleanIcon from 'components/atoms/BooleanIcon'

const columns = [
  { id: 'ref', disablePadding: false, label: 'Référence' },
  { id: 'title', disablePadding: false, label: 'Intitulé' },
  {
    id: 'operator_name',
    disablePadding: false,
    label: 'Opérateur',
  },
  { id: 'status', disablePadding: false, label: 'Statut' },
  { id: 'active', disablePadding: false, label: 'Actif' },
  {
    id: 'ends_at',

    disablePadding: false,
    label: 'Termine le',
  },
  {
    id: 'card_length',
    disablePadding: false,
    label: 'Audits',
  },
]

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
  },
})

const Index = (props) => {
  const {
    auditMissions,
    selectedAuditMission,
    pagination,
    perPage,
    search,
    getAuditMissions,
    setSelectedAuditMission,
    setAuditMissionsPerPage,
    setAuditMissionSearch,
  } = props

  const classes = useStyles()

  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    getAuditMissions()
  }, [getAuditMissions])

  const onRowClick = (item) => {
    setSelectedAuditMission(item)
    setDialogOpen(true)
  }

  const cellFormat = (item) => [
    item.ref,
    item.title,
    item.operator_name,
    getAuditMissionStatus(item.status),
    <BooleanIcon value={item.active} />,
    new Date(item.ends_at).toLocaleDateString('fr-FR'),
    item.audits_count,
  ]

  return (
    <>
      <WithPadding className={classes.root}>
        <TableHeader
          title="Liste des missions d'audit"
          createUrl={AUDIT_MISSION_CREATE_URL()}
          pagination={pagination}
          search={search}
          setItemSearch={setAuditMissionSearch}
          getItems={getAuditMissions}
        />
        <Table
          items={auditMissions}
          columns={columns}
          cellFormat={cellFormat}
          setItemsPerPage={setAuditMissionsPerPage}
          getItems={getAuditMissions}
          pagination={pagination}
          perPage={perPage}
          onRowClick={onRowClick}
        />
      </WithPadding>
      <Dialog
        fullWidth
        maxWidth={false}
        open={dialogOpen}
        setOpen={setDialogOpen}
        title="Trouver une mission d'audit"
      >
        <AuditMissionInDialog
          auditMission={selectedAuditMission}
          setOpen={setDialogOpen}
        />
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  auditMissions: auditMissionListSelector(state),
  selectedAuditMission: selectedAuditMissionSelector(state),
  pagination: auditMissionPaginationSelector(state),
  perPage: auditMissionPerPageSelector(state),
  search: auditMissionSearchSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  getAuditMissions: (urlParams, callbacks) =>
    dispatch(getAuditMissionsAction(urlParams, callbacks)),
  setAuditMissionsPerPage: (perPage) =>
    dispatch(setAuditMissionsPerPageAction(perPage)),
  setSelectedAuditMission: (auditMission) =>
    dispatch(setSelectedAuditMissionAction(auditMission)),
  setAuditMissionSearch: (search) =>
    dispatch(setAuditMissionSearchAction(search)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Index)
