import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Snackbar from 'components/organisms/Snackbar'
import { makeStyles } from '@material-ui/styles'
import Routes from 'env/routing'
import PictoLoader from 'components/atoms/PictoLoader'
import Topbar from 'components/molecules/Topbar'
import ConfirmationDialog from 'components/organisms/ConfirmationDialog'
import {
  setSnackbarAction,
  setConfirmationDialog as setConfirmationDialogAction,
  initializeAppAction,
} from 'domains/control/actions'
import {
  confirmationDialogSelector,
  snackbarSelector,
  loadingSelector,
} from 'domains/control/selectors'
import { isLogged } from 'api/utils/authentication'

const useStyles = makeStyles({
  root: {
    zIndex: 2,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  loader: ({ loading }) => ({
    display: loading ? 'flex' : 'none',
  }),
})

const Root = (props) => {
  const {
    confirmationDialog,
    snackbar,
    loading,
    setSnackbar,
    setConfirmationDialog,
    initializeApp,
  } = props
  const classes = useStyles({ loading })

  useEffect(() => {
    initializeApp()
  }, [initializeApp])

  return (
    <div className={classes.root}>
      {loading && <PictoLoader />}
      {isLogged() && <Topbar />}
      <Routes />
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
      <ConfirmationDialog
        confirmationDialog={confirmationDialog}
        setConfirmationDialog={setConfirmationDialog}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  confirmationDialog: confirmationDialogSelector(state),
  snackbar: snackbarSelector(state),
  loading: loadingSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setSnackbar: (attr, value) => dispatch(setSnackbarAction(attr, value)),
  setConfirmationDialog: (attr, value) =>
    dispatch(setConfirmationDialogAction(attr, value)),
  initializeApp: () => dispatch(initializeAppAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Root)
