/* eslint-disable no-alert */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Typography,
  Divider,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableCell,
  Chip,
  makeStyles,
} from '@material-ui/core'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import { filter } from 'lodash'
import CardSearcher from 'components/organisms/CardSearcher'
import CardSimpleTable from 'domains/cards/displayers/SimpleTable'
import { Delete, SupervisorAccount } from '@material-ui/icons'
import { popConfirmationDialogAction } from 'domains/control/actions'
import PermissionsModal from './PermissionsModal'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  textField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
}))

const AddCardToAudit = (props) => {
  const [searcherOpen, setSearcherOpen] = useState(false)
  const [actionsAnchor, setActionsAnchor] = useState(false)
  const [permissionModalOpen, setPermissionModalOpen] = useState(false)
  const classes = useStyles()

  const { className, selectedCards, setSelectedCards, popConfirmationDialog } =
    props

  const handleOnSelect = (card, event) => {
    setActionsAnchor({ anchor: event.currentTarget, card })
  }

  const removeCard = (card) => {
    setSelectedCards(
      filter(selectedCards, (selectedCard) => selectedCard.id !== card.id),
    )
  }

  const addOrRemoveCard = (card) => {
    const selectedCardsIds = selectedCards.map(
      (selectedCard) => selectedCard.id,
    )
    if (selectedCardsIds.includes(card.id)) {
      removeCard(card)
    } else {
      setSelectedCards([...selectedCards, card])
    }
  }

  const handlePermissionsChange = (card, permissionsState) => {
    const newSelectedCards = selectedCards
    if (card === null || card.id === undefined) {
      newSelectedCards.forEach((newSelectedCard, index) => {
        newSelectedCards[index].forbidden_features = permissionsState
      })
    } else {
      newSelectedCards.forEach((newSelectedCard, index) => {
        if (newSelectedCard.id === card.id) {
          newSelectedCards[index].forbidden_features = permissionsState
        }
      })
    }

    setSelectedCards(newSelectedCards)
    setActionsAnchor(null)
  }

  const closePermissionModal = () => {
    setPermissionModalOpen(false)
    setActionsAnchor(null)
  }

  const confirmRemoveCard = () => {
    popConfirmationDialog(
      "Modification de la mission d'audit",
      "Êtes-vous sûr de vouloir retirer ce lieu de la mission d'audit ?",
      () => {
        removeCard(actionsAnchor.card)
        setActionsAnchor(null)
      },
      () => {},
    )
  }

  const popoverOpen = Boolean(actionsAnchor && actionsAnchor.anchor)
  return (
    <PaperWithPadding className={`${classes.root} ${className}`}>
      <Typography variant="h5" gutterBottom className={classes.textField}>
        Liste des lieux à auditer
      </Typography>
      <Button
        className={classes.marginRight}
        variant="contained"
        color="primary"
        onClick={() => setSearcherOpen(true)}
      >
        Ajouter un lieu à auditer
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setPermissionModalOpen(true)
        }}
      >
        Permissions pour les audits
      </Button>
      <Divider className={classes.marginTop} />
      <CardSimpleTable
        onSelect={handleOnSelect}
        cards={selectedCards}
        customColumns={[
          {
            head: () => <TableCell key="permissions">Permissions</TableCell>,
            body: (card) =>
              card.forbidden_features && card.forbidden_features.length ? (
                <TableCell key="permissions">
                  <Chip
                    color="secondary"
                    label={`-${card.forbidden_features.length}`}
                  />
                </TableCell>
              ) : (
                <TableCell key="permissions" />
              ),
          },
        ]}
      />
      <CardSearcher
        open={searcherOpen}
        setOpen={setSearcherOpen}
        onSelect={addOrRemoveCard}
        selectedCards={selectedCards}
      />
      <Popover
        id="row-actions-popover"
        open={popoverOpen}
        anchorEl={actionsAnchor ? actionsAnchor.anchor : null}
        onClose={() => setActionsAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav">
          <ListItem
            button
            onClick={() => {
              setPermissionModalOpen(true)
            }}
          >
            <ListItemIcon>
              <SupervisorAccount />
            </ListItemIcon>
            <ListItemText primary="Gérer les permissions" />
          </ListItem>
          <ListItem button onClick={confirmRemoveCard}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Supprimer" />
          </ListItem>
        </List>
      </Popover>
      <PermissionsModal
        open={permissionModalOpen}
        onClose={closePermissionModal}
        card={actionsAnchor && actionsAnchor.card}
        setPermissions={handlePermissionsChange}
      />
    </PaperWithPadding>
  )
}

const mapDispatchToProps = (dispatch) => ({
  popConfirmationDialog: (title, message, onAgree, onCancel) =>
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel)),
})

export default connect(null, mapDispatchToProps)(AddCardToAudit)
