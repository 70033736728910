export const labelCriteriaEnum = (key) => {
  switch (key) {
    case 'explanation':
      return 'Description du critère'
    case 'name':
      return 'Intitulé du critère'
    case 'question':
      return 'Question posée'
    case 'ref':
      return 'Référence'
    case 'criteria_choices.label':
      return "Label d'un choix"
    default:
      return ''
  }
}

export const CHOICES_TYPES = [
  { value: 'radio', label: 'Choix unique' },
  { value: 'radio_extended', label: 'Choix unique avec détails' },
  { value: 'checkbox', label: 'Choix multiple' },
  { value: 'checkbox_extended', label: 'Choix multiple avec détails' },
  { value: 'text', label: 'Texte' },
]

export const choiceTypesEnum = (key) => {
  switch (key) {
    case 'radio':
      return 'Choix unique'
    case 'radio_extended':
      return 'Choix unique avec détails'
    case 'checkbox':
      return 'Choix multiples'
    case 'checkbox_extended':
      return 'Choix multiples avec détails'
    case 'text':
      return 'Texte seulement'
    default:
      return ''
  }
}
