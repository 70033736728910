import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Table from 'components/organisms/Table'
import HandicapIconsPopper from 'components/atoms/HandicapIconsPopper'
import TableHeader from 'components/molecules/TableHeader'
import {
  getCriteriasAction,
  setSelectedCriteriaAction,
  setCriteriasPerPageAction,
  setCriteriaSearchAction,
} from 'domains/criterias/actions'
import {
  criteriaListSelector,
  criteriaPaginationSelector,
  criteriaPerPageSelector,
  criteriaSearchSelector,
  selectedCriteriaSelector,
} from 'domains/criterias/selectors'
import { CRITERIA_CREATE_URL } from 'domains/criterias/urls'
import Dialog from 'components/templates/Dialog'
import FullCriteria from 'domains/criterias/displayers/Full'
import WithPadding from 'components/templates/WithPadding'
import BooleanIcon from 'components/atoms/BooleanIcon'

const columns = [
  { id: 'ref', disablePadding: false, label: 'Référence' },
  { id: 'title', disablePadding: false, label: 'Intitulé' },
  {
    id: 'question',
    disablePadding: false,
    label: 'Question posée',
  },
  { id: 'status', disablePadding: false, label: 'Publié' },
  { id: 'archived', disablePadding: false, label: 'Archivé' },
  {
    id: 'handicaps',
    disablePadding: false,
    label: 'Handicaps',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  color_primary: {
    color: theme.palette.primary.main,
  },
  bold: {
    fontWeight: 'bold',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const All = (props) => {
  const {
    criterias,
    selectedCriteria,
    pagination,
    perPage,
    search,
    getCriterias,
    setSelectedCriteria,
    setCriteriaSearch,
    setCriteriasPerPage,
  } = props

  const classes = useStyles()

  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    getCriterias()
  }, [getCriterias])

  const onRowClick = (item) => {
    setSelectedCriteria(item)
    setDialogOpen(true)
  }

  const cellFormat = (item) => [
    <Typography className={classes.bold}>{item.ref}</Typography>,
    item.name,
    item.question,
    <BooleanIcon value={item.published} />,
    <BooleanIcon value={item.archived} />,
    <HandicapIconsPopper handicaps={item.handicaps} />,
  ]

  return (
    <WithPadding className={classes.root}>
      <TableHeader
        title="Liste des critères"
        createUrl={CRITERIA_CREATE_URL()}
        pagination={pagination}
        search={search}
        setItemSearch={setCriteriaSearch}
        getItems={getCriterias}
      />
      <Table
        className={classes.table}
        onRowClick={onRowClick}
        items={criterias}
        columns={columns}
        cellFormat={cellFormat}
        setItemsPerPage={setCriteriasPerPage}
        getItems={getCriterias}
        pagination={pagination}
        perPage={perPage}
      />
      <Dialog open={dialogOpen} setOpen={setDialogOpen} title="Critère">
        <FullCriteria criteria={selectedCriteria} setOpen={setDialogOpen} />
      </Dialog>
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  criterias: criteriaListSelector(state),
  pagination: criteriaPaginationSelector(state),
  perPage: criteriaPerPageSelector(state),
  search: criteriaSearchSelector(state),
  selectedCriteria: selectedCriteriaSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  getCriterias: (urlParams, callbacks) =>
    dispatch(getCriteriasAction(urlParams, callbacks)),
  setCriteriasPerPage: (perPage) =>
    dispatch(setCriteriasPerPageAction(perPage)),
  setSelectedCriteria: (criteria) =>
    dispatch(setSelectedCriteriaAction(criteria)),
  setCriteriaSearch: (search) => dispatch(setCriteriaSearchAction(search)),
})

export default connect(mapStateToProps, mapDispatchToProps)(All)
