import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Input,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { find, without } from 'lodash'

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
    flex: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MultiSelect = ({ label, handleChange, suggestions, value }) => {
  const classes = useStyles()

  const deleteValue = (id) => {
    handleChange(without(value, id).join(','))
  }

  const renderValue = (selected) => (
    <div className={classes.chips}>
      {selected.map((suggestionId) => {
        const suggestion = find(suggestions, {
          id: parseInt(suggestionId, 10),
        })

        return (
          suggestion && (
            <Chip
              key={suggestion.id}
              label={suggestion.name}
              onDelete={() => deleteValue(`${suggestion.id}`)}
              className={classes.chip}
            />
          )
        )
      })}
    </div>
  )

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
      <Select
        value={value}
        multiple
        onChange={(e) => handleChange(e.target.value.join(','))}
        input={<Input id="select-multiple-chip" />}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {suggestions.map((suggestion) => (
          <MenuItem key={suggestion.id} value={`${suggestion.id}`}>
            {suggestion.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiSelect
