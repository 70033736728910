import React from 'react'
import { makeStyles } from '@material-ui/styles'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  actionBase: {
    padding: 0,
    minWidth: 0,
    marginTop: theme.spacing(1),
  },
  actionUp: {
    marginBottom: -1,
  },
  actionDown: {},
}))

const Controls = (props) => {
  const {
    actionUp,
    actionDown,
    variant = 'contained',
    className,
    disabled = false,
    disabledUp = false,
    disabledDown = false,
  } = props
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${className}`}>
      <Button
        className={`${classes.actionBase} ${classes.actionUp}`}
        color="primary"
        disabled={disabled || disabledUp}
        variant={variant}
        onClick={actionUp}
      >
        <KeyboardArrowUp />
      </Button>
      <Button
        className={`${classes.actionBase} ${classes.actionDown}`}
        color="primary"
        disabled={disabled || disabledDown}
        variant={variant}
        onClick={actionDown}
      >
        <KeyboardArrowDown />
      </Button>
    </div>
  )
}

export default Controls
