import {
  SET_SNACKBAR,
  POP_SNACKBAR,
  SET_CONFIRMATION_DIALOG,
  POP_CONFIRMATION_DIALOG,
  SET_LOADING,
} from './types'

const initialState = {
  redirection: '',
  snackbar: {
    open: false,
    message: '',
    type: '',
  },
  confirmation_dialog: {
    open: false,
    title: '',
    message: '',
    onAgree: null,
    onCancel: null,
  },
  all_categories: [],
  all_tags: [],
  all_brands: [],
  loading: false,
  user_auth_token: '',
}

const control = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          [action.attribute]: action.value,
        },
      }
    case POP_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: true,
          message: action.message,
          type: action.snack_type,
        },
      }
    case SET_CONFIRMATION_DIALOG:
      return {
        ...state,
        confirmation_dialog: {
          ...state.confirmation_dialog,
          [action.attribute]: action.value,
        },
      }
    case POP_CONFIRMATION_DIALOG:
      return {
        ...state,
        confirmation_dialog: {
          open: true,
          title: action.title,
          message: action.message,
          onAgree: action.onAgree,
          onCancel: action.onCancel,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default control
