import React from 'react'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  iconLeft: { marginRight: theme.spacing(2) },
  iconRight: { marginLeft: theme.spacing(2) },
}))

const ButtonCustom = ({ children, iconLeft, iconRight, ...rest }) => {
  const classes = useStyles()

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button variant="contained" color="primary" {...rest}>
      {iconLeft && <Icon className={classes.iconLeft}>{iconLeft}</Icon>}
      {children}
      {iconRight && <Icon className={classes.iconRight}>{iconRight}</Icon>}
    </Button>
  )
}

export default ButtonCustom
