import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Typography, Fab, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Edit from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import Delete from '@material-ui/icons/Delete'
import { map, orderBy, get } from 'lodash'
import 'moment/locale/fr'
import moment from 'moment'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import DetailRow from 'components/molecules/DetailRow'
import BooleanIcon from 'components/atoms/BooleanIcon'
import {
  setSelectedTemplateAction,
  deleteTemplateAction,
  duplicateTemplateAction,
} from '../actions'
import { TEMPLATE_EDIT_URL } from '../urls'

moment.locale('fr')
const useStyles = makeStyles((theme) => ({
  root: ({ onClick }) => ({
    '&:hover': {
      backgroundColor: onClick ? theme.palette.grey[200] : 'unset',
      cursor: 'pointer',
    },
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  fab: {
    marginLeft: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    margin: 0,
  },
  link: {
    marginLeft: theme.spacing(1),
  },
  linkItem: {
    display: 'flex',
  },
}))

const Full = (props) => {
  const {
    template,
    deleteTemplate,
    duplicateTemplate,
    setSelectedTemplate,
    pushUrl,
    noAction = false,
    onClick = null,
    setOpen,
  } = props
  const classes = useStyles({ onClick })

  const handleEdit = (id) => {
    pushUrl(TEMPLATE_EDIT_URL(id))
    setSelectedTemplate(null)
  }

  return (
    template && (
      <PaperWithPadding className={classes.root} onClick={onClick}>
        <Typography gutterBottom variant="h5">
          {`Template ${template.ref}`}
        </Typography>
        <Divider className={classes.divider} />
        <DetailRow title="Nom">{template.name}</DetailRow>
        {template.message && (
          <DetailRow title="Description">
            <Typography>{template.description}</Typography>
          </DetailRow>
        )}
        <DetailRow title="Publié">
          <BooleanIcon value={template.published} />
        </DetailRow>
        <DetailRow title="Privé">
          <BooleanIcon value={template.private} />
        </DetailRow>
        <DetailRow wrap title="Marques">
          <ul className={classes.list}>
            {map(template.brands, (brand) => (
              <li key={brand.id} className={classes.linkItem}>
                <Typography>{brand.name}</Typography>
              </li>
            ))}
          </ul>
        </DetailRow>
        <DetailRow wrap title="Catégories">
          <ul className={classes.list}>
            {map(
              orderBy(template.categories, ['depth'], ['desc']),
              (category) => (
                <li key={category.id} className={classes.linkItem}>
                  <Typography>{category.name}</Typography>
                </li>
              ),
            )}
          </ul>
        </DetailRow>
        <DetailRow wrap title="Tags">
          <ul className={classes.list}>
            {map(template.tags, (tag) => (
              <li key={tag.id} className={classes.linkItem}>
                <Typography>{tag.name}</Typography>
              </li>
            ))}
          </ul>
        </DetailRow>
        <DetailRow title="Bonnes pratiques associée">
          {template.good_practice &&
            `[${get(template, 'good_practice.ref')}] ${get(
              template,
              'good_practice.title',
            )}`}
        </DetailRow>
        <DetailRow title="Créé le">
          {moment(template.created_at).format('L')}
        </DetailRow>
        <DetailRow title="Mis à jour le">
          {moment(template.updated_at).format('L')}
        </DetailRow>
        {!noAction && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.actions}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.fab}
                onClick={() => handleEdit(template.id)}
              >
                <Edit />
              </Fab>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.fab}
                onClick={() => duplicateTemplate(template.id)}
              >
                <FileCopy />
              </Fab>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.fab}
                onClick={() => {
                  deleteTemplate(template.id)
                  setOpen(false)
                }}
              >
                <Delete />
              </Fab>
            </div>
          </>
        )}
      </PaperWithPadding>
    )
  )
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplate: (template) => {
    dispatch(setSelectedTemplateAction(template))
  },
  deleteTemplate: (id) => {
    dispatch(deleteTemplateAction(id))
  },
  duplicateTemplate: (id) => {
    dispatch(duplicateTemplateAction(id))
  },
  pushUrl: (url) => {
    dispatch(push(url))
  },
})

export default connect(null, mapDispatchToProps)(Full)
