import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'
import ListPermissionsModal from './ListPermissionsModal'

const PermissionsModal = (props) => {
  const [permissionsState, setPermissionsState] = useState([])
  const { card, open, onClose, setPermissions } = props

  const onEntered = () => {
    if (card && card.forbidden_features) {
      setPermissionsState(card.forbidden_features)
    } else {
      setPermissionsState([])
    }
  }

  const handleClose = () => {
    setPermissionsState([])
    onClose()
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      TransitionProps={{
        onEntered: () => onEntered(),
      }}
      onClose={handleClose}
      aria-labelledby="permission-dialog"
    >
      <DialogTitle id="permission-dialog">Définir les permissions</DialogTitle>
      <div>
        <ListPermissionsModal
          permissionsState={permissionsState}
          setPermissionsState={setPermissionsState}
        />
      </div>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setPermissions(card, permissionsState)
            onClose()
          }}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PermissionsModal
