import { METHOD_GET } from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from '.'

const suffix = 'cards'

export const getCardsWithSearch = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/search_text`, query_params)

export const getCardsWithSearchRansack = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/search_ransack`, query_params)
