import React from 'react'
import { Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import moment from 'moment'
import 'moment/locale/fr'
import DetailRow from 'components/molecules/DetailRow'
import BooleanIcon from 'components/atoms/BooleanIcon'
import { get } from 'lodash'

moment.locale('fr')
const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const FullTemplatePreviewPrint = (props) => {
  const { template } = props
  const classes = useStyles()

  return (
    template && (
      <PaperWithPadding>
        <Typography gutterBottom variant="h5">
          {`Template ${template.ref}`}
        </Typography>
        <Divider className={classes.divider} />
        <DetailRow title="Nom">{template.name}</DetailRow>
        {template.message && (
          <DetailRow title="Description">
            <Typography>{template.description}</Typography>
          </DetailRow>
        )}
        <DetailRow title="Publié">
          <BooleanIcon value={template.published} />
        </DetailRow>
        <DetailRow title="Privé">
          <BooleanIcon value={template.private} />
        </DetailRow>
        <DetailRow title="Créé le">
          {moment(template.created_at).format('L')}
        </DetailRow>
        <DetailRow title="Mis à jour le">
          {moment(template.updated_at).format('L')}
        </DetailRow>
        <DetailRow title="Mis à jour le">
          {template.good_practice &&
            `[${get(template, 'good_practice.ref')}] ${get(
              template,
              'good_practice.title',
            )}`}
        </DetailRow>
      </PaperWithPadding>
    )
  )
}

export default FullTemplatePreviewPrint
