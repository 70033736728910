/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import React from 'react'
import {
  Box,
  Typography,
  Button,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core'
import MultilineText from 'components/atoms/MultilineText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { formatChatMessage } from 'domains/audits/formatters'
import { has, map, get, isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  bubbleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bubble: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
  bubbleSize: {
    display: 'flex',
    maxWidth: '70%',
    border: '0.5px solid black',
    borderRadius: '10px',
    padding: `${theme.spacing(1)}px`,
  },
  bg_approver: {
    backgroundColor: `${theme.palette.primary.main}40`,
  },
  bg_auditor: {
    backgroundColor: `${theme.palette.common.greys.light}20`,
  },
  avatar: { marginRight: theme.spacing(1), marginLeft: theme.spacing(1) },
  message: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  right: {
    justifyContent: 'flex-end',
  },
  left: {
    justifyContent: 'flex-start',
  },
}))

const RejectAuditDialog = ({
  open,
  handleDialogClose,
  handleDialogConfirm,
  data = {},
}) => {
  const classes = useStyles()
  const [message, setMessage] = React.useState('')

  if (!has(data, 'rejectedChat')) {
    data.rejectedChat = []
  }

  const rejectedChat = get(data, 'rejectedChat', [])

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Reject d&apos;un audit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Êtes-vous sûr de vouloir rejeter cet audit ?
        </DialogContentText>
        <DialogContentText>
          L&apos;auditeur sera notifié par mail de votre décision.
          <br />
          N&apos;hésitez pas à remplir le formulaire pour aider l&apos;auditeur
          à corriger les problèmes de l&apos;audit.
        </DialogContentText>
        <DialogContentText>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="h6">
                Historique de discussion
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.bubbleContainer}>
                {map(rejectedChat, (chatMessage, index) => {
                  return (
                    <Box
                      key={index}
                      className={`${classes.bubble} ${
                        chatMessage.user === 'approver'
                          ? classes.left
                          : classes.right
                      }`}
                    >
                      <Box
                        className={`${classes.bubbleSize} ${
                          classes[`bg_${chatMessage.user}`]
                        }`}
                      >
                        {chatMessage.user === 'approver' && (
                          <Avatar className={classes.avatar}>M</Avatar>
                        )}
                        <Box className={classes.message}>
                          <Typography>{chatMessage.message}</Typography>
                          <Typography variant="caption">
                            {formatChatMessage(chatMessage)}
                          </Typography>
                        </Box>
                        {chatMessage.user === 'auditor' && (
                          <Avatar className={classes.avatar}>A</Avatar>
                        )}
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        </DialogContentText>
        <MultilineText
          className={classes.textField}
          label="Message"
          value={message}
          maxRows={4}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDialogClose}>
          Annuler
        </Button>
        <Button
          onClick={() => {
            if (!isEmpty(message)) {
              data.rejectedChat.push({
                user: 'approver',
                message,
                postedAt: new Date(),
              })
            }
            handleDialogConfirm({ messages: data })
          }}
          color="secondary"
          variant="contained"
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RejectAuditDialog
