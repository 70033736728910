import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import AuditMissionForm from 'components/organisms/AuditMissionForm'
import { auditMissionToRedux } from 'domains/audit_missions/formatters'
import Button from 'components/atoms/Button'
import Header from 'components/molecules/Header'
import {
  setLoadingAction,
  popConfirmationDialogAction,
} from 'domains/control/actions'
import {
  setInitialStateAction,
  setSelectedCardItemAction,
  setFormElementAction,
  setSelectedCardsAction,
  updateAuditMissionAction,
} from 'domains/forms/audit_mission/edit/actions'
import { getAuditMissionByIdAction } from 'domains/audit_missions/actions'
import { auditMissionEditFormSelector } from 'domains/forms/audit_mission/edit/selectors'
import ButtonLink from 'components/atoms/ButtonLink'
import WithPadding from 'components/templates/WithPadding'
import WithWarningOnModification from 'components/templates/WithWarningOnModification'

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
  },
})

const Edit = (props) => {
  const classes = useStyles()
  const {
    computedMatch: {
      params: { interventionId },
    },
    auditMission,
    setSelectedCardItem,
    setFormElement,
    setSelectedCards,
    setInitialState,
    updateAuditMission,
    getAuditMissionById,
    popConfirmationDialog,
    setLoading,
  } = props

  const [skipUpdate, setSkipUpdate] = useState(false)

  const resetForm = async () => {
    getAuditMissionById(interventionId, {
      onBefore: async () => setLoading(true),
      onSuccess: async (res) => setInitialState(auditMissionToRedux(res)),
      onAfter: async () => setLoading(false),
    })
  }

  useEffect(() => {
    resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmFlush = () => {
    popConfirmationDialog(
      'Réinitialisation du formulaire',
      'Êtes-vous sûr de vouloir réinitialiser le formulaire ? Tous les changements seront perdus.',
      () => resetForm(),
      () => {},
    )
  }
  const actions = [
    <ButtonLink variant="outlined" to="/audit_missions/">
      Retour
    </ButtonLink>,
    <ButtonLink variant="outlined" to={`/audit_missions/${auditMission.id}`}>
      Consulter
    </ButtonLink>,
    <Button
      disabled={auditMission.updated}
      onClick={() => {
        setSkipUpdate(true)

        updateAuditMission(auditMission.id, {
          onAfterSuccess: () => resetForm(),
        })
      }}
    >
      Enregistrer
    </Button>,
  ]

  return (
    <WithPadding className={classes.root}>
      <WithWarningOnModification
        skip={skipUpdate}
        updated={auditMission.updated}
      />
      <Header
        title="Modification d'une mission d'audit"
        actions={actions}
        alignContent="right"
      />
      <AuditMissionForm
        formValues={auditMission}
        setSelectedCardItem={setSelectedCardItem}
        setFormElement={setFormElement}
        setSelectedCards={setSelectedCards}
        confirmFlush={confirmFlush}
      />
    </WithPadding>
  )
}
const mapStateToProps = (state) => ({
  auditMission: auditMissionEditFormSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setInitialState: (payload) => {
    dispatch(setInitialStateAction(payload))
  },
  setLoading: (loading) => dispatch(setLoadingAction(loading)),
  popConfirmationDialog: (title, message, onAgree, onCancel) => {
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel))
  },
  updateAuditMission: (id, callbacks) =>
    dispatch(updateAuditMissionAction(id, callbacks)),
  getAuditMissionById: (id, callbacks) =>
    dispatch(getAuditMissionByIdAction(id, callbacks)),
  setSelectedCardItem: (card) => dispatch(setSelectedCardItemAction(card)),
  setFormElement: (el, value) => dispatch(setFormElementAction(el, value)),
  setSelectedCards: (cards) => dispatch(setSelectedCardsAction(cards)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
