/* eslint-disable no-alert */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Fab, makeStyles } from '@material-ui/core'
import { find, sortBy, map } from 'lodash'
import Button from 'components/atoms/Button'
import Text from 'components/atoms/Text'
import CriteriaSearcher from 'components/organisms/CriteriaSearcher'
import Controls from 'components/molecules/Controls'
import Delete from '@material-ui/icons/Delete'
import { getCriteriaByIdAction } from 'domains/criterias/actions'
import {
  popSnackbarAction,
  popConfirmationDialogAction,
} from 'domains/control/actions'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import Criteria from './Criteria'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  criterias_block: {
    marginLeft: theme.spacing(1),
  },
}))

const Section = (props) => {
  const {
    template,
    section,
    isFirst,
    isLast,
    getCriteriaById,
    popSnackbar,
    setFormElement,
    moveCriteriaInSection,
    onDeleteCriteria,
    moveSection,
    changeLabel,
    onDelete,
  } = props
  const [searcherOpen, setSearcherOpen] = useState(false)
  const classes = useStyles()

  const sortedCriteria = sortBy(
    section.accessibility_section_criteria_maps,
    (criteria) => criteria.criteria_index,
  )

  const isAlreadyInThisSection = (sectionToCheck, criteria) =>
    find(
      sectionToCheck.accessibility_section_criteria_maps,
      (sectionCriteria) => sectionCriteria.criteria.id === criteria.id,
    )

  const handleOnSelect = (criteriaTarget) => {
    const nextSections = JSON.parse(
      JSON.stringify(template.accessibility_sections),
    )

    getCriteriaById(criteriaTarget.id, {
      onSuccess: async (criteria) => {
        nextSections.forEach((nextSection) => {
          if (nextSection.id === section.id) {
            if (isAlreadyInThisSection(nextSection, criteriaTarget)) {
              popSnackbar(
                `${criteria.ref} est impossible à lier car il est déjà présent dans cette section`,
                'warning',
              )
            } else {
              nextSection.accessibility_section_criteria_maps.push({
                criteria_index:
                  nextSection.accessibility_section_criteria_maps.length,
                criteria,
              })

              setFormElement('accessibility_sections', nextSections)
              setSearcherOpen(false)
              popSnackbar(
                `${criteria.ref} a bien été lié à la section ${nextSection.name}`,
                'info',
              )
            }
          }
        })
      },
    })
  }

  return (
    <PaperWithPadding padding={2} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2} sm={1}>
          <Controls
            disabledUp={isFirst}
            disabledDown={isLast}
            actionUp={() => {
              moveSection(section.accessibility_section_index, 'up')
            }}
            actionDown={() => {
              moveSection(section.accessibility_section_index, 'down')
            }}
          />
        </Grid>
        <Grid item xs={10} sm={3}>
          <Text
            error={template.form_submitted_once && section.name === ''}
            value={section.name || ''}
            label="Titre de la section"
            name="name"
            onChange={changeLabel}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Text
            value={section.description || ''}
            label="Description"
            name="description"
            onChange={changeLabel}
          />
        </Grid>

        <Grid item xs={2} sm={1}>
          <Fab size="small" color="secondary" onClick={onDelete}>
            <Delete />
          </Fab>
        </Grid>

        <Grid item xs={10} sm={3}>
          <Button
            variant="outlined"
            iconLeft="add"
            onClick={() => setSearcherOpen(true)}
          >
            Ajouter un critère
          </Button>
        </Grid>
      </Grid>
      <div className={classes.criterias_block}>
        {map(sortedCriteria, (criteriaWrapper, index) => (
          <Criteria
            key={criteriaWrapper.criteria.id}
            criteriaWrapper={criteriaWrapper}
            section={section}
            isFirst={index === 0}
            isLast={index + 1 === sortedCriteria.length}
            moveCriteriaInSection={moveCriteriaInSection}
            onDeleteCriteria={onDeleteCriteria}
          />
        ))}
      </div>
      <CriteriaSearcher
        open={searcherOpen}
        setOpen={setSearcherOpen}
        onSelect={handleOnSelect}
      />
    </PaperWithPadding>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getCriteriaById: (id, callbacks) =>
    dispatch(getCriteriaByIdAction(id, callbacks)),
  popSnackbar: (message, type) => dispatch(popSnackbarAction(message, type)),
  popConfirmationDialog: (title, message, onAgree, onCancel) =>
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel)),
})

export default connect(null, mapDispatchToProps)(Section)
