import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const ConfirmationDialog = (props) => {
  const { confirmationDialog, setConfirmationDialog } = props

  const handleClose = () => {
    setConfirmationDialog('open', false)
  }

  const handleAgree = () => {
    handleClose()
    confirmationDialog.onAgree()
  }

  const handleCancel = () => {
    handleClose()
    confirmationDialog.onCancel()
  }

  return (
    <Dialog
      open={confirmationDialog.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {confirmationDialog.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmationDialog.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleAgree} color="primary" autoFocus>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
