import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'

const useStyles = makeStyles((theme) => ({
  color_primary: {
    color: theme.palette.primary.main,
  },
  row: {
    cursor: 'pointer',
  },
}))

const SimpleTable = (props) => {
  const { criterias, onSelect } = props
  const classes = useStyles()

  return (
    <Table className={classes.table} aria-label="criteria search table">
      <TableHead>
        <TableRow>
          <TableCell>Référence</TableCell>
          <TableCell>Nom</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Question</TableCell>
          <TableCell>Publié</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {criterias.map((criteria) => (
          <TableRow
            className={classes.row}
            hover
            key={criteria.ref}
            onClick={() => onSelect(criteria)}
          >
            <TableCell>
              <strong>{criteria.ref}</strong>
            </TableCell>
            <TableCell>{criteria.name}</TableCell>
            <TableCell>{criteria.explanation}</TableCell>
            <TableCell>{criteria.question}</TableCell>
            <TableCell>
              {criteria.published ? (
                <CheckCircle className={classes.color_primary} />
              ) : (
                <RadioButtonUnchecked className={classes.color_primary} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SimpleTable
