import {
  METHOD_GET,
  METHOD_PATCH,
  METHOD_POST,
  METHOD_DELETE,
} from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from '.'

const suffix = 'referential_templates'

export const getReferentialTemplates = (query_params = {}) =>
  nsfw(METHOD_GET, suffix, query_params)

export const getReferentialTemplateById = (id, query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/${id}`, query_params)

export const searchReferentialTemplates = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/search`, query_params)

export const createReferentialTemplate = (body, query_params = {}) =>
  nsfw(METHOD_POST, suffix, query_params, body)

export const duplicateReferentialTemplate = (id, query_params = {}) =>
  nsfw(METHOD_POST, `${suffix}/${id}/duplicate`, query_params)

export const createReferentialForCards = (id, body, query_params = {}) =>
  nsfw(
    METHOD_POST,
    `${suffix}/${id}/create_referential_for_cards`,
    query_params,
    body,
  )

export const createReferentialForAudits = (id, body, query_params = {}) =>
  nsfw(
    METHOD_POST,
    `${suffix}/${id}/create_referential_for_audits`,
    query_params,
    body,
  )

export const updateReferentialTemplate = (id, body, query_params = {}) =>
  nsfw(METHOD_PATCH, `${suffix}/${id}`, query_params, body)

export const deleteReferentialTemplate = (id, query_params = {}) =>
  nsfw(METHOD_DELETE, `${suffix}/${id}`, query_params)
