import React, { useRef } from 'react'
import theme from 'env/theme'
import { Box, Paper, Popper, Typography, makeStyles } from '@material-ui/core'
import { forEach } from 'lodash'
import InfoIcon from '@material-ui/icons/Info'
import AuditifPartial from 'assets/icons/AuditifPartial'
import Auditif from 'assets/icons/Auditif'
import FemmeEnceinte from 'assets/icons/FemmeEnceinte'
import HandicapPsychique from 'assets/icons/HandicapPsychique'
import Moteur from 'assets/icons/Moteur'
import Senior from 'assets/icons/Senior'
import Visuel from 'assets/icons/Visuel'
import VisuelPartial from 'assets/icons/VisuelPartial'

const color = theme.palette.primary.main

const getHandicapIconsList = (handicaps) => {
  const res = []
  forEach(handicaps, (handicap) => {
    switch (handicap) {
      case 1:
        res.push(
          <Moteur
            key={1}
            width={40}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      case 2:
        res.push(
          <HandicapPsychique
            key={2}
            width={40}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      case 3:
        res.push(
          <Auditif
            key={3}
            width={40}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      case 4:
        res.push(
          <Visuel
            key={4}
            width={40}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      case 5:
        res.push(
          <VisuelPartial
            key={5}
            width={40}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      case 6:
        res.push(
          <AuditifPartial
            key={6}
            width={40}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      case 7:
        res.push(
          <FemmeEnceinte
            key={7}
            width={30}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      case 8:
        res.push(
          <Senior
            key={8}
            width={40}
            height={40}
            fill={color}
            style={{ marginLeft: '4px' }}
          />,
        )
        break
      default:
        break
    }
  })

  return res
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const HandicapIconsPopper = (props) => {
  const { handicaps } = props
  const classes = useStyles()
  const ref = useRef(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const onClick = (event) => {
    event.stopPropagation()
    setAnchorEl(ref.current)
    setOpen(true)
  }

  const onMouseEnter = () => {
    setAnchorEl(ref.current)
    setOpen(true)
  }

  const onMouseLeave = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handicapIcons = getHandicapIconsList(handicaps)

  return (
    <>
      <Box
        onMouseEnter={(event) => onMouseEnter(event)}
        onClick={(event) => onClick(event)}
        onMouseLeave={(event) => onMouseLeave(event)}
        ref={ref}
        aria-describedby="simple-popper"
      >
        <InfoIcon size="large" color="primary" />
      </Box>
      <Popper id="simple-popper" open={open} anchorEl={anchorEl}>
        <Paper className={classes.paper}>
          <Typography variant="body1">
            {handicapIcons.length > 0
              ? handicapIcons
              : "Aucun d'handicap affilié"}
          </Typography>
        </Paper>
      </Popper>
    </>
  )
}

export default HandicapIconsPopper
