import { getXAuthToken } from 'api/utils/authentication'
import { formatParams } from './formatters'

const getAuth = () => window.env.REACT_APP_API_AUTH

export const getUrl = (endpoint, params) =>
  `${window.env.REACT_APP_API_URL}${endpoint}${formatParams(params)}`

export const getHeaders = (namespace = 'picto-admin') => ({
  Accept: `application/vnd.${namespace}-v2+json`,
  'X-Auth-Token': getXAuthToken(),
  'Content-Type': 'application/json',
  Authorization: getAuth(),
})
