/* eslint-disable camelcase */
import { filter, map, orderBy } from 'lodash'

const mapCategoriesDepth = (categories, depth = 0, parent_id = null) =>
  filter(categories, (category) => {
    if (parent_id === null) {
      return category.depth === depth
    }
    return category.depth === depth && category.parent_id === parent_id
  }).map((category) => ({
    label: category.name,
    value: category.id,
  }))

export const categoriesLevelOneToOptions = (categories) =>
  mapCategoriesDepth(categories, 0)

export const categoriesLevelTwoToOptions = (categories, levelOneCategory) =>
  mapCategoriesDepth(categories, 1, levelOneCategory)

export const categoriesLevelThreeToOptions = (categories, levelTwoCategory) =>
  mapCategoriesDepth(categories, 2, levelTwoCategory)

export const formatCategories = (categories) =>
  map(
    orderBy(categories, ['depth'], ['desc']),
    (category) => category.name,
  ).join(', ')
