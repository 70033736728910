import React from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core'
import { map } from 'lodash'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBox from '@material-ui/icons/CheckBox'
import BooleanIcon from 'components/atoms/BooleanIcon'
import {
  formatCardTitle,
  formatCardAddress,
  formatCardCategories,
  formatCardHasReferential,
} from '../formatters'

const useStyles = makeStyles((theme) => ({
  color_primary: {
    color: theme.palette.primary.main,
  },
  overflowXScroll: { overflowX: 'scroll' },
  row: {
    cursor: 'pointer',
  },
  table: { width: '100%' },
}))

const SimpleTable = (props) => {
  const {
    cards,
    className,
    selectedCards = [],
    onSelect = null,
    multiSelect = false,
    customColumns = null,
  } = props
  const classes = useStyles()

  return (
    <Box className={classes.overflowXScroll}>
      <Table
        className={`${classes.table} ${className}`}
        aria-label="card search table"
      >
        <TableHead>
          <TableRow>
            {multiSelect && (
              <TableCell>
                <CheckBoxOutlineBlank color="action" />
              </TableCell>
            )}
            <TableCell>Titre</TableCell>
            <TableCell>Adresse</TableCell>
            <TableCell>Catégories</TableCell>
            <TableCell>Possède un Réferentiel</TableCell>
            {customColumns &&
              customColumns.map((customColumn) => customColumn.head())}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(cards, (card) => {
            const selected =
              multiSelect &&
              map(selectedCards, (selectedCard) => selectedCard.id).includes(
                card.id,
              )

            return (
              <TableRow
                className={classes.row}
                hover
                selected={selected}
                key={card.id}
                onClick={onSelect ? (e) => onSelect(card, e) : null}
              >
                {multiSelect && (
                  <TableCell>
                    {selected ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank color="action" />
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <strong>{formatCardTitle(card)}</strong>
                </TableCell>
                <TableCell>
                  <Typography>
                    {formatCardAddress(card.localisation)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>
                    {formatCardCategories(card.categories)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <BooleanIcon value={formatCardHasReferential(card)} />
                </TableCell>
                {customColumns &&
                  map(customColumns, (customColumn) => customColumn.body(card))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default SimpleTable
