const initialState = {
  form_submitted_once: false,
  ref: '',
  name: '',
  private: false,
  description: '',
  published: false,
  created_at: '',
  updated_at: '',
  brands: '',
  category: '',
  level_two_category: '',
  tags: '',
  accessibility_sections: [],
  good_practice: '',
  updated: true,
}

const create = (state = initialState, action) => {
  switch (action.type) {
    case 'FLUSH_TEMPLATE_CREATE_FORM':
      return initialState
    case 'SET_TEMPLATE_CREATE_FORM':
      return {
        ...state,
        [action.element]: action.value,
        updated: false,
      }
    default:
      return state
  }
}

export default create
