/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import {
  categoryListSelector,
  categoriesLevelOneOptionsSelector,
  categoriesLevelTwoOptionsSelector,
  categoriesLevelTheeOptionsSelector,
} from 'domains/categories/selectors'
import {
  brandListSelector,
  brandsOptionsSelector,
} from 'domains/brands/selectors'
import {
  goodPracticeListSelector,
  goodPracticesOptionsSelector,
} from 'domains/good_practices/selectors'
import { tagOptionsSelector } from 'domains/tags/selectors'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import Autocomplete from 'components/atoms/Autocomplete'
import MultilineText from 'components/atoms/MultilineText'
import MultiSelect from 'components/atoms/MultiSelect'
import Switch from 'components/atoms/Switch'
import Text from 'components/atoms/Text'

const useStyles = makeStyles((theme) => ({
  label: {
    lineHeight: '2.5rem',
  },
  row_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: theme.spacing(2),
  },
  text: {},
  blockStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  brands: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  good_practices: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
}))

const AttributesForm = (props) => {
  const {
    template,
    brands,
    categories,
    tagOptions,
    brandOptions,
    goodPractices,
    goodPracticeOptions,
    categoriesLevelOneOptions,
    categoriesLevelTwoOptions,
    categoriesLevelThreeOptions,
    setFormElement,
  } = props
  const classes = useStyles()

  const handleLevelOneCategoryChange = (categoryLevelOneFormData) => {
    if (categoryLevelOneFormData !== null) {
      // reset category level 2 & 3 on change
      if (template.level_two_category !== `${categoryLevelOneFormData.value}`) {
        setFormElement('level_two_category', '')
        setFormElement('level_three_category', '')
      }
    }
    setFormElement('category', `${categoryLevelOneFormData.value}`)
  }

  const handleLevelTwoSubCategoryChange = (levelTwoCategoryFormData) => {
    if (levelTwoCategoryFormData !== null) {
      const levelTwoCategory = find(categories, {
        id: parseInt(levelTwoCategoryFormData.value, 10),
      })

      if (template.level_two_category !== `${levelTwoCategoryFormData.value}`) {
        setFormElement('level_three_category', '')
      }
      if (template.category !== levelTwoCategory.parent_id) {
        setFormElement('category', `${levelTwoCategory.parent_id}`)
      }
    }

    setFormElement('level_two_category', `${levelTwoCategoryFormData.value}`)
  }

  const handleLevelThreeSubCategoryChange = (levelThreeCategoryFormData) => {
    if (levelThreeCategoryFormData !== null) {
      const levelThreeCategory = find(categories, {
        id: parseInt(levelThreeCategoryFormData.value, 10),
      })
      const levelTwoCategory = find(categories, {
        id: parseInt(levelThreeCategory.parent_id, 10),
      })

      // if (template.level_three_category !== `${category.value}`) {
      //   setFormElement('level_three_category', '')
      // }
      if (template.level_two_category !== levelThreeCategory.parent_id) {
        setFormElement('level_two_category', `${levelThreeCategory.parent_id}`)
      }
      if (template.category !== levelTwoCategory.parent_id) {
        setFormElement('category', `${levelTwoCategory.parent_id}`)
      }
    }
    setFormElement(
      'level_three_category',
      `${levelThreeCategoryFormData.value}`,
    )
  }

  const handleBrandChange = (brand) => {
    setFormElement('brands', `${brand.value}`)
  }

  const handleGoodPracticeChange = (goodPractice) => {
    setFormElement('good_practice', `${goodPractice.value}`)
  }

  const formatCategory = (categoryId) => {
    const category = find(categories, {
      id: parseInt(categoryId, 10),
    })

    return (
      category && {
        label: category.name,
        value: category.id,
      }
    )
  }

  const formatBrand = (brandId) => {
    const brand = find(brands, {
      id: parseInt(brandId, 10),
    })

    return (
      brand && {
        label: brand.name,
        value: brand.id,
      }
    )
  }

  const formatGoodPractice = (goodPracticeId) => {
    const goodPractice = find(goodPractices, {
      id: parseInt(goodPracticeId, 10),
    })
    if (goodPractice) {
      let label = `[${goodPractice.ref}] ${goodPractice.title}`
      if (goodPractice.is_default === true) {
        label = `${label} (PAR DEFAUT)`
      }

      return {
        value: goodPractice.id,
        label,
      }
    }
    return null
  }

  return (
    <PaperWithPadding className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Text
            error={template.form_submitted_once && template.name === ''}
            className={`${classes.text} ${classes.fullWidth}`}
            label="Intitulé du template"
            value={template.name || ''}
            onChange={(e) => {
              setFormElement('name', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Text
            error={template.form_submitted_once && template.ref === ''}
            className={`${classes.text} ${classes.fullWidth}`}
            label="Référence"
            value={template.ref || ''}
            onChange={(e) => {
              setFormElement('ref', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={classes.blockStyle}>
            <Switch
              label="Statut"
              attributeLabel="Publié"
              onChange={(e) => {
                setFormElement('published', e.target.checked)
              }}
              checked={template.published}
            />
            <Switch
              label="Confidentialité"
              attributeLabel="Privé"
              onChange={(e) => {
                setFormElement('private', e.target.checked)
              }}
              checked={template.private}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MultilineText
            className={classes.fullWidth}
            error={template.form_submitted_once && template.explanation === ''}
            label="Description du template"
            value={template.description || ''}
            maxRows={4}
            onChange={(e) => {
              setFormElement('description', e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={categoriesLevelOneOptions}
            value={formatCategory(template.category) || ''}
            placeholder="Catégorie niveau 1"
            onChange={(value) => handleLevelOneCategoryChange(value)}
            label="Catégorie niveau 1"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={categoriesLevelTwoOptions}
            value={formatCategory(template.level_two_category) || ''}
            placeholder="Catégorie niveau 2"
            onChange={(value) => handleLevelTwoSubCategoryChange(value)}
            label="Catégorie niveau 2"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={categoriesLevelThreeOptions}
            value={formatCategory(template.level_three_category) || ''}
            placeholder="Catégorie niveau 3"
            onChange={(value) => handleLevelThreeSubCategoryChange(value)}
            label="Catégorie niveau 3"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography
            component="label"
            variant="body2"
            className={classes.label}
          >
            Marques
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Autocomplete
            className={classes.brands}
            options={brandOptions}
            value={formatBrand(template.brands) || ''}
            placeholder="Marque"
            onChange={(value) => handleBrandChange(value)}
            label="Marque"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography
            component="label"
            variant="body2"
            className={classes.label}
          >
            Bonnes Pratiques
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Autocomplete
            className={classes.good_practices}
            options={goodPracticeOptions}
            value={formatGoodPractice(template.good_practice) || ''}
            placeholder="Bonnes pratiques"
            onChange={(value) => handleGoodPracticeChange(value)}
            label="Bonnes pratiques"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MultiSelect
            value={template.tags ? template.tags.split(',') : []}
            suggestions={tagOptions}
            handleChange={(value) => setFormElement('tags', value)}
            label="Tags"
            placeholder=""
          />
        </Grid>
      </Grid>
    </PaperWithPadding>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    template: { category, level_two_category },
  } = ownProps
  return {
    categories: categoryListSelector(state),
    brands: brandListSelector(state),
    goodPractices: goodPracticeListSelector(state),
    goodPracticeOptions: goodPracticesOptionsSelector(state),
    tagOptions: tagOptionsSelector(state),
    categoriesLevelOneOptions: categoriesLevelOneOptionsSelector(state),
    categoriesLevelTwoOptions: categoriesLevelTwoOptionsSelector(
      state,
      category === '' ? null : parseInt(category, 10),
    ),
    categoriesLevelThreeOptions: categoriesLevelTheeOptionsSelector(
      state,
      level_two_category === '' ? null : parseInt(level_two_category, 10),
    ),
    brandOptions: brandsOptionsSelector(state),
  }
}

export default connect(mapStateToProps, null)(AttributesForm)
