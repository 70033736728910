import {
  SET_TEMPLATES,
  SET_SELECTED_TEMPLATE,
  SET_PER_PAGE,
  SET_SEARCH,
} from './types'

const initialState = {
  list: [],
  pagination: null,
  selected: null,
  per_page: 25,
  search: '',
}

const all = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATES:
      return {
        ...state,
        list: action.payload.referential_templates || [],
        pagination: action.payload.pagination || initialState.pagination,
      }
    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selected: action.payload,
      }
    case SET_PER_PAGE:
      return {
        ...state,
        per_page: action.payload,
      }
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      }
    default:
      return state
  }
}

export default all
