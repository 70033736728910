import React from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'

const Head = (props) => {
  const { columns } = props
  return (
    <TableHead>
      <TableRow>
        {columns.map(
          (column) => (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? 'none' : 'normal'}
            >
              {column.label}
            </TableCell>
          ),
          this,
        )}
      </TableRow>
    </TableHead>
  )
}

export default Head
