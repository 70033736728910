import axios from 'axios'
import { forEach, isObject } from 'lodash'
import { CallbackTypeError, logError, BodyNotObjectError } from './utils/errors'
import { logSuccessfulResponse, logFailureResponse } from './utils/loggers'
import { requestIsSuccessful } from './utils/parsers'

const handleResponse = (res, endpoint, requestOptions, callbacks) => {
  const { onSuccess, onFailure } = callbacks

  try {
    if (res.status && requestIsSuccessful(res.status)) {
      logSuccessfulResponse(endpoint, requestOptions, res.data)
      return onSuccess(res.data)
    }
    logFailureResponse(endpoint, requestOptions, res.data)
    return onFailure({ status: res.status, ...res.data })
  } catch (err) {
    logFailureResponse(endpoint, requestOptions, err)
    return onFailure(err)
  }
}

/**
 * DONT'T USE IT DIRECTLY
 * (Use the wrappers in the api/domains folder)
 */
const fetch = (endpoint, opt, callbacks, body = null) => {
  try {
    forEach(callbacks, (callback, key) => {
      if (callback && typeof callback !== 'function') {
        throw CallbackTypeError(key)
      }
    })

    if (body && !isObject(body)) {
      throw BodyNotObjectError(typeof body)
    }

    const defaultOptions = {
      url: endpoint,
      withCredentials: false,
      responseType: 'json',
    }

    const requestOptions = body
      ? { ...defaultOptions, ...opt, data: body }
      : { ...defaultOptions, ...opt }

    const {
      onBefore = () => Promise.resolve(),
      onAfter = () => Promise.resolve(),
      onSuccess = () => Promise.resolve(),
      onFailure = () => Promise.resolve(),
    } = callbacks

    onBefore()
      .then(() => axios(requestOptions))
      .then((res) => {
        handleResponse(res, endpoint, requestOptions, { onSuccess, onFailure })
      })
      .catch((err) => {
        logFailureResponse(endpoint, requestOptions, err)
        return onFailure(err)
      })
      .finally(() => onAfter())
  } catch (error) {
    logError(error)
  }
}

export default fetch
