import React, { useState } from 'react'
import { connect } from 'react-redux'
import { forEach } from 'lodash'
import { Grid, Typography, IconButton, makeStyles } from '@material-ui/core'
import Button from 'components/atoms/Button'
import Text from 'components/atoms/Text'
import MultilineText from 'components/atoms/MultilineText'
import Delete from '@material-ui/icons/Delete'
import Close from '@material-ui/icons/Close'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CriteriaSearcher from 'components/organisms/CriteriaSearcher'
import Controls from 'components/molecules/Controls'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import { getCriteriaByIdAction } from 'domains/criterias/actions'
import { popSnackbarAction } from 'domains/control/actions'
import { criteriaIsEligible } from 'domains/criterias/parsers'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  choiceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  titleAndDelete: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  criteria: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  criteriaRef: {
    display: 'flex',
  },
  criteriaIcon: {
    marginRight: theme.spacing(2),
  },
  criteriaContainer: {
    marginTop: theme.spacing(2),
  },
}))

const Choice = (props) => {
  const {
    formValues,
    index,
    choice,
    isFirst,
    isLast,
    onDelete,
    moveChoice,
    onChangeLabel,
    onChangeDescription,
    onDeleteLinkedCriteria,
    setFormElement,
    getCriteriaById,
    popSnackbar,
  } = props

  const [searcherOpen, setSearcherOpen] = useState(false)
  const classes = useStyles()

  const handleOnSelect = (selectedCriteria) => {
    const nextChoices = JSON.parse(JSON.stringify(formValues.criteria_choices))
    getCriteriaById(selectedCriteria.id, {
      onSuccess: async (criteria) => {
        let choiceRef = null
        const { eligible, reason } = criteriaIsEligible(choice, criteria)

        if (!eligible) {
          popSnackbar(reason, 'warning')
        } else {
          forEach(nextChoices, (nextChoice) => {
            if (nextChoice.value === choice.value) {
              choiceRef = nextChoice
              nextChoice.linked_criterias.push(criteria)
            }
          })

          setFormElement('criteria_choices', nextChoices)
          setSearcherOpen(false)
          popSnackbar(
            `${criteria.ref} a bien été lié à la réponse ${
              choiceRef.choice_index + 1
            }`,
            'info',
          )
        }
      },
    })
  }

  const titleLabel = `Réponse ${index || 0}`

  return (
    <PaperWithPadding padding={2} className={classes.root}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={2} md={1}>
          <Controls
            disabledUp={isFirst}
            disabledDown={isLast}
            actionUp={() => {
              moveChoice(choice.choice_index, 'up')
            }}
            actionDown={() => {
              moveChoice(choice.choice_index, 'down')
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">{titleLabel}</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <IconButton color="secondary" onClick={onDelete}>
            <Delete />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            iconLeft="add"
            variant="outlined"
            size="small"
            onClick={() => {
              setSearcherOpen(true)
            }}
          >
            Lier ce choix à un autre critère
          </Button>
        </Grid>
      </Grid>
      <Text
        className={classes.text}
        error={formValues.form_submitted_once && choice.label === ''}
        value={choice.label || ''}
        label="Label"
        onChange={onChangeLabel}
      />
      <MultilineText
        className={classes.text}
        value={choice.description || ''}
        onChange={onChangeDescription}
        label="Description"
        maxRows={4}
      />
      <div className={classes.criteriaContainer}>
        {choice.linked_criterias.map((criteria) => {
          const criteriaRefLabel = `Lié à ${criteria.ref} - "${criteria.question}"`

          return (
            <div key={criteria.id} className={classes.criteria}>
              <div className={classes.criteriaRef}>
                <KeyboardArrowRight className={classes.criteriaIcon} />
                <Typography>{criteriaRefLabel}</Typography>
              </div>
              <IconButton
                onClick={() =>
                  onDeleteLinkedCriteria(criteria.id, choice.value)
                }
              >
                <Close color="primary" />
              </IconButton>
            </div>
          )
        })}
      </div>

      <CriteriaSearcher
        open={searcherOpen}
        setOpen={setSearcherOpen}
        onSelect={handleOnSelect}
      />
    </PaperWithPadding>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getCriteriaById: (id, callbacks) =>
    dispatch(getCriteriaByIdAction(id, callbacks)),
  popSnackbar: (message, type) => {
    dispatch(popSnackbarAction(message, type))
  },
})

export default connect(null, mapDispatchToProps)(Choice)
