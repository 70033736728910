import {
  SET_FORM,
  SET_SELECTED_CARDS,
  SET_SELECTED_CARD_ITEM,
  SET_INITIAL_STATE,
} from './constants'

const initialState = {
  form_submitted_once: false,
  ref: '',
  title: '',
  operator_name: '',
  ends_at: new Date(),
  status: 'pending',
  active: false,
  message: '',
  audits: [],
  selected_card_item: null,
  selected_cards: [],
  initial_state: {},
  updated: true,
}

const edit = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return {
        ...state,
        ...action.payload,
        initial_state: action.payload,
      }
    case SET_SELECTED_CARDS:
      return {
        ...state,
        selected_cards: action.payload,
        updated: false,
      }
    case SET_SELECTED_CARD_ITEM:
      return {
        ...state,
        selected_card_item: action.payload,
        updated: false,
      }
    case SET_FORM:
      return {
        ...state,
        [action.element]: action.value,
        updated: false,
      }
    default:
      return state
  }
}

export default edit
