/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import React from 'react'
import { connect } from 'react-redux'
import { Box, Button, makeStyles } from '@material-ui/core'
import Done from '@material-ui/icons/Done'
import Clear from '@material-ui/icons/Clear'
import {
  acceptAuditAction,
  rejectAuditAction,
  publishAuditAction,
  cancelApprovementAuditAction,
} from 'domains/audits/actions'
import { openInNewTab } from 'domains/audits/utils'
import PublishAuditDialog from './PublishAuditDialog'
import RejectAuditDialog from './RejectAuditDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}))

const ActionButtons = (props) => {
  const {
    audit,
    acceptAudit,
    rejectAudit,
    publishAudit,
    refreshAuditMission,
    cancelApprovement,
  } = props
  const classes = useStyles()

  const [openRejectDialog, setOpenRejectDialog] = React.useState(false)
  const [openPublishDialog, setOpenPublishDialog] = React.useState(false)

  const displayApprovedAndRejectButtons = audit.status === 'waiting_for_review'
  const displayPublishButton = audit.status === 'accepted'
  const displayShowPageButton = audit.status === 'published'
  const displayCancelButton =
    audit.status !== 'published' &&
    (audit.status === 'accepted' || audit.status === 'rejected')

  return (
    <Box className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        size="small"
        onClick={() => openInNewTab('audit', audit.id)}
      >
        Prévisualiser l&apos;audit
      </Button>

      {displayShowPageButton && (
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          onClick={() => openInNewTab('card', audit.card.slug)}
        >
          Voir la page du lieu
        </Button>
      )}

      {displayApprovedAndRejectButtons && (
        <>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              acceptAudit(audit.id, {
                onSuccess: async () => {
                  refreshAuditMission()
                },
              })
            }}
          >
            <Done fontSize="small" /> Valider l&apos;audit
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => setOpenRejectDialog(true)}
          >
            <Clear fontSize="small" /> Rejeter l&apos;audit
          </Button>
          <RejectAuditDialog
            open={openRejectDialog}
            handleDialogClose={() => setOpenRejectDialog(false)}
            handleDialogConfirm={(data) => {
              rejectAudit(audit.id, data, {
                onBefore: async () => setOpenRejectDialog(false),
                onSuccess: async () => refreshAuditMission(),
              })
            }}
            data={audit.messages}
          />
        </>
      )}

      {displayPublishButton && (
        <>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setOpenPublishDialog(true)}
          >
            Publier l&apos;audit
          </Button>
          <PublishAuditDialog
            open={openPublishDialog}
            handleDialogClose={() => setOpenPublishDialog(false)}
            handleDialogConfirm={(performBackup = true) => {
              publishAudit(audit.id, performBackup, {
                onBefore: async () => setOpenPublishDialog(false),
                onSuccess: async () => refreshAuditMission(),
              })
            }}
          />
        </>
      )}
      {displayCancelButton && (
        <Button
          className={classes.button}
          variant="contained"
          size="small"
          color="secondary"
          onClick={() =>
            cancelApprovement(audit.id, {
              onSuccess: async () => {
                refreshAuditMission()
              },
            })
          }
        >
          Annuler la modération
        </Button>
      )}
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => ({
  acceptAudit: (auditId, callbacks) =>
    dispatch(acceptAuditAction(auditId, callbacks)),
  publishAudit: (auditId, performBackup, callbacks) =>
    dispatch(publishAuditAction(auditId, performBackup, callbacks)),
  rejectAudit: (auditId, data, callbacks) =>
    dispatch(rejectAuditAction(auditId, data, callbacks)),
  cancelApprovement: (auditId, callbacks) =>
    dispatch(cancelApprovementAuditAction(auditId, callbacks)),
})

export default connect(null, mapDispatchToProps)(ActionButtons)
