/* eslint-disable import/prefer-default-export */
export const formatSections = (sections) => {
  const sectionsFormatted = {}
  sections.forEach((section, index) => {
    const {
      accessibility_section_criteria_maps: accessibilitySectionCriteriaMaps,
      id,
      ...eligiblesAttributes
    } = section

    const formattedAttributes = {}
    section.accessibility_section_criteria_maps.forEach(
      (criteria, criteriaIndex) => {
        formattedAttributes[`${criteriaIndex}`] = {
          criteria_id: criteria.criteria.id,
          criteria_index: criteria.criteria_index,
        }
      },
    )

    sectionsFormatted[`${index}`] = {
      ...eligiblesAttributes,
      accessibility_section_criteria_maps_attributes: formattedAttributes,
    }
  })
  return sectionsFormatted
}
