import { SET_FORM_VALUES } from './types'

const initialState = {
  email: '',
  password: '',
}

const main = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_VALUES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default main
