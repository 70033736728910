/* eslint-disable no-use-before-define */
import React from 'react'
import TreeItem from '@material-ui/lab/TreeItem'
import { v4 as uid } from 'uuid'
import { flattenDeep, map } from 'lodash'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Typography, makeStyles } from '@material-ui/core'
import { labelTemplateEnum } from './enums'

const useStyles = makeStyles({
  treeItemView: {
    display: 'flex',
    alignItems: 'center',
  },
  iconMargin: {
    marginRight: '3px',
  },
  bold: {
    fontWeight: 'bold',
  },
})

export const formatErrorsTemplateForm = (title, errors) => {
  const subErrorStyle = {
    margin: 0,
  }
  const getAllErrors = () => {
    const subErrors = []
    Object.keys(errors).map((key) =>
      errors[key].forEach((error) => {
        subErrors.push(
          <p style={subErrorStyle} key={error}>
            {`"${labelTemplateEnum(key)}" ${error}`}
          </p>,
        )
      }),
    )

    return subErrors
  }

  return (
    <div>
      <h3>{title || ''}</h3>
      {getAllErrors()}
    </div>
  )
}

export const getNodeIds = (treeItems) =>
  treeItems.map((treeItem) => treeItem.key)

export const getAllNodeIds = (treeItems) => {
  return flattenDeep(
    map(treeItems, (treeItem) => {
      let children = []

      if (treeItem.props.children.length) {
        children = getAllNodeIds(treeItem.props.children)
      }

      return [treeItem.key, ...children]
    }),
  )
}

export const formatSectionsToTreeView = (sections) =>
  sections.map((section) => {
    const id = uid()

    return (
      <TreeItem
        key={id}
        label={
          <Typography variant="h6" color="primary">
            {section.name}
          </Typography>
        }
        nodeId={id}
        collapseIcon={<ExpandMoreIcon />}
        expandIcon={<ChevronRightIcon />}
      >
        {formatCriteriasToTreeView(
          section.accessibility_section_criteria_maps.map(
            (criteria) => criteria.criteria,
          ),
        )}
      </TreeItem>
    )
  })

const CriteriaTreeItem = (props) => {
  const { criteria, nodeId, children } = props
  const classes = useStyles()

  const criteriaItemLabel = `${criteria.ref} - ${criteria.question}`

  return (
    <TreeItem
      key={nodeId}
      label={
        <Typography className={classes.bold}>{criteriaItemLabel}</Typography>
      }
      nodeId={nodeId}
      collapseIcon={<ExpandMoreIcon />}
      expandIcon={<ChevronRightIcon />}
    >
      {children}
    </TreeItem>
  )
}

const formatCriteriasToTreeView = (criterias) =>
  criterias
    ? criterias.map((criteria) => {
        const id = uid()
        return (
          <CriteriaTreeItem criteria={criteria} nodeId={id} key={id}>
            {formatChoicesToTreeView(
              criteria.criteria_choices,
              criteria.criteria_type,
            )}
          </CriteriaTreeItem>
        )
      })
    : null

const ChoiceTreeItem = (props) => {
  const { choice, criteriaType, nodeId, children } = props

  return (
    <TreeItem
      label={<TreeItemLabel choice={choice} criteriaType={criteriaType} />}
      nodeId={nodeId}
      collapseIcon={<ExpandMoreIcon />}
      expandIcon={<ChevronRightIcon />}
    >
      {children}
    </TreeItem>
  )
}

const formatChoicesToTreeView = (choices, criteriaType) =>
  choices
    ? choices.map((choice) => {
        const id = uid()
        return (
          <ChoiceTreeItem
            choice={choice}
            criteriaType={criteriaType}
            nodeId={id}
            key={id}
          >
            {formatCriteriasToTreeView(choice.linked_criterias)}
          </ChoiceTreeItem>
        )
      })
    : null

const CriteriaTypeIcon = ({ criteriaType }) => {
  const classes = useStyles()
  if (criteriaType === 'radio' || criteriaType === 'radio_extended') {
    return (
      <RadioButtonUncheckedIcon
        color="primary"
        fontSize="small"
        className={classes.iconMargin}
      />
    )
  }
  if (criteriaType === 'checkbox' || criteriaType === 'checkbox_extended') {
    return (
      <CheckBoxOutlineBlankIcon
        color="primary"
        fontSize="small"
        className={classes.iconMargin}
      />
    )
  }

  return <ChevronRightIcon color="primary" />
}

const TreeItemLabel = ({ choice, criteriaType }) => {
  const classes = useStyles()

  return (
    <div className={classes.treeItemView}>
      <CriteriaTypeIcon criteriaType={criteriaType} />
      <Typography>{choice.label}</Typography>
    </div>
  )
}
