import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, createStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Redirect } from 'react-router-dom'
import {
  submitFormAction,
  setFormValuesAction,
} from 'domains/forms/sign_in/actions'
import { signInFormValuesSelector } from 'domains/forms/sign_in/selectors'
import PictoLoader from 'components/atoms/PictoLoader'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
    },
    textField: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    notchedOutline: { color: 'black' },
  }),
)

const SignInForm = (props) => {
  const { control, formValues, setFormValues, submitForm } = props
  const { email, password } = formValues

  const [showPassword, setShowPassword] = useState(false)

  const classes = useStyles()

  const handleOnSubmit = (event) => {
    event.preventDefault()
    if (control.loading === false) submitForm()
  }

  if (control.user_auth_token !== '') {
    return <Redirect to="/criterias" />
  }
  return (
    <Grid container justifyContent="center">
      {control.loading && <PictoLoader />}
      <Grid item xs={12} sm={12} md={3}>
        <form
          className={classes.root}
          acceptCharset="UTF-8"
          onSubmit={(event) => handleOnSubmit(event)}
        >
          <Typography
            variant="h2"
            component="h2"
            color="textPrimary"
            gutterBottom
            align="center"
          >
            Connexion
          </Typography>
          <OutlinedInput
            required
            notched={false}
            placeholder="Adresse E-mail"
            id="user_email"
            label="Email"
            type="text"
            className={classes.textField}
            value={email}
            classes={{ notchedOutline: classes.notchedOutline }}
            onChange={(event) => setFormValues({ email: event.target.value })}
          />
          <OutlinedInput
            required
            notched={false}
            id="user_password"
            label="Mot de passe"
            placeholder="Mot de passe"
            className={classes.textField}
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={(event) => {
              setFormValues({ password: event.target.value })
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={control.loading}
            type="submit"
          >
            {control.loading ? 'Chargement...' : 'Se connecter'}
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  formValues: signInFormValuesSelector(state),
  control: state.control,
})

const mapDispatchToProps = (dispatch) => ({
  setFormValues: (formValues) => dispatch(setFormValuesAction(formValues)),
  submitForm: () => {
    dispatch(submitFormAction())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm)
