/* eslint-disable no-alert */
import React from 'react'
import { connect } from 'react-redux'
import { filter, find, sortBy } from 'lodash'
import { Typography, makeStyles } from '@material-ui/core'
import { v4 as uid } from 'uuid'
import Button from 'components/atoms/Button'
import { popConfirmationDialogAction } from 'domains/control/actions'
import Section from './Section'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
  },
  title: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

const SectionsForm = (props) => {
  const classes = useStyles()
  const { template, setFormElement, popConfirmationDialog } = props

  const onDeleteCriteria = (criteriaIdToDelete, sectionToUpdate) => {
    const nextSections = template.accessibility_sections || []

    nextSections.forEach((nextSection, index) => {
      if (nextSection.id === sectionToUpdate) {
        nextSections[index].accessibility_section_criteria_maps = filter(
          nextSection.accessibility_section_criteria_maps,
          (criteria) => criteria.criteria.id !== criteriaIdToDelete,
        )
      }
    })

    setFormElement('accessibility_sections', nextSections)
  }

  const moveSection = (index, direction) => {
    const nextSections = template.accessibility_sections || []

    const swappedOne = find(nextSections, {
      accessibility_section_index: index,
    })
    const swappedTwo =
      direction === 'up'
        ? find(nextSections, {
            accessibility_section_index: index - 1,
          })
        : find(nextSections, {
            accessibility_section_index: index + 1,
          })

    if (swappedTwo === undefined) {
      return null
    }

    const temp = swappedOne.accessibility_section_index
    swappedOne.accessibility_section_index =
      swappedTwo.accessibility_section_index
    swappedTwo.accessibility_section_index = temp

    return setFormElement('accessibility_sections', nextSections)
  }

  const moveCriteriaInSection = async (
    sectionIndex,
    criteriaIndex,
    direction,
  ) => {
    const nextSections = template.accessibility_sections || []

    nextSections.forEach((section) => {
      if (section.accessibility_section_index === sectionIndex) {
        const swappedOne = find(section.accessibility_section_criteria_maps, {
          criteria_index: criteriaIndex,
        })
        const swappedTwo =
          direction === 'up'
            ? find(section.accessibility_section_criteria_maps, {
                criteria_index: criteriaIndex - 1,
              })
            : find(section.accessibility_section_criteria_maps, {
                criteria_index: criteriaIndex + 1,
              })

        if (swappedTwo === undefined) {
          return null
        }
        const temp = swappedOne.criteria_index
        swappedOne.criteria_index = swappedTwo.criteria_index
        swappedTwo.criteria_index = temp

        setFormElement('accessibility_sections', nextSections)
      }
      return true
    })
  }

  const reindexSections = (sections) =>
    sections.map((section, i) => ({
      ...section,
      accessibility_section_index: i,
    }))

  const changeLabel = (sectionToUpdate, name, value) => {
    const nextSections = template.accessibility_sections || []
    nextSections.forEach((nextSection, index) => {
      if (nextSection.id === sectionToUpdate.id) {
        nextSections[index][name] = value
      }
    })

    setFormElement('accessibility_sections', nextSections)
  }

  const deleteSection = (sectionToDelete) => {
    let newSections = JSON.parse(
      JSON.stringify(template.accessibility_sections),
    )
    newSections = filter(
      newSections,
      (section) => section.id !== sectionToDelete.id,
    )
    setFormElement('accessibility_sections', reindexSections(newSections))
  }

  const pushNewSection = () => {
    const newSections = template.accessibility_sections || []
    const id = uid()
    newSections.push({
      id,
      name: '',
      accessibility_section_index: newSections.length,
      accessibility_section_criteria_maps: [],
    })
    setFormElement('accessibility_sections', newSections)
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  }

  const confirmDeleteSection = (section) => {
    popConfirmationDialog(
      'Modification de critère',
      'Êtes-vous sûr de vouloir supprimer cette réponse ?',
      () => {
        deleteSection(section)
      },
      () => {},
    )
  }

  const orderedSections = sortBy(
    template.accessibility_sections,
    (section) => section.accessibility_section_index,
  )

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography className={classes.title} variant="h5">
          Liste des sections
        </Typography>

        <Button iconLeft="add" onClick={() => pushNewSection()}>
          Ajouter une section
        </Button>
      </div>
      <div className={classes.content}>
        {orderedSections.map((section, index) => (
          <Section
            template={template}
            setFormElement={setFormElement}
            key={section.id}
            index={index + 1}
            isFirst={index === 0}
            isLast={index + 1 === orderedSections.length}
            section={section}
            moveSection={moveSection}
            changeLabel={(e) =>
              changeLabel(section, e.target.name, e.target.value)
            }
            onDelete={() => confirmDeleteSection(section)}
            onDeleteCriteria={onDeleteCriteria}
            moveCriteriaInSection={moveCriteriaInSection}
          />
        ))}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  popConfirmationDialog: (title, message, onAgree, onCancel) =>
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel)),
})

export default connect(null, mapDispatchToProps)(SectionsForm)
