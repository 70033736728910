/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const FemmeEnceinte = (props) => (
  <svg viewBox="0 0 14.499 14.499" {...props}>
    <g>
      <path d="M14.465 5.343c0-2.617-2.068-4.738-4.621-4.738A4.446 4.446 0 0 0 8.56.794l1.284 4.55 4.621-.001z" />
      <path d="M4.239 5.997a2.618 2.618 0 0 0-.563-1.483c-.458-.574-1.156-.953-2.072-1.139a.565.565 0 0 0-.431-.2H.572a.573.573 0 0 0 0 1.145h.601c.14 0 .266-.052.365-.135.673.15 1.176.425 1.499.822.292.36.375.746.396.991H3.41c0 2.505 2.482 4.537 5.545 4.537 3.062 0 5.544-2.031 5.544-4.537l-10.26-.001zM6.77 10.735c-.871 0-1.579.708-1.579 1.579s.708 1.579 1.579 1.579 1.579-.708 1.579-1.579-.708-1.579-1.579-1.579zm0 2.352c-.426 0-.773-.347-.773-.772s.347-.773.773-.773.773.348.773.773-.347.772-.773.772zM11.029 10.735c-.871 0-1.58.708-1.58 1.579s.709 1.579 1.58 1.579c.87 0 1.579-.708 1.579-1.579s-.709-1.579-1.579-1.579zm0 2.352a.773.773 0 0 1 0-1.545.773.773 0 0 1 0 1.545z" />
    </g>
  </svg>
)

export default FemmeEnceinte
