/* eslint-disable import/no-cycle */
import mainFetchWrapper from 'api/fetch'
import { getHeaders, getUrl } from 'api/utils/getters'

const getNamespaceHeaders = (method) => ({
  method,
  headers: getHeaders('picto-users'),
})

export const namespaceFetchWrapper =
  (method, endpoint, urlParams, body = null) =>
  (callbacks = {}) =>
    mainFetchWrapper(
      getUrl(endpoint, urlParams),
      getNamespaceHeaders(method),
      callbacks,
      body,
    )
