import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText,
} from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { AUDIT_FEATURES_TYPES } from 'domains/audit_missions/enums'
import { map, includes, difference } from 'lodash'

const CustomListItemText = ({ deselected, classes, type }) => (
  <ListItemText
    classes={{
      root: deselected ? classes.deselected : '',
    }}
    primary={type.label}
  />
)

const useStyles = makeStyles({
  container: {},
  deselected: {
    opacity: 0.5,
    textDecoration: 'line-through',
  },
})

const ListPermissionsModal = ({ permissionsState, setPermissionsState }) => {
  const classes = useStyles()
  const handleChange = (type) => {
    if (includes(permissionsState, type)) {
      setPermissionsState(difference(permissionsState, [type]))
    } else {
      setPermissionsState([...permissionsState, type])
    }
  }
  return (
    <List>
      <Divider />
      {map(AUDIT_FEATURES_TYPES, (type) => {
        const deselected = permissionsState.includes(type.value)
        return (
          <ListItem
            key={type.value}
            divider
            selected={deselected}
            button
            onClick={() => handleChange(type.value)}
          >
            <ListItemIcon>{deselected ? <Close /> : <Check />}</ListItemIcon>
            <CustomListItemText
              deselected={deselected}
              classes={classes}
              type={type}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default ListPermissionsModal
