import { popSnackbarAction, setLoadingAction } from 'domains/control/actions'
import { push } from 'connected-react-router'
import {
  getTemplateByIdAction,
  updateTemplateAction,
} from 'domains/templates/actions'
import { formatErrorsCriteriaForm } from 'domains/criterias/formatters'
import { map, omit } from 'lodash'
import {
  addToBodyIfArraysWereTouched,
  addToBodyIfSectionsWereTouched,
} from './parsers'

export const setFormElementAction = (element, value) => ({
  type: 'SET_TEMPLATE_EDIT_FORM',
  element,
  value,
})

export const setTemplateToEditAction = (template) => ({
  type: 'SET_TEMPLATE_TO_EDIT',
  payload: template,
})

export const fetchTemplateToEditAction = (templateId) => (dispatch) =>
  dispatch(
    getTemplateByIdAction(templateId, {
      onBefore: async () => dispatch(setLoadingAction(true)),
      onSuccess: async (template) =>
        dispatch(setTemplateToEditAction(template)),
      onAfter: async () => dispatch(setLoadingAction(false)),
    }),
  )

// eslint-disable-next-line react/function-component-definition
export const updateFormTemplateAction = () => (dispatch, getState) => {
  const original = JSON.parse(
    JSON.stringify(getState().forms.template.edit.original_template),
  )
  const updated = JSON.parse(
    JSON.stringify(
      omit(getState().forms.template.edit, [
        'original_template',
        'criteria_search',
        'form_submitted_once',
      ]),
    ),
  )

  let body = {}

  map(
    omit(updated, [
      'updated',
      'accessibility_sections',
      'brands',
      'category',
      'level_two_category',
      'level_three_category',
      'tags',
      'good_practice',
    ]),
    (attr, key) => {
      if (attr !== original[key]) {
        body[key] = attr
      }
    },
  )

  body = addToBodyIfArraysWereTouched(original, updated, body)
  body = addToBodyIfSectionsWereTouched(original, updated, body)

  if (!Object.keys(body).length) {
    dispatch(setLoadingAction(false))
    dispatch(
      popSnackbarAction("Aucun changement n'est à enregistrer", 'warning'),
    )
    return null
  }

  return dispatch(
    updateTemplateAction(updated.id, body, {
      onBefore: async () => {
        dispatch(setFormElementAction('form_submitted_once', true))
        dispatch(setLoadingAction(true))
      },
      onSuccess: async (res) => {
        dispatch(push(`/templates/${res.id}/edit`))
        dispatch(popSnackbarAction('Le template a bien été modifié', 'success'))
      },
      onFailure: async (res) => {
        dispatch(
          popSnackbarAction(
            formatErrorsCriteriaForm(
              'Erreur(s) lors de la mise à jour du template',
              res.errors,
            ),
            'error',
          ),
        )
      },
      onAfter: async () => dispatch(setLoadingAction(false)),
    }),
  )
}
