import {
  METHOD_GET,
  METHOD_PATCH,
  METHOD_POST,
  METHOD_DELETE,
} from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from '.'

const suffix = 'audit_missions'

export const getAuditMissions = (query_params = {}) =>
  nsfw(METHOD_GET, suffix, query_params)

export const searchAuditMissions = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/search`, query_params)

export const getAuditMissionById = (id, query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/${id}`, query_params)

export const createAuditMission = (body, query_params = {}) =>
  nsfw(METHOD_POST, suffix, query_params, body)

export const updateAuditMission = (id, body, query_params = {}) =>
  nsfw(METHOD_PATCH, `${suffix}/${id}`, query_params, body)

export const deleteAuditMission = (id, query_params = {}) =>
  nsfw(METHOD_DELETE, `${suffix}/${id}`, query_params)
