/* eslint-disable no-param-reassign */
import { find } from 'lodash'

const pushNewManipulation = (object, manipulation) => {
  object[`${Object.keys(object).length}`] = manipulation
}

const pushManipulationIfSectionCriteriaUpdated = (
  originalCriterias,
  updatedCriterias,
  criteriasBody,
) => {
  updatedCriterias.forEach((updatedCriteria) => {
    const found = find(
      originalCriterias,
      (originalCriteria) =>
        originalCriteria.criteria.id === updatedCriteria.criteria.id,
    )

    if (found && found.criteria_index !== updatedCriteria.criteria_index) {
      pushNewManipulation(criteriasBody, {
        criteria_index: updatedCriteria.criteria_index,
        id: updatedCriteria.id,
      })
    }
  })
}

const pushManipulationIfSectionCriteriaAdded = (
  originalCriterias,
  updatedCriterias,
  criteriasBody,
) => {
  updatedCriterias.forEach((updatedCriteria) => {
    const found = find(
      originalCriterias,
      (originalCriteria) =>
        originalCriteria.criteria.id === updatedCriteria.criteria.id,
    )

    if (!found) {
      pushNewManipulation(criteriasBody, {
        criteria_index: updatedCriteria.criteria_index,
        criteria_id: updatedCriteria.criteria.id,
      })
    }
  })
}

const pushManipulationIfSectionCriteriaDestroyed = (
  originalCriterias,
  updatedCriterias,
  criteriasBody,
) => {
  originalCriterias.forEach((originalCriteria) => {
    const found = find(
      updatedCriterias,
      (updatedCriteria) =>
        originalCriteria.criteria.id === updatedCriteria.criteria.id,
    )

    if (!found) {
      pushNewManipulation(criteriasBody, {
        id: originalCriteria.id,
        _destroy: true,
      })
    }
  })
}

const pushManipulationIfSectionDestroyed = (
  originalSections,
  updatedSections,
  sectionsBody,
) => {
  originalSections.forEach((originalSection) => {
    if (!find(updatedSections, { id: originalSection.id })) {
      pushNewManipulation(sectionsBody, {
        id: originalSection.id,
        _destroy: true,
      })
    }
  })
}

const pushManipulationIfSectionAdded = (
  originalSections,
  updatedSections,
  sectionsBody,
) => {
  updatedSections.forEach((updatedSection) => {
    if (!find(originalSections, { id: updatedSection.id })) {
      const {
        accessibility_section_criteria_maps: accessibilitySectionCriteriaMaps,
        id,
        ...eligiblesAttributes
      } = updatedSection

      const formattedAttributes = {}
      accessibilitySectionCriteriaMaps.forEach((criteria, criteriaIndex) => {
        formattedAttributes[`${criteriaIndex}`] = {
          criteria_id: criteria.criteria.id,
          criteria_index: criteria.criteria_index,
        }
      })

      pushNewManipulation(sectionsBody, {
        ...eligiblesAttributes,
        accessibility_section_criteria_maps_attributes: formattedAttributes,
      })
    }
  })
}

const pushManipulationIfSectionUpdated = (
  originalSections,
  updatedSections,
  sectionsBody,
) => {
  updatedSections.forEach((updatedSection) => {
    const relativeOriginalSections = find(originalSections, {
      id: updatedSection.id,
    })
    if (relativeOriginalSections) {
      const sectionBody = {}

      if (updatedSection.name !== relativeOriginalSections.name) {
        sectionBody.name = updatedSection.name
      }

      if (updatedSection.description !== relativeOriginalSections.description) {
        sectionBody.description = updatedSection.description
      }

      if (
        updatedSection.accessibility_section_index !==
        relativeOriginalSections.accessibility_section_index
      ) {
        sectionBody.accessibility_section_index =
          updatedSection.accessibility_section_index
      }

      const criteriaAttributes = {}
      pushManipulationIfSectionCriteriaAdded(
        relativeOriginalSections.accessibility_section_criteria_maps,
        updatedSection.accessibility_section_criteria_maps,
        criteriaAttributes,
      )

      pushManipulationIfSectionCriteriaDestroyed(
        relativeOriginalSections.accessibility_section_criteria_maps,
        updatedSection.accessibility_section_criteria_maps,
        criteriaAttributes,
      )

      pushManipulationIfSectionCriteriaUpdated(
        relativeOriginalSections.accessibility_section_criteria_maps,
        updatedSection.accessibility_section_criteria_maps,
        criteriaAttributes,
      )

      if (Object.keys(criteriaAttributes).length) {
        sectionBody.accessibility_section_criteria_maps_attributes =
          criteriaAttributes
      }

      const sectionManipulation = {
        ...sectionBody,
        id: updatedSection.id,
      }

      if (Object.keys(sectionBody).length) {
        pushNewManipulation(sectionsBody, sectionManipulation)
      }
    }
  })
}

export const addToBodyIfArraysWereTouched = (original, updated, body) => {
  if (
    original.category !== updated.category ||
    original.level_two_category !== updated.level_two_category ||
    original.level_three_category !== updated.level_three_category
  ) {
    const categories = []
    if (updated.category) {
      categories.push(parseInt(updated.category, 10))
    }
    if (updated.level_two_category) {
      categories.push(parseInt(updated.level_two_category, 10))
    }
    if (updated.level_three_category) {
      categories.push(parseInt(updated.level_three_category, 10))
    }

    body.category_ids = categories
  }

  if (original.brands !== updated.brands) {
    const brands = []
    if (updated.brands) {
      brands.push(parseInt(updated.brands, 10))
      body.brand_ids = brands
    }
  }

  if (original.tags !== updated.tags) {
    if (updated.tags) {
      const tags = updated.tags.split(',').map((tag) => parseInt(tag, 10))
      body.tag_ids = tags
    }

    if (updated.tags === '') {
      body.tag_ids = []
    }
  }

  if (original.good_practice !== updated.good_practice) {
    body.good_practice_id = parseInt(updated.good_practice, 10)
  }

  return body
}

export const addToBodyIfSectionsWereTouched = (original, updated, body) => {
  const sectionsBody = {}
  const originalSections = original.accessibility_sections
  const updatedSections = updated.accessibility_sections

  pushManipulationIfSectionDestroyed(
    originalSections,
    updatedSections,
    sectionsBody,
  )
  pushManipulationIfSectionAdded(
    originalSections,
    updatedSections,
    sectionsBody,
  )
  pushManipulationIfSectionUpdated(
    originalSections,
    updatedSections,
    sectionsBody,
  )

  if (Object.keys(sectionsBody).length) {
    body.accessibility_sections_attributes = sectionsBody
  }

  return body
}
