/* eslint-disable camelcase */
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import forms from './forms'
import control from './control/reducer'
import criterias from './criterias/reducer'
import referential_templates from './templates/reducer'
import audit_missions from './audit_missions/reducer'
import brands from './brands/reducer'
import tags from './tags/reducer'
import good_practices from './good_practices/reducer'
import categories from './categories/reducer'
import cards from './cards/reducer'
import audits from './audits/reducer'

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    forms,
    control,
    criterias,
    referential_templates,
    audit_missions,
    brands,
    tags,
    categories,
    cards,
    audits,
    good_practices,
  })

export default reducers
