const initialState = {
  form_submitted_once: false,
  ref: '',
  name: '',
  explanation: '',
  question: '',
  published: false,
  archived: false,
  handicaps: [],
  criteria_type: '',
  criteria_choices: [],
  original_criteria: {},
  updated: true,
}

const edit = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CRIT_EDIT_FORM':
      return {
        ...state,
        [action.element]: action.value,
        updated: false,
      }
    case 'SET_CRITERIA_TO_EDIT':
      return {
        ...initialState,
        ...action.payload,
        original_criteria: { ...JSON.parse(JSON.stringify(action.payload)) },
      }
    default:
      return state
  }
}

export default edit
