import { getTags } from 'api/domains/public/tags'
import { SET_TAGS } from './types'

export const setTagsAction = (payload) => ({
  type: SET_TAGS,
  payload,
})

export const getTagsAction =
  (callbacks = {}) =>
  (dispatch) => {
    const onSuccess = async (res) => dispatch(setTagsAction(res))

    return getTags()({
      onSuccess,
      ...callbacks,
    })
  }
