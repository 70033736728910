import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core'
import BooleanIcon from 'components/atoms/BooleanIcon'
import { map, orderBy, get } from 'lodash'

const useStyles = makeStyles((theme) => ({
  color_primary: {
    color: theme.palette.primary.main,
  },
  row: {
    cursor: 'pointer',
  },
}))

const SimpleTable = (props) => {
  const { templates, onSelect } = props
  const classes = useStyles()

  return (
    <Table className={classes.table} aria-label="template search table">
      <TableHead>
        <TableRow>
          <TableCell>Référence</TableCell>
          <TableCell>Nom</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Privé</TableCell>
          <TableCell>Publié</TableCell>
          <TableCell>Catégories</TableCell>
          <TableCell>Marques</TableCell>
          <TableCell>Tags</TableCell>
          <TableCell>Bonnes Pratiques</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map(templates, (template) => (
          <TableRow
            className={classes.row}
            hover
            key={template.ref}
            onClick={() => onSelect(template)}
          >
            <TableCell>
              <Typography>
                <strong>{template.ref}</strong>
              </Typography>
            </TableCell>
            <TableCell>{template.name}</TableCell>
            <TableCell>{template.description}</TableCell>
            <TableCell>
              <BooleanIcon value={template.private} />
            </TableCell>
            <TableCell>
              <BooleanIcon value={template.published} />
            </TableCell>
            <TableCell>
              <Typography>
                {map(
                  orderBy(template.categories, ['depth'], ['desc']),
                  (category) => category.name,
                ).join(', ')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {map(template.brands, (brand) => brand.name).join(', ')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {map(template.tags, (tag) => tag.name).join(', ')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {template.good_practice &&
                  `[${get(template, 'good_practice.ref')}] ${get(
                    template,
                    'good_practice.title',
                  )}`}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SimpleTable
