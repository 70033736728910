import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: ({ padding }) => theme.spacing(padding),
  },
}))

const WithPadding = ({ padding = 4, className = '', children }) => {
  const classes = useStyles({ padding })

  return <div className={`${classes.root} ${className}`}>{children}</div>
}

export default WithPadding
