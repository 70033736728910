import {
  categoriesLevelOneToOptions,
  categoriesLevelTwoToOptions,
  categoriesLevelThreeToOptions,
} from './formatters'

export const categoryListSelector = (state) => state.categories.list

export const categoriesLevelOneOptionsSelector = (state) =>
  categoriesLevelOneToOptions(categoryListSelector(state))

export const categoriesLevelTwoOptionsSelector = (
  state,
  levelOneCategory = null,
) => categoriesLevelTwoToOptions(categoryListSelector(state), levelOneCategory)

export const categoriesLevelTheeOptionsSelector = (
  state,
  levelTwoCategory = null,
) =>
  categoriesLevelThreeToOptions(categoryListSelector(state), levelTwoCategory)
export const categoryExpiresAtSelector = (state) => state.categories.expiresAt
