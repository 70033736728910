import { push } from 'connected-react-router'
import { createReferentialForCards } from 'api/domains/admin/referential_templates'
import { popSnackbarAction, setLoadingAction } from 'domains/control/actions'
import { cardLinkFormValuesSelector } from './selectors'
import { SET_SELECTED_TEMPLATE, SET_SELECTED_CARDS } from './constants'

export const setSelectedTemplateAction = (payload) => ({
  type: SET_SELECTED_TEMPLATE,
  payload,
})

export const setSelectedCardsAction = (payload) => ({
  type: SET_SELECTED_CARDS,
  payload,
})

export const bindCardsAndTemplateAction = () => (dispatch, getState) => {
  dispatch(setLoadingAction(true))
  const formValues = cardLinkFormValuesSelector(getState())
  const templateId = formValues.selected_template.id
  const cardArray = formValues.selected_cards.map((card) => card.id)

  return createReferentialForCards(templateId, { card_ids: cardArray })({
    onSuccess: async () => {
      dispatch(setSelectedTemplateAction(null))
      dispatch(setSelectedCardsAction([]))
      dispatch(push('/templates'))
      dispatch(
        popSnackbarAction('Les lieux ont été liés avec succès', 'success'),
      )
    },
    onAfter: async () => dispatch(setLoadingAction(false)),
  })
}
