import React from 'react'
import { connect } from 'react-redux'
import { push as pushAction } from 'connected-react-router'
import {
  deleteCriteriaAction,
  setSelectedCriteriaAction,
  duplicateCriteriaAction,
} from 'domains/criterias/actions'
import { Box, Typography, Tooltip, Fab, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Edit from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import Delete from '@material-ui/icons/Delete'
import { choiceTypesEnum } from 'domains/criterias/enums'
import HandicapIconsList from 'components/atoms/HandicapIconsList'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import moment from 'moment'
import 'moment/locale/fr'
import DetailRow from 'components/molecules/DetailRow'
import BooleanIcon from 'components/atoms/BooleanIcon'

moment.locale('fr')
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  fab: {
    marginLeft: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    margin: 0,
  },
  link: {
    marginLeft: theme.spacing(1),
  },
  linkItem: {
    display: 'flex',
  },
}))

const Full = (props) => {
  const {
    criteria,
    deleteCriteria,
    duplicateCriteria,
    setSelectedCriteria,
    push,
    setOpen,
  } = props
  const classes = useStyles()

  const handleEdit = (id) => {
    push(`/criterias/${id}/edit`)
    setSelectedCriteria(null)
  }

  return (
    criteria && (
      <PaperWithPadding>
        <Typography gutterBottom variant="h5">
          {`Critère ${criteria.ref}`}
        </Typography>
        <Divider className={classes.divider} />
        <DetailRow title="Nom">{criteria.name}</DetailRow>
        <DetailRow title="Question">{criteria.question}</DetailRow>
        <DetailRow title="Explications">{criteria.explanation}</DetailRow>
        <DetailRow title="Publié">
          <BooleanIcon value={criteria.published} />
        </DetailRow>
        <DetailRow title="Archivé">
          <BooleanIcon value={criteria.archived} />
        </DetailRow>
        <DetailRow title="Handicaps">
          <HandicapIconsList handicaps={criteria.handicaps} />
        </DetailRow>
        <DetailRow title="Type de choix">
          {choiceTypesEnum(criteria.criteria_type)}
        </DetailRow>
        <DetailRow wrap title="Choix">
          <ul className={classes.list}>
            {criteria.criteria_choices.map((choice) => (
              <li key={choice.id} className={classes.linkItem}>
                <Typography>{choice.label}</Typography>
              </li>
            ))}
          </ul>
        </DetailRow>
        <DetailRow title="Créé le">
          {moment(criteria.created_at).format('L')}
        </DetailRow>
        <DetailRow title="Mis à jour le">
          {moment(criteria.updated_at).format('L')}
        </DetailRow>
        <Divider className={classes.divider} />
        <Box className={classes.actions}>
          <Tooltip placement="top" title="Modifier le critère">
            <Fab
              size="small"
              color="primary"
              aria-label="Modifier le critère"
              className={classes.fab}
              onClick={() => handleEdit(criteria.id)}
            >
              <Edit />
            </Fab>
          </Tooltip>
          <Tooltip placement="top" title="Dupliquer le critère">
            <Fab
              size="small"
              color="primary"
              aria-label="Dupliquer le critère"
              className={classes.fab}
              onClick={() => duplicateCriteria(criteria.id)}
            >
              <FileCopy />
            </Fab>
          </Tooltip>
          <Tooltip placement="top" title="Supprimer le critère">
            <Fab
              size="small"
              color="primary"
              aria-label="Supprimer le critère"
              className={classes.fab}
              onClick={() => {
                deleteCriteria(criteria.id)
                setOpen(false)
              }}
            >
              <Delete />
            </Fab>
          </Tooltip>
        </Box>
      </PaperWithPadding>
    )
  )
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedCriteria: (criteria) => {
    dispatch(setSelectedCriteriaAction(criteria))
  },
  deleteCriteria: (id) => {
    dispatch(deleteCriteriaAction(id))
  },
  duplicateCriteria: (id) => {
    dispatch(duplicateCriteriaAction(id))
  },
  push: (url) => {
    dispatch(pushAction(url))
  },
})

export default connect(null, mapDispatchToProps)(Full)
