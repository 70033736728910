/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const AuditifPartial = (props) => (
  <svg viewBox="0 0 1190.551 841.89" {...props}>
    <path d="M425.98 332.487c-15.997-9.414-30.464-17.289-44.042-26.482-2.586-1.75-2.379-8.721-2.235-13.243 2.32-72.743 27.038-135.979 79.339-187.731 56.405-55.809 124.84-81.238 203.3-79.362 46.945 1.121 90.891 14.965 131.579 38.752C898.41 125.51 946.144 251.055 915.772 365.687c-11.847 44.715-33.195 84.032-63.368 118.684-26.052 29.924-55.374 57.436-78.292 89.553-28.399 39.794-52.973 82.517-76.893 125.265-37.435 66.899-127.083 85.488-186.98 37.244-10.564-8.51-10.37-9.979.031-18.934 6.463-5.564 12.448-11.723 18.295-17.95 5.049-5.375 9.684-5.707 15.476-1.027 41.964 33.921 92.462 8.035 111.058-27.608 33.327-63.895 74.401-122.745 122.818-176.431 22.808-25.29 46.93-50.172 65.182-78.624 45.392-70.75 47.188-145.052 10.468-220.745-22.032-45.421-56.06-79.231-101.016-100.068-76.768-35.573-152.207-32.37-224.957 13.797-33.542 21.287-58.155 50.577-76.847 84.206-22.067 39.698-28.229 83.729-24.791 128.991.185 2.466.024 4.959.024 10.447z" />
    <path d="M737.457 405.177c43.15-38.132 57.659-84.176 47.88-137.257-5.927-32.19-23.238-58.725-49.425-78.605-41.189-31.274-86.906-37.123-134.43-18.448-47.03 18.479-74.933 54.814-85.1 105.425-12.175-7.153-21.99-13.128-32.007-18.737-7.26-4.064-8.458-9.393-6.056-17.162 7.031-22.791 19.066-42.611 34.055-60.92 58.34-71.281 164.035-86.981 243.353-33.781 73.809 49.501 98.74 145.436 63.493 224.892-8.167 18.411-21.448 34.575-32.645 51.597-4.787 7.285-10.601 6.694-17.523 2.006-9.388-6.352-19.415-11.754-31.595-19.01z" />
  </svg>
)

export default AuditifPartial
