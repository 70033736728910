import React from 'react'
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({})

const generateOptions = (options) =>
  options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))

const Selected = ({ className, label, value, onChange, options }) => {
  const classes = useStyles()
  return (
    <FormControl className={`${classes.formControl} ${className}`}>
      <InputLabel htmlFor="age-simple">{label}</InputLabel>
      <Select value={value || ''} onChange={onChange}>
        {generateOptions(options)}
      </Select>
    </FormControl>
  )
}

export default Selected
