const initialState = {
  selected_audits: [],
  selected_template: null,
}

const main = (state = initialState, action) => {
  switch (action.type) {
    case 'LinkReferentialToAudits/SET_SELECTED_AUDITS':
      return {
        ...state,
        selected_audits: action.payload,
      }
    case 'LinkReferentialToAudits/SET_SELECTED_TEMPLATE':
      return {
        ...state,
        selected_template: action.payload,
      }
    default:
      return state
  }
}

export default main
