import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { render } from 'react-dom'
import { ThemeProvider } from '@material-ui/styles'
import { Provider } from 'react-redux'
import configureStore, { history } from 'env/store'
import theme from 'env/theme'
import * as serviceWorker from './serviceWorker'
import Root from './components/Root'
import initSentry from './sentry'
import './assets/stylesheets/index.css'

if (window.env.NODE_ENV === 'production') {
  initSentry()
}

const { store, persistor } = configureStore()

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <Root />
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
