import { isEmpty } from 'lodash'
import moment from 'moment'
import { getBrandsAction } from 'domains/brands/actions'
import { getTagsAction } from 'domains/tags/actions'
import { getCategoriesAction } from 'domains/categories/actions'
import { getGoodPracticesAction } from 'domains/good_practices/actions'
import { tagListSelector, tagExpiresAtSelector } from 'domains/tags/selectors'
import {
  brandListSelector,
  brandExpiresAtSelector,
} from 'domains/brands/selectors'
import {
  categoryListSelector,
  categoryExpiresAtSelector,
} from 'domains/categories/selectors'
import {
  goodPracticeListSelector,
  goodPracticeExpiresAtSelector,
} from 'domains/good_practices/selectors'
import {
  SET_SNACKBAR,
  POP_SNACKBAR,
  SET_CONFIRMATION_DIALOG,
  POP_CONFIRMATION_DIALOG,
  SET_LOADING,
} from './types'

export const setSnackbarAction = (attribute, value) => ({
  type: SET_SNACKBAR,
  attribute,
  value,
})

export const popSnackbarAction = (message, snackType) => ({
  type: POP_SNACKBAR,
  message,
  snack_type: snackType,
})

export const setConfirmationDialog = (attribute, value) => ({
  type: SET_CONFIRMATION_DIALOG,
  attribute,
  value,
})

export const popConfirmationDialogAction = (
  title,
  message,
  onAgree,
  onCancel,
) => ({
  type: POP_CONFIRMATION_DIALOG,
  title,
  message,
  onAgree,
  onCancel,
})

export const setLoadingAction = (payload) => ({
  type: SET_LOADING,
  payload,
})

export const initializeAppAction = () => (dispatch, getState) => {
  const state = getState()
  if (
    isEmpty(tagListSelector(state)) ||
    moment(tagExpiresAtSelector(state)).isBefore()
  ) {
    dispatch(getTagsAction())
  }
  if (
    isEmpty(brandListSelector(state)) ||
    moment(brandExpiresAtSelector(state)).isBefore()
  ) {
    dispatch(getBrandsAction())
  }
  if (
    isEmpty(categoryListSelector(state)) ||
    moment(categoryExpiresAtSelector(state)).isBefore()
  ) {
    dispatch(getCategoriesAction())
  }
  if (
    isEmpty(goodPracticeListSelector(state)) ||
    moment(goodPracticeExpiresAtSelector(state)).isBefore()
  ) {
    dispatch(getGoodPracticesAction())
  }
}
