/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Moteur = (props) => (
  <svg viewBox="0 0 1190.55 841.89" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M599.187 319.499c.659 14.648 1.26 28.578 1.921 42.504.419 8.832 1.033 17.656 1.353 26.49.146 4.063 1.323 5.824 5.956 6.261 41.838 3.95 83.644 8.255 125.459 12.443 5.361.537 10.72 1.194 16.096 1.465 12.583.637 22.531 6.229 29.083 16.722 33.554 53.737 66.985 107.552 100.157 161.526 10.682 17.379 5.175 39.283-11.666 49.939-17.143 10.846-39.373 6.304-50.498-11.211-19.754-31.101-38.953-62.555-58.367-93.87-9.88-15.936-19.605-31.968-29.723-47.75-1.351-2.106-4.492-4.017-6.992-4.285-29.141-3.141-58.319-5.928-87.484-8.85-30.233-3.028-60.516-5.661-90.681-9.278-31.2-3.742-50.342-25.797-51.82-57.07-2.137-45.232-4.686-90.447-7.38-135.65-1.035-17.363-.352-33.997 11.034-48.513 14.52-18.512 38.561-26.253 60.77-19.185 22.475 7.153 37.923 27.008 39.15 50.318.773 14.708.774 14.71 15.406 14.715 33.219.012 66.438-.067 99.657.084 11.191.051 19.42 5.5 23.97 15.678 4.421 9.89 3.086 19.461-3.739 27.903-5.487 6.788-12.885 9.667-21.632 9.646-34.082-.084-68.165-.033-102.248-.033l-7.782.001z" />
      <path d="M572.771 726.99c-108.003 3.751-205.766-75.623-223.118-182.481-13.272-81.731 12.173-150.429 73.513-205.925 7.843-7.096 16.792-9.807 26.84-5.894 9.199 3.583 14.486 10.757 15.47 20.578.907 9.049-2.917 16.212-9.791 22.074-30.278 25.816-49.143 58.343-57.281 97.275-18.998 90.884 41.275 183.64 132.821 201.346 73.354 14.188 132.724-10.457 177.069-70.578 6.32-8.569 13.352-14.887 24.448-14.005 19.498 1.552 29.068 22.386 18.001 38.995-19.602 29.415-44.754 52.952-75.575 70.295-31.768 17.876-65.98 27.123-102.397 28.32zM534.265 64.547c34.261.043 62.605 28.61 62.348 62.838-.256 34.17-28.383 62.236-62.33 62.198-34.495-.039-62.679-28.152-62.675-62.517.004-34.337 28.293-62.563 62.657-62.519z" />
    </g>
  </svg>
)

export default Moteur
