import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tree from 'react-d3-tree'
import { generateTreeData } from './utils'
import './custom-tree.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    border: `5px dashed ${theme.palette.primary.light}`,
    marginLeft: theme.spacing(1),
    height: '100vh',
  },
  rootNode: {
    fill: 'red',
  },
}))

const CriteriaPreview = (props) => {
  const treeContainer = useRef(null)
  const { formValues } = props
  const [state, setState] = useState({})
  const classes = useStyles()

  useEffect(() => {
    setState({
      translate: {
        x: 15,
        y: 300,
      },
      zoom: 0.6,
    })
  }, [])

  return (
    <div className={classes.root} ref={treeContainer}>
      <Tree
        data={generateTreeData(formValues)}
        translate={state.translate}
        zoom={state.zoom}
        orientation="horizontal"
        transitionDuration={0}
        collapsible={false}
        rootNodeClassName="node__root"
        branchNodeClassName="node__branch"
        leafNodeClassName="node__leaf"
        nodeSize={{ x: 340, y: 100 }}
      />
    </div>
  )
}

export default CriteriaPreview
