import React, { useState, useEffect, useRef } from 'react'
import { sortBy } from 'lodash'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import ReactToPrint from 'react-to-print'
import { Print } from '@material-ui/icons'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import TreeView from '@material-ui/lab/TreeView'
import {
  formatSectionsToTreeView,
  getNodeIds,
  getAllNodeIds,
} from 'domains/templates/formatters'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import FullTemplatePreviewPrint from 'domains/templates/displayers/FullTemplatePreviewPrint'

const useStyles = makeStyles((theme) => ({
  referentialTree: {
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: '100vh',
    position: 'relative',
  },
  printIconContainer: {
    position: 'absolute',
    zIndex: 99,
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  visibilityIconContainer: {
    position: 'absolute',
    zIndex: 99,
    right: theme.spacing(10),
    top: theme.spacing(2),
  },
}))

const TemplatePreview = (props) => {
  const previewRef = useRef()
  const { accessibilitySections, formValues } = props
  const [expandedNodes, setExpandedNodes] = useState([])
  const [allNodeIds, setAllNodeIds] = useState([])
  const [treeView, setTreeView] = useState([])
  const [showVisibility, setShowVisibility] = useState(true)

  const classes = useStyles()

  useEffect(() => {
    const initialTreeView = formatSectionsToTreeView(
      sortBy(accessibilitySections, ['accessibility_section_index']),
    )
    const sectionsNodeIds = getNodeIds(initialTreeView)
    setExpandedNodes(sectionsNodeIds)
    setTreeView(initialTreeView)
    setAllNodeIds(getAllNodeIds(initialTreeView))
  }, [accessibilitySections])

  const onNodeToggle = (event, nodeIds) => setExpandedNodes(nodeIds)
  const expandAllReferential = () => {
    if (showVisibility) setExpandedNodes(allNodeIds)
    else setExpandedNodes([])
    setShowVisibility(!showVisibility)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PaperWithPadding className={classes.referentialTree}>
          <div className={classes.visibilityIconContainer}>
            <IconButton onClick={expandAllReferential}>
              {showVisibility ? (
                <Visibility color="primary" />
              ) : (
                <VisibilityOff color="primary" />
              )}
            </IconButton>
          </div>
          <div className={classes.printIconContainer}>
            <ReactToPrint
              pageStyle={{ padding: '20px', fontSize: '5px' }}
              trigger={() => (
                <IconButton>
                  <Print color="primary" />
                </IconButton>
              )}
              content={() => previewRef.current}
              handleClick={() => {
                return true
              }}
            />
          </div>
          <div>
            <TreeView onNodeToggle={onNodeToggle} expanded={expandedNodes}>
              {treeView}
            </TreeView>
          </div>
          <div style={{ display: 'none' }}>
            <div ref={previewRef}>
              <FullTemplatePreviewPrint template={formValues} />
              <div>
                <TreeView onNodeToggle={onNodeToggle} expanded={allNodeIds}>
                  {treeView}
                </TreeView>
              </div>
            </div>
          </div>
        </PaperWithPadding>
      </Grid>
    </Grid>
  )
}

export default TemplatePreview
