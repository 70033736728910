/* eslint-disable import/prefer-default-export */
import { get, capitalize, orderBy, map } from 'lodash'

export const formatCardTitle = (card) =>
  get(card, 'content.display_title', null) || get(card, 'content.title', null)

export const formatCardAddress = (localisation) => {
  if (!localisation) {
    return ''
  }

  const address = get(localisation, 'address', '')
  const cityName =
    get(localisation, 'city_name', '') || get(localisation, 'city.name', '')
  const postalCode =
    get(localisation, 'postal_code', '') ||
    get(localisation, 'city.postal_code', '')
  const country =
    get(localisation, 'country', '') || get(localisation, 'city.country', '')

  return `${capitalize(address)} ${postalCode} ${capitalize(
    cityName,
  )} ${capitalize(country)}`
}

export const formatCardCategories = (categories) =>
  categories &&
  map(
    orderBy(categories, ['depth'], ['asc']),
    (category) => category.name,
  ).join(', ')

export const formatCardHasReferential = (card) =>
  get(card, 'accessibility_description.referential.id', null) !== null &&
  get(card, 'accessibility_description.referential.answers', {}).length !== 0
