import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import AttributesForm from './AttributesForm'
import ChoicesForm from './ChoicesForm'
import CriteriaPreview from './CriteriaPreview'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}))

const CriteriaForm = (props) => {
  const { formValues, setFormElement } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AttributesForm formValues={formValues} setFormElement={setFormElement} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ChoicesForm
            formValues={formValues}
            setFormElement={setFormElement}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CriteriaPreview formValues={formValues} />
        </Grid>
      </Grid>
    </div>
  )
}

export default CriteriaForm
