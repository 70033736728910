import { getCategories } from 'api/domains/public/categories'
import { SET_CATEGORIES } from './types'

export const setCategoriesAction = (payload) => ({
  type: SET_CATEGORIES,
  payload,
})

export const getCategoriesAction =
  (callbacks = {}) =>
  (dispatch) => {
    const onSuccess = async (res) => dispatch(setCategoriesAction(res))

    return getCategories()({
      onSuccess,
      ...callbacks,
    })
  }
