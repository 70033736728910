import React from 'react'
import { Typography } from '@material-ui/core'
import { forEach, map } from 'lodash'
import { labelCriteriaEnum } from './enums'

export const formatErrorsCriteriaForm = (title, errors = {}) => {
  const getAllErrors = () => {
    const subErrors = []
    map(Object.keys(errors), (key) =>
      forEach(errors[key], (error) => {
        const labelCriteria = `"${labelCriteriaEnum(key)}" ${error}`
        subErrors.push(<Typography key={key}>{labelCriteria}</Typography>)
      }),
    )

    return subErrors
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title || ''}
      </Typography>
      {getAllErrors()}
    </div>
  )
}

export const choicesToString = (choices) => {
  let res = ''
  let totalLenght = 0
  if (choices.length === 0) {
    return 'Aucune réponse'
  }

  choices.forEach((choice, index) => {
    const prefix = index !== 0 ? ', ' : ''
    totalLenght += choice.label.length
    if (totalLenght > 20) {
      res += ',...'
    } else {
      res += prefix + choice.label
    }
    return res
  })

  return res
}
