/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const Auditif = (props) => (
  <svg viewBox="0 0 1190.551 841.89" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M205.559 658.85c24.603-23.347 49.285-46.607 73.781-70.066 21.108-20.214 41.925-40.731 63.025-60.954 9.042-8.664 18.645-16.746 27.672-25.426 5.392-5.185 9.669-6.517 17.141-2.263 31.48 17.927 63.572 34.775 95.323 52.231 10.984 6.039 21.629 12.698 33.558 19.742-11.927 11.787-22.741 22.543-33.631 33.222-23.757 23.295-47.297 46.817-71.4 69.748-27.389 26.057-55.308 51.553-82.945 77.347-2.174 2.029-3.869 4.562-5.929 6.725-7.143 7.508-14.177 13.068-26.289 12.568-30.056-1.241-60.198-.401-90.306-.397V658.85zM938.666 62.321c-.004 31.458.145 62.916-.214 94.37-.042 3.739-1.877 8.46-4.529 11.011-24.232 23.304-48.864 46.19-73.305 69.277-14.207 13.42-28.277 26.987-43.894 41.905-19.539-48.34-43.387-92.572-87.174-123.4 10.152-9.391 19.676-17.96 28.93-26.81 21.656-20.709 43.193-41.545 64.738-62.372 1.176-1.136 1.989-2.646 2.971-3.982l112.477.001z" />
      <path d="M384.255 376.361c-12.264-7.191-23.451-13.492-34.258-20.386-1.532-.978-1.693-4.854-1.683-7.387.202-50.62 14.126-97.668 48.373-134.708 61.099-66.085 136.874-91.064 226.044-70.08 45.893 10.798 84.012 33.583 115.383 69.136 39.374 44.623 53.035 96.787 49.042 154.605-2.784 40.316-17.707 76.651-41.048 109.211-8.758 12.219-20.671 22.171-31.111 33.19-30.74 32.444-61.191 65.02-82.531 104.975-10.399 19.472-21.786 38.526-34.121 56.83-27.197 40.363-89.332 54.358-131.235 28.506-5.908-3.645-12.03-6.975-17.728-10.919-4.743-3.283-4.225-6.947.271-10.497 6.479-5.118 13.067-10.164 19.026-15.852 4.273-4.079 7.666-4.1 12.097-.59 32.36 25.626 72.149 8.145 88.424-20.73 16.776-29.766 34.934-59.146 55.868-86.065 22.553-28.999 49.023-54.951 73.793-82.224 17.184-18.92 29.424-40.595 40.411-63.772 15.839-33.416 16.636-67.708 7.335-101.247-10.834-39.067-31.284-72.831-65.671-98.103-35.385-26.003-74.186-37.906-116.88-37.424-34.49.389-66.476 10.13-96.556 28.758-28.37 17.571-46.565 42.352-63.575 69.724-18.232 29.342-20.315 61.184-19.675 94.017.055 2.951.005 5.903.005 11.032z" />
      <path d="M457.287 332.698c-6.754-3.925-11.529-6.858-16.446-9.53-16.953-9.21-17.502-11.609-9.623-29.705 15.387-35.338 41.444-60.416 76.266-75.168 58.81-24.917 125.405-11.092 167.799 31.54 29.316 29.481 41.525 65.01 40.401 105.673-.776 28.042-10.223 53.563-25.799 76.669-4.195 6.224-10.203 11.309-15.813 16.441-1.618 1.48-5.417 2.561-7.08 1.68-9.304-4.929-18.26-10.517-29.03-16.879 34.475-30.906 46.125-68.774 36.169-112.045-5.416-23.542-18.628-43.662-39.125-57.589-33.382-22.684-69.247-27.514-107.539-13.855-38.961 13.898-58.839 44.43-70.18 82.768z" />
    </g>
  </svg>
)

export default Auditif
