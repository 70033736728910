/* eslint-disable import/prefer-default-export */
export const handicapsEnum = [
  {
    id: 1,
    slug: 'moteur',
    label: 'Moteur',
  },
  {
    id: 2,
    slug: 'psychique',
    label: 'Psychique',
  },
  {
    id: 3,
    slug: 'mal-entendant',
    label: 'Mal entendant',
  },
  {
    id: 4,
    slug: 'mal-voyant',
    label: 'Mal voyant',
  },
  {
    id: 5,
    slug: 'mal-voyant-partiel',
    label: 'Mal voyant partiel',
  },
  {
    id: 6,
    slug: 'mal-entendant-partiel',
    label: 'Mal entendant partiel',
  },
  {
    id: 7,
    slug: 'femme-enceinte',
    label: 'Femme enceinte',
  },
  {
    id: 8,
    slug: 'senior',
    label: 'Senior',
  },
]
