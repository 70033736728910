import React from 'react'
import { Prompt } from 'react-router-dom'

const WithWarningOnModification = (props) => {
  const { skip, updated } = props

  if (skip) {
    return null
  }

  if (!updated) {
    window.onbeforeunload = () => true
  } else {
    window.onbeforeunload = undefined
  }

  return (
    <Prompt
      when={!updated}
      message="Des modifications ont été enregistrées êtes vous sûr de vouloir quitter la page ?"
    />
  )
}

export default WithWarningOnModification
