import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Dialog from 'components/templates/Dialog'
import TemplateSimpleTable from 'domains/templates/displayers/SimpleTable'
import { TextField, CircularProgress, Typography } from '@material-ui/core'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import { debounce, isEmpty } from 'lodash'
import { getTemplatesWithSearchAction } from 'domains/templates/actions'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  text_field: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
  no_results: {
    marginTop: theme.spacing(2),
  },
}))

const TemplateSearcher = (props) => {
  const debouncerRef = useRef()
  const { open, setOpen, onSelect, getTemplatesWithSearch } = props
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])

  const classes = useStyles()

  useEffect(() => {
    debouncerRef.current = debounce(
      (search) =>
        getTemplatesWithSearch(search, {
          onBefore: async () => setLoading(true),
          onSuccess: async (res) => setResults(res.referential_templates),
          onAfter: async () => setLoading(false),
        }),
      500,
    )
    return () => {
      debouncerRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      maxWidth={false}
      open={open}
      setOpen={setOpen}
      title="Trouver un template"
    >
      <PaperWithPadding>
        <div className={classes.header}>
          <TextField
            className={classes.text_field}
            label="Rechercher un template"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value)
              debouncerRef.current(e.target.value)
            }}
            autoFocus
          />
          {loading && <CircularProgress size={30} />}
        </div>
        <TemplateSimpleTable templates={results} onSelect={onSelect} />
        {isEmpty(results) && (
          <Typography className={classes.no_results} align="center">
            Aucun résultat
          </Typography>
        )}
      </PaperWithPadding>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getTemplatesWithSearch: (query, callbacks) => {
    dispatch(getTemplatesWithSearchAction(query, callbacks))
  },
})

export default connect(null, mapDispatchToProps)(TemplateSearcher)
