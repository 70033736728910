import { isEmpty, forEach, size } from 'lodash'
import { ParamTypeError, logError } from './errors'

export const formatParams = (params) => {
  if (isEmpty(params)) {
    return ''
  }

  let res = '?'
  let index = 0
  const paramsSize = size(params)

  try {
    forEach(params, (param, key) => {
      if (Array.isArray(param)) {
        forEach(param, (item, eachIndex) => {
          if (typeof param === 'object' || typeof param === 'function') {
            throw ParamTypeError(key)
          }

          res += `${key}[]=${item}`

          if (eachIndex < param.length - 1) {
            res += '&'
          }
        })
      } else if (typeof param !== 'object' && typeof param !== 'function') {
        res += `${key}=${encodeURIComponent(param)}`
      } else {
        throw ParamTypeError(key)
      }

      if (index < paramsSize - 1) {
        res += '&'
      }
      index += 1
    })

    return res
  } catch (error) {
    logError(error)
    return ''
  }
}
