import {
  SET_SELECTED_CARD_ITEM,
  SET_SELECTED_CARDS,
  FLUSH_FORM,
  SET_FORM,
} from './constants'

export const flushFormAction = () => ({
  type: FLUSH_FORM,
})

export const setFormElementAction = (element, value) => ({
  type: SET_FORM,
  element,
  value,
})

export const setSelectedCardsAction = (payload) => ({
  type: SET_SELECTED_CARDS,
  payload,
})

export const setSelectedCardItemAction = (payload) => ({
  type: SET_SELECTED_CARD_ITEM,
  payload,
})
