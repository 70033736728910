import { sortBy } from 'lodash'
import {
  SET_AUDIT_MISSIONS,
  SET_SELECTED_AUDIT_MISSION,
  SET_PER_PAGE,
  SET_SEARCH,
} from './types'

const orderAudits = (auditMission) =>
  auditMission
    ? {
        ...auditMission,
        audits: sortBy(auditMission.audits, ['id']),
      }
    : null

const initialState = {
  list: [],
  pagination: null,
  selected: null,
  per_page: 25,
  search: '',
}

const all = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIT_MISSIONS:
      return {
        ...state,
        list: action.payload.audit_missions || [],
        pagination: action.payload.pagination || initialState.pagination,
      }
    case SET_SELECTED_AUDIT_MISSION:
      return {
        ...state,
        selected: orderAudits(action.payload),
      }
    case SET_PER_PAGE:
      return {
        ...state,
        per_page: action.payload,
      }
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      }
    default:
      return state
  }
}

export default all
