/* eslint-disable import/no-extraneous-dependencies */
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createBrowserHistory } from 'history'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createRootReducer from 'domains'

export const history = createBrowserHistory()

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
if (
  process.env.APP_ENV === 'production' ||
  process.env.NODE_ENV === 'production'
) {
  composeEnhancers = compose
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['tags', 'brands', 'categories', 'good_practices'],
}

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history),
)

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)),
  )

  const persistor = persistStore(store)
  return { store, persistor }
}
