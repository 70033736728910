import { formatToTemplate } from './formatters'

const initialState = {
  form_submitted_once: false,
  ref: '',
  name: '',
  private: false,
  description: '',
  published: false,
  created_at: '',
  updated_at: '',
  brands: '',
  category: '',
  level_two_category: '',
  level_three_category: '',
  tags: '',
  accessibility_sections: [],
  good_practice: '',
  original_template: {},
  updated: true,
}

const edit = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TEMPLATE_EDIT_FORM':
      return {
        ...state,
        [action.element]: action.value,
        updated: false,
      }
    case 'SET_TEMPLATE_TO_EDIT':
      return {
        ...initialState,
        ...formatToTemplate(action.payload),
        original_template: {
          ...JSON.parse(JSON.stringify(formatToTemplate(action.payload))),
        },
      }
    default:
      return state
  }
}

export default edit
