/* eslint-disable import/prefer-default-export */
import theme from 'env/theme'

const getCriteriaAttributes = (criteria) => {
  const res = {}
  res['Référence'] = criteria.ref
  if (criteria.criteria_choices) {
    criteria.criteria_choices.forEach((choice) => {
      const key = `R ${choice.choice_index + 1}`
      const value = choice.label
      res[key] = value
    })
  }

  return res
}

const getCriteriaChildren = (criteria) => {
  const res = []

  if (criteria.criteria_choices) {
    criteria.criteria_choices.forEach((choice) => {
      res.push({
        name: choice.label,
        nodeSvgShape: {
          shape: 'circle',
          shapeProps: {
            r: 10,
            fill: theme.palette.secondary.main,
          },
        },
        // eslint-disable-next-line no-use-before-define
        children: getChoiceChildren(choice),
      })
    })
  }

  return res
}

const getChoiceChildren = (choice) => {
  const res = []
  if (choice.linked_criterias) {
    choice.linked_criterias.forEach((criteria) => {
      res.push({
        name: criteria.name,
        nodeSvgShape: {
          shape: 'rect',
          shapeProps: {
            width: 20,
            height: 20,
            x: -10,
            y: -10,
            fill: theme.palette.primary.main,
          },
        },
        attributes: getCriteriaAttributes(criteria),
        children: getCriteriaChildren(criteria),
      })
    })
  }

  return res
}

export const generateTreeData = (criteria) => [
  {
    name: criteria.name,
    nodeSvgShape: {
      shape: 'rect',
      shapeProps: {
        width: 20,
        height: 20,
        x: -10,
        y: -10,
        fill: theme.palette.primary.main,
      },
    },
    attributes: getCriteriaAttributes(criteria),
    children: getCriteriaChildren(criteria),
  },
]
