import { getGoodPractices } from 'api/domains/public/good_practices'
import { SET_GOOD_PRACTICES } from './types'

export const setGoodPracticesAction = (payload) => ({
  type: SET_GOOD_PRACTICES,
  payload,
})

export const getGoodPracticesAction =
  (callbacks = {}) =>
  (dispatch) => {
    const onSuccess = async (res) =>
      dispatch(setGoodPracticesAction(res.good_practices))

    return getGoodPractices()({
      onSuccess,
      ...callbacks,
    })
  }
