import React, { useState } from 'react'
import moment from 'moment'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  makeStyles,
} from '@material-ui/core'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import AddCardToAudit from './AddCardToAudit'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardAddContainer: {
    flex: 2,
  },
  button: {
    width: 'fit-content',
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}))

const AuditMissionForm = (props) => {
  const classes = useStyles()
  const {
    formValues,
    setFormElement,
    confirmFlush,
    setSelectedCards,
    setSelectedCardItem,
    setCardsDialog,
  } = props

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const handleSelectedMenu = (e) => setMenuAnchorEl(e.currentTarget)

  const handleCardItemClick = (e, card) => {
    handleSelectedMenu(e)
    setSelectedCardItem(card)
  }

  return (
    <div className={classes.container}>
      <PaperWithPadding className={classes.form}>
        <TextField
          className={classes.textField}
          error={formValues.form_submitted_once && formValues.ref === ''}
          label="Référence"
          value={formValues.ref || ''}
          onChange={(e) => setFormElement('ref', e.target.value)}
        />
        <TextField
          className={classes.textField}
          error={formValues.form_submitted_once && formValues.token === ''}
          label="Code de connexion"
          value={formValues.token || ''}
          onChange={(e) => setFormElement('token', e.target.value)}
          helperText="Si vous changez le code de connexion, n'oubliez pas de transmettre le nouveau aux clients"
        />
        <TextField
          className={classes.textField}
          error={formValues.form_submitted_once && formValues.title === ''}
          label="Nom de la mission"
          value={formValues.title || ''}
          onChange={(e) => setFormElement('title', e.target.value)}
        />
        <TextField
          className={classes.textField}
          error={formValues.form_submitted_once && formValues.message === ''}
          label="Description"
          value={formValues.message || ''}
          onChange={(e) => setFormElement('message', e.target.value)}
        />
        <TextField
          className={classes.textField}
          error={
            formValues.form_submitted_once && formValues.operator_name === ''
          }
          label="Nom de l'opérateur"
          value={formValues.operator_name || ''}
          onChange={(e) => setFormElement('operator_name', e.target.value)}
        />
        <TextField
          className={classes.textField}
          id="date"
          label="Termine le"
          type="date"
          value={moment(formValues.ends_at).format('YYYY-MM-DD')}
          onChange={(e) =>
            setFormElement('ends_at', moment(e.target.value).format())
          }
        />

        <FormControl className={classes.textField}>
          <InputLabel htmlFor="status">Statut</InputLabel>
          <Select
            value={formValues.status}
            onChange={(e) => setFormElement('status', e.target.value)}
          >
            <MenuItem value="pending">En attente</MenuItem>
            <MenuItem value="in_progress">En cours</MenuItem>
            <MenuItem value="finish">Terminé</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          className={classes.textField}
          control={
            <Switch
              checked={formValues.active}
              onChange={() => setFormElement('active', !formValues.active)}
            />
          }
          label="Active"
        />
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={confirmFlush}
        >
          Réinitialiser le formulaire
        </Button>
      </PaperWithPadding>
      <AddCardToAudit
        className={classes.cardAddContainer}
        auditMission={formValues}
        selectedCards={formValues.selected_cards}
        menuAnchorEl={menuAnchorEl}
        setMenuAnchorEl={setMenuAnchorEl}
        handleCardItemClick={handleCardItemClick}
        setSelectedCards={setSelectedCards}
        setCardsDialog={setCardsDialog}
        setSelectedCardItem={setSelectedCardItem}
      />
    </div>
  )
}

export default AuditMissionForm
