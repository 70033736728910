import { combineReducers } from 'redux'
import create from './create/reducer'
import edit from './edit/reducer'

const reducers = combineReducers({
  create,
  edit,
})

export default reducers
