import { push } from 'connected-react-router'
import {
  popConfirmationDialogAction,
  popSnackbarAction,
  setLoadingAction,
} from 'domains/control/actions'
import {
  deleteAuditMission,
  getAuditMissions,
  updateAuditMission,
  getAuditMissionById,
  createAuditMission,
  searchAuditMissions,
} from 'api/domains/admin/audit_missions'
import { isEmpty, forEach } from 'lodash'
import {
  setFormElementAction,
  flushFormAction,
} from 'domains/forms/audit_mission/create/actions'
import { auditMissionCreateFormSelector } from 'domains/forms/audit_mission/create/selectors'
import { formatErrorsAuditMissionForm } from './formatters'
import {
  auditMissionPerPageSelector,
  auditMissionSearchSelector,
} from './selectors'
import {
  SET_AUDIT_MISSIONS,
  SET_SELECTED_AUDIT_MISSION,
  SET_PER_PAGE,
  SET_SEARCH,
} from './types'

const firstPage = 1

const setAuditMissionsAction = (payload) => ({
  type: SET_AUDIT_MISSIONS,
  payload,
})

export const setSelectedAuditMissionAction = (payload) => ({
  type: SET_SELECTED_AUDIT_MISSION,
  payload,
})

export const setAuditMissionsPerPageAction = (payload) => ({
  type: SET_PER_PAGE,
  payload,
})

export const setAuditMissionSearchAction = (payload) => ({
  type: SET_SEARCH,
  payload,
})

export const getAuditMissionsAction =
  (urlParams = {}, callbacks = {}) =>
  (dispatch, getState) => {
    const onBefore = async () => dispatch(setLoadingAction(true))
    const onSuccess = async (res) => {
      dispatch(setAuditMissionsAction(res))
    }

    const onAfter = async () => dispatch(setLoadingAction(false))

    const params = {
      with_audits: false,
      page: urlParams.page || firstPage,
      per_page: urlParams.per_page
        ? urlParams.per_page
        : auditMissionPerPageSelector(getState()),
    }

    if (!isEmpty(auditMissionSearchSelector(getState()))) {
      params['q[translations_title_or_operator_name_or_ref_cont]'] =
        auditMissionSearchSelector(getState())
      return searchAuditMissions(params)({
        onBefore,
        onSuccess,
        onAfter,
        ...callbacks,
      })
    }

    return getAuditMissions(params)({
      onBefore,
      onSuccess,
      onAfter,
      ...callbacks,
    })
  }

export const getAuditMissionsWithSearchAction = (query, callbacks) => () =>
  searchAuditMissions({
    with_audits: false,
    'q[translations_title_or_operator_name_or_ref_cont]': query,
  })(callbacks)

export const createAuditMissionAction = () => {
  const formatAuditsAttributes = (cards) => {
    const res = {}

    forEach(cards, (card, i) => {
      res[`${i}`] = {
        card_id: card.id,
        forbidden_features: card.forbidden_features || [],
      }
    })

    return res
  }

  return (dispatch, getState) => {
    const formValues = auditMissionCreateFormSelector(getState())

    const {
      form_submitted_once: formSubmittedOnce,
      selected_cards: selectedCards,
      audits,
      cards_dialog: cardsDialog,
      ...eligibleAttributes
    } = formValues

    const body = {
      ...eligibleAttributes,
      audits_attributes: formatAuditsAttributes(selectedCards),
    }

    return createAuditMission(body)({
      onBefore: async () => {
        dispatch(setFormElementAction('form_submitted_once', true))
        dispatch(setLoadingAction(true))
      },
      onSuccess: async () => {
        dispatch(push('/audit_missions'))
        dispatch(flushFormAction())
        dispatch(
          popSnackbarAction("La mission d'audit a bien été créée", 'success'),
        )
      },
      onFailure: async (res) =>
        dispatch(
          popSnackbarAction(
            formatErrorsAuditMissionForm(
              "Erreur(s) lors de la création de la mission d'audit",
              res.errors,
            ),
            'error',
          ),
        ),
      onAfter: async () => dispatch(setLoadingAction(false)),
    })
  }
}

export const getAuditMissionByIdAction =
  (id, callbacks = {}) =>
  (dispatch) => {
    const onBefore = async () => dispatch(setLoadingAction(true))
    const onSuccess = async (res) => {
      dispatch(setSelectedAuditMissionAction(res))
    }
    const onAfter = async () => dispatch(setLoadingAction(false))

    return getAuditMissionById(id)({
      onBefore,
      onSuccess,
      onAfter,
      ...callbacks,
    })
  }

export const getAuditMissionByIdParamsAction =
  (id, queryParams, callbacks = {}) =>
  (dispatch) => {
    const onBefore = async () => dispatch(setLoadingAction(true))
    const onSuccess = async (res) => {
      dispatch(setSelectedAuditMissionAction(res))
    }
    const onAfter = async () => dispatch(setLoadingAction(false))

    return getAuditMissionById(
      id,
      queryParams,
    )({
      onBefore,
      onSuccess,
      onAfter,
      ...callbacks,
    })
  }

export const deleteAuditMissionAction = (id) => (dispatch) =>
  dispatch(
    popConfirmationDialogAction(
      "Suppression d'un critère",
      'Êtes-vous sûr de vouloir supprimer ce critère ? \n Cette opération est irréversible',
      () =>
        deleteAuditMission(id)({
          onBefore: async () => dispatch(setLoadingAction(true)),
          onSuccess: async () => dispatch(getAuditMissionsAction()),
          onFailure: async (res) =>
            dispatch(
              popSnackbarAction(
                formatErrorsAuditMissionForm(
                  "Erreur(s) lors de la suppression de la mission d'audit",
                  res.errors,
                ),
                'error',
              ),
            ),
          onAfter: async () => dispatch(setLoadingAction(false)),
        }),
      () => null,
    ),
  )

export const updateAuditMissionAction = (id, body, callbacks) => (dispatch) => {
  const onBefore = async () => dispatch(setLoadingAction(true))
  const onAfter = async () => dispatch(setLoadingAction(false))

  return updateAuditMission(
    id,
    body,
  )({
    onBefore,
    onAfter,
    ...callbacks,
  })
}
