import React from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBox from '@material-ui/icons/CheckBox'
import { map, includes } from 'lodash'
import BooleanIcon from 'components/atoms/BooleanIcon'
import { auditStatusEnum } from 'domains/audits/enums'
import {
  formatAuditTitle,
  formatAuditSubmitter,
  formatAuditMissionReference,
  hasReferential,
} from 'domains/audits/formatters'

const useStyles = makeStyles((theme) => ({
  color_primary: {
    color: theme.palette.primary.main,
  },
  row: {
    cursor: 'pointer',
  },
  center: { textAlign: 'center' },
  overflowXScroll: { overflowX: 'scroll' },
}))

const AuditSimpleTable = (props) => {
  const {
    audits,
    className,
    selectedAudits = [],
    onSelect = null,
    onSelectAll = null,
    multiSelect = false,
    customColumns = null,
  } = props
  const classes = useStyles()

  return (
    <Box className={classes.overflowXScroll}>
      <Table
        className={`${classes.table} ${className}`}
        aria-label="Audit search table"
      >
        <TableHead>
          <TableRow>
            {multiSelect && (
              <TableCell>
                <CheckBoxOutlineBlank
                  className={classes.row}
                  color="action"
                  onClick={onSelectAll ? () => onSelectAll(audits) : null}
                />
              </TableCell>
            )}
            <TableCell>Référence de la mission d&apos;audit</TableCell>
            <TableCell>Titre</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Présence d&apos;un référentiel</TableCell>
            <TableCell>Auditeur/Auditrice</TableCell>
            {customColumns &&
              map(customColumns, (customColumn) => customColumn.head())}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(audits, (audit) => {
            const selected =
              multiSelect &&
              includes(
                map(selectedAudits, (selectedAudit) => selectedAudit.id),
                audit.id,
              )

            return (
              <TableRow
                className={classes.row}
                hover
                selected={selected}
                key={audit.id}
                onClick={onSelect ? (e) => onSelect(audit, e) : null}
              >
                {multiSelect && (
                  <TableCell>
                    {selected ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank color="action" />
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <Typography variant="body1" component="b">
                    {formatAuditMissionReference(audit)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" component="b">
                    {formatAuditTitle(audit)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {auditStatusEnum(audit.status)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.center}>
                  <BooleanIcon value={hasReferential(audit)} />
                </TableCell>
                <TableCell className={classes.center}>
                  {formatAuditSubmitter(audit)}
                </TableCell>
                {customColumns &&
                  map(customColumns, (customColumn) =>
                    customColumn.body(audit),
                  )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default AuditSimpleTable
