import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Button from 'components/atoms/Button'
import Header from 'components/molecules/Header'
import TemplateForm from 'components/organisms/TemplateForm'
import {
  flushFormAction,
  setFormElementAction,
  saveNewTemplateAction,
} from 'domains/forms/template/create/actions'
import {
  popSnackbarAction,
  popConfirmationDialogAction,
} from 'domains/control/actions'
import { createTemplateFormValuesSelector } from 'domains/forms/template/create/selectors'
import ButtonLink from 'components/atoms/ButtonLink'
import { TEMPLATE_INDEX_URL } from 'domains/templates/urls'
import WithPadding from 'components/templates/WithPadding'
import WithWarningOnModification from 'components/templates/WithWarningOnModification'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  reset_button: { maxWidth: theme.spacing(20) },
}))

const Create = (props) => {
  const {
    formValues,
    setFormElement,
    flushForm,
    saveNewTemplate,
    popConfirmationDialog,
  } = props
  const classes = useStyles()

  const [skipUpdate, setSkipUpdate] = useState(false)

  const confirmFlush = () => {
    popConfirmationDialog(
      'Réinitialisation du formulaire',
      'Êtes-vous sûr de vouloir réinitialiser le formulaire ? Tous les changements seront perdus.',
      () => {
        flushForm()
      },
      () => null,
    )
  }

  const actions = [
    <ButtonLink variant="outlined" to={TEMPLATE_INDEX_URL()}>
      Retour
    </ButtonLink>,
    <Button
      disabled={formValues.updated}
      onClick={() => {
        setSkipUpdate(true)
        saveNewTemplate()
      }}
    >
      Enregistrer
    </Button>,
  ]

  return (
    <WithPadding className={classes.root}>
      <WithWarningOnModification
        skip={skipUpdate}
        updated={formValues.updated}
      />
      <Header
        title="Création d'un template"
        alignContent="right"
        actions={actions}
      />
      <Button
        className={classes.reset_button}
        color="secondary"
        onClick={() => confirmFlush()}
      >
        Réinitialiser
      </Button>
      <TemplateForm formValues={formValues} setFormElement={setFormElement} />
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  formValues: createTemplateFormValuesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  flushForm: () => {
    dispatch(flushFormAction())
  },
  setFormElement: (element, value) => {
    dispatch(setFormElementAction(element, value))
  },
  saveNewTemplate: () => {
    dispatch(saveNewTemplateAction())
  },
  popSnackbar: (message, type) => {
    dispatch(popSnackbarAction(message, type))
  },
  popConfirmationDialog: (title, message, onAgree, onCancel) => {
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Create)
