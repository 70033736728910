import { SET_SELECTED_TEMPLATE, SET_SELECTED_CARDS } from './constants'

const initialState = {
  selected_cards: [],
  selected_template: null,
}

const main = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CARDS:
      return {
        ...state,
        selected_cards: action.payload,
      }
    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selected_template: action.payload,
      }
    default:
      return state
  }
}

export default main
