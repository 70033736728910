import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Button from 'components/atoms/Button'
import Header from 'components/molecules/Header'
import CriteriaForm from 'components/organisms/CriteriaForm'

import {
  flushFormAction,
  setFormElementAction,
} from 'domains/forms/criteria/create/actions'
import {
  popSnackbarAction,
  popConfirmationDialogAction,
} from 'domains/control/actions'
import {
  createCriteriaAction,
  getCriteriaByIdAction,
} from 'domains/criterias/actions'
import { createCriteriaFormValuesSelector } from 'domains/forms/criteria/create/selectors'
import ButtonLink from 'components/atoms/ButtonLink'
import WithPadding from 'components/templates/WithPadding'
import WithWarningOnModification from 'components/templates/WithWarningOnModification'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  reset_button: { maxWidth: theme.spacing(20) },
}))

const Create = (props) => {
  const {
    formValues,
    setFormElement,
    popConfirmationDialog,
    flushForm,
    createCriteria,
  } = props
  const classes = useStyles()

  const [skipUpdate, setSkipUpdate] = useState(false)

  const confirmFlush = () => {
    popConfirmationDialog(
      'Réinitialisation du formulaire',
      'Êtes-vous sûr de vouloir réinitialiser le formulaire ? Tous les changements seront perdus.',
      () => {
        flushForm()
      },
      () => null,
    )
  }

  const actions = [
    <ButtonLink variant="outlined" to="/criterias/">
      Retour
    </ButtonLink>,
    <Button
      disabled={formValues.updated}
      onClick={() => {
        setSkipUpdate(true)
        createCriteria()
      }}
    >
      Enregistrer
    </Button>,
  ]

  return (
    <WithPadding className={classes.root}>
      <WithWarningOnModification
        skip={skipUpdate}
        updated={formValues.updated}
      />
      <Header
        title="Création d'un critère"
        alignContent="right"
        actions={actions}
      />
      <Button
        className={classes.reset_button}
        color="secondary"
        onClick={() => {
          confirmFlush()
        }}
      >
        Réinitialiser
      </Button>
      <CriteriaForm formValues={formValues} setFormElement={setFormElement} />
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  formValues: createCriteriaFormValuesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  flushForm: () => {
    dispatch(flushFormAction())
  },
  setFormElement: (element, value) => {
    dispatch(setFormElementAction(element, value))
  },
  createCriteria: () => {
    dispatch(createCriteriaAction())
  },
  getCriteriaById: (id, callbacks) =>
    dispatch(getCriteriaByIdAction(id, callbacks)),
  popSnackbar: (message, type) => {
    dispatch(popSnackbarAction(message, type))
  },
  popConfirmationDialog: (title, message, onAgree, onCancel) => {
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Create)
