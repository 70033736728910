/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { TextField, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { debounce, isEmpty } from 'lodash'
import Switch from 'components/atoms/Switch'
import Dialog from 'components/templates/Dialog'
import AuditSimpleTable from 'domains/audits/displayers/AuditSimpleTable'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import {
  getAuditsWithSearchAction,
  setQueryAction,
  setInitialStateAction,
} from 'domains/audits/actions'
import { auditSearchQuerySelector } from 'domains/audits/selectors'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  text_field: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
  no_results: {
    marginTop: theme.spacing(2),
  },
}))

const AuditSearcher = (props) => {
  const debouncerRef = useRef()
  const {
    open,
    query,
    setOpen,
    selectedAudits,
    onSelect,
    onSelectAll,
    getAuditsWithSearch,
    setAuditSearchInitialState,
    setAuditSearchQuery,
  } = props
  const {
    audit_mission_ref_ucont,
    content_translations_title_ucont,
    content_translations_display_title_ucont,
  } = query
  const [results, setResults] = useState([])
  const [hasReferentialChecked, setHasReferentialChecked] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    setAuditSearchInitialState()
    debouncerRef.current = debounce((search) => {
      getAuditsWithSearch(search, {
        onSuccess: async (res) => setResults(res.audits),
        onEmptyQuery: () => setResults([]),
      })
    }, 800)
    return () => {
      debouncerRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      setOpen={setOpen}
      title="Trouver un audit"
    >
      <PaperWithPadding>
        <Box className={classes.header}>
          <TextField
            className={classes.text_field}
            label="Référence de la mission"
            value={audit_mission_ref_ucont}
            onChange={(e) => {
              const newQuery = {
                ...query,
                audit_mission_ref_ucont: e.target.value,
              }
              setAuditSearchQuery(newQuery)
              debouncerRef.current(newQuery)
            }}
            autoFocus
          />
          <TextField
            className={classes.text_field}
            label="Titre de l'audit"
            value={
              content_translations_display_title_ucont ||
              content_translations_title_ucont
            }
            onChange={(e) => {
              const newQuery = {
                ...query,
                content_translations_title_ucont: e.target.value,
                content_translations_display_title_ucont: e.target.value,
              }
              setAuditSearchQuery(newQuery)
              debouncerRef.current(newQuery)
            }}
            autoFocus
          />
        </Box>
        <Box className={classes.header}>
          <Switch
            label="Absence de référentiel"
            onChange={() => {
              const newQuery = {
                ...query,
              }

              if (hasReferentialChecked) {
                delete newQuery.accessibility_description_referential_id_null
                setHasReferentialChecked(false)
              } else {
                newQuery.accessibility_description_referential_id_null = true
                setHasReferentialChecked(true)
              }

              setAuditSearchQuery(newQuery)
              debouncerRef.current(newQuery)
            }}
            checked={hasReferentialChecked}
          />
        </Box>
        <AuditSimpleTable
          audits={results}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          selectedAudits={selectedAudits}
          multiSelect
        />
        {isEmpty(results) && (
          <Typography className={classes.no_results} align="center">
            Aucun résultat
          </Typography>
        )}
      </PaperWithPadding>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getAuditsWithSearch: (query, callbacks) =>
    dispatch(getAuditsWithSearchAction(query, callbacks)),
  setAuditSearchInitialState: () => dispatch(setInitialStateAction()),
  setAuditSearchQuery: (query) => dispatch(setQueryAction(query)),
})

const mapStateToProps = (state) => ({
  query: auditSearchQuerySelector(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditSearcher)
