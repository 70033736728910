/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  warningTextRed: {
    color: theme.palette.common.red,
  },
  warningTextYellow: {
    color: theme.palette.common.orange,
  },
}))

const PublishAllAuditDialog = ({
  open,
  handleDialogClose,
  handleDialogConfirm,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Publication des audits acceptés
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1">
            Êtes-vous sûr de vouloir publier ces audits ?
          </Typography>
          <Typography variant="body1" className={classes.warningTextYellow}>
            Les données des audits écraserons les données des pages
            correspondantes, référentiel compris.
          </Typography>
          <Typography variant="body1">
            Nous vous conseillons de prévisualiser les audits avant de les
            publier.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDialogClose}>
          Annuler
        </Button>
        <Button
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleDialogConfirm}
          color="secondary"
          variant="contained"
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PublishAllAuditDialog
