const initialState = {
  form_submitted_once: false,
  ref: '',
  name: '',
  explanation: '',
  question: '',
  published: false,
  archived: false,
  handicaps: [],
  criteria_type: '',
  criteria_choices: [],
  updated: true,
}

const create = (state = initialState, action) => {
  switch (action.type) {
    case 'FLUSH_CRIT_CREATE_FORM':
      return initialState
    case 'SET_CRIT_CREATE_FORM':
      return {
        ...state,
        [action.element]: action.value,
        updated: false,
      }
    default:
      return state
  }
}

export default create
