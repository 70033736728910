/* eslint-disable import/prefer-default-export */
import { setLoadingAction } from 'domains/control/actions'
import * as API from 'api/domains/admin/audits'
import { getCurrentUser } from 'api/domains/users/users'
import { isEmpty, reduce, forEach } from 'lodash'
import {
  SET_INITIAL_STATE,
  SET_QUERY,
  SET_AUDITS_PUBLISHED_DONE_PROGRESS,
} from './types'

export const updateAuditAction = (id, body, callbacks) => (dispatch) => {
  const onBefore = async () => dispatch(setLoadingAction(true))
  const onAfter = async () => dispatch(setLoadingAction(false))
  return API.updateAudit(id, body)({ onBefore, onAfter, ...callbacks })
}

const approveAuditAction = (id, status, callbacks) => (dispatch) =>
  getCurrentUser()({
    onSuccess: async (res) => {
      const userId = res.id
      dispatch(
        updateAuditAction(
          id,
          {
            audit: {
              status,
              approved_at: new Date(),
              approver_id: userId,
              approver_message: '',
            },
          },
          callbacks,
        ),
      )
    },
  })

export const publishAllAuditsAction = (audits, callbacks) => (dispatch) => {
  const auditsCount = audits.length
  let auditsDone = 0

  const onBefore = async () => {
    dispatch(setLoadingAction(true))
  }

  const onAfter = async () => {
    auditsDone += 1
    if (auditsCount === auditsDone) {
      dispatch(setLoadingAction(false))
      await callbacks.onAfter()
    }
  }

  forEach(audits, (audit) => {
    API.publishAudit(audit.id, { perform_backup: true })({
      onBefore,
      onAfter,
    })
  })
}

export const publishAuditAction =
  (id, performBackup, callbacks) => (dispatch) => {
    const onBefore = async () => {
      dispatch(setLoadingAction(true))
      if (callbacks.onBefore) await callbacks.onBefore()
    }
    const onAfter = async () => {
      dispatch(setLoadingAction(false))
      if (callbacks.onAfter) callbacks.onAfter()
    }

    API.publishAudit(id, { perform_backup: performBackup })({
      ...callbacks,
      onBefore,
      onAfter,
    })
  }

export const cancelApprovementAuditAction = (id, callbacks) => (dispatch) =>
  dispatch(
    updateAuditAction(
      id,
      {
        audit: {
          status: 'waiting_for_review',
          approved_at: null,
          approver_id: null,
        },
      },
      callbacks,
    ),
  )

export const acceptAuditAction = (auditId, callbacks) => (dispatch) =>
  dispatch(approveAuditAction(auditId, 'accepted', callbacks))

export const rejectAuditAction = (auditId, data, callbacks) => (dispatch) =>
  getCurrentUser()({
    onSuccess: async (res) => {
      const userId = res.id
      dispatch(
        updateAuditAction(
          auditId,
          {
            audit: {
              status: 'rejected',
              approved_at: new Date(),
              approver_id: userId,
              ...data,
            },
          },
          callbacks,
        ),
      )
    },
  })

export const getAuditsWithSearchAction =
  (query, callbacks = {}) =>
  (dispatch) => {
    const onBefore = async () => {
      dispatch(setLoadingAction(true))
      if (callbacks.onBefore) await callbacks.onBefore()
    }
    const onAfter = async () => {
      dispatch(setLoadingAction(false))
      if (callbacks.onAfter) callbacks.onAfter()
    }

    const resQuery = reduce(
      query,
      (res, value, key) => {
        if (!isEmpty(value) || value === true || value === false) {
          res[`q[${key}]`] = value
        }
        return res
      },
      {},
    )

    if (isEmpty(resQuery)) {
      return callbacks.onEmptyQuery()
    }

    return API.getAuditsWithSearch({ ...resQuery, per_page: 500 })({
      onBefore,
      onAfter,
      ...callbacks,
    })
  }

export const setInitialStateAction = () => ({
  type: SET_INITIAL_STATE,
})

export const setQueryAction = (payload) => ({
  type: SET_QUERY,
  payload,
})

export const setAuditsPublishedDoneProgressAction = (payload) => ({
  type: SET_AUDITS_PUBLISHED_DONE_PROGRESS,
  payload,
})
