import { METHOD_GET, METHOD_PATCH } from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from '.'

const suffix = 'audits'

export const updateAudit = (id, body, query_params = {}) =>
  nsfw(METHOD_PATCH, `${suffix}/${id}`, query_params, body)

export const publishAudit = (id, body = {}, query_params = {}) =>
  nsfw(METHOD_PATCH, `${suffix}/${id}/publish`, query_params, body)

export const getAuditsWithSearch = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/search`, query_params)
