import { push } from 'connected-react-router'
import { popSnackbarAction, setLoadingAction } from 'domains/control/actions'
import { formatErrorsTemplateForm } from 'domains/templates/formatters'
import { createTemplateAction } from 'domains/templates/actions'
import { formatSections } from './formatters'

export const flushFormAction = () => ({
  type: 'FLUSH_TEMPLATE_CREATE_FORM',
})

export const setFormElementAction = (element, value) => ({
  type: 'SET_TEMPLATE_CREATE_FORM',
  element,
  value,
})

const categoryIds = (template) => {
  const categoryIdsResult = []
  if (template.category) {
    categoryIdsResult.push(template.category)
  }
  if (template.level_two_category) {
    categoryIdsResult.push(template.level_two_category)
  }
  if (template.level_three_category) {
    categoryIdsResult.push(template.level_three_category)
  }
  return categoryIdsResult
}

const tagIds = (template) =>
  template.tags ? template.tags.split(',').map((id) => parseInt(id, 10)) : []

export const saveNewTemplateAction = () => (dispatch, getState) => {
  const template = JSON.parse(JSON.stringify(getState().forms.template.create))

  const {
    form_submitted_once: formSubmittedOnce,
    category,
    tags,
    brands,
    ...attributesToSend
  } = template

  const body = {
    ...attributesToSend,
    accessibility_sections_attributes: formatSections(
      template.accessibility_sections,
    ),
    category_ids: categoryIds(template),
    tag_ids: tagIds(template),
    brand_ids: template.brands ? [template.brands] : [],
    good_practice_id: parseInt(template.good_practice, 10),
  }

  dispatch(
    createTemplateAction(body, {
      onBefore: async () => {
        dispatch(setFormElementAction('form_submitted_once', true))
        dispatch(setLoadingAction(true))
      },
      onSuccess: async (res) => {
        dispatch(push(`/templates/${res.id}/edit`))
        dispatch(flushFormAction())
        dispatch(popSnackbarAction('Le template a bien été créé', 'success'))
      },
      onFailure: async (res) =>
        dispatch(
          popSnackbarAction(
            formatErrorsTemplateForm(
              'Erreur(s) lors de la création du template',
              res.errors,
            ),
            'error',
          ),
        ),
      onAfter: async () => dispatch(setLoadingAction(false)),
    }),
  )
}
