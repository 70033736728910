import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow } from '@material-ui/core'

const useStyles = makeStyles({
  root: ({ onRowClick }) => ({
    cursor: onRowClick ? 'pointer' : 'unset',
  }),
})

const Row = ({ item, cellFormat, onRowClick = null }) => {
  const classes = useStyles({ onRowClick })

  return (
    <TableRow
      classes={{ root: classes.root }}
      onClick={onRowClick ? () => onRowClick(item) : null}
      hover
      tabIndex={-1}
    >
      {cellFormat(item).map((content, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableCell key={i}>{content}</TableCell>
      ))}
    </TableRow>
  )
}

export default Row
