import React from 'react'
import { TablePagination } from '@material-ui/core'

const Pagination = (props) => {
  const { pagination, perPage, getItems, setItemsPerPage } = props

  const labelDisplayedRows = () => {
    const curStart = perPage * pagination.current - (perPage - 1)
    let curEnd = perPage * pagination.current

    if (curEnd > pagination.total_count) {
      curEnd = pagination.total_count
    }

    return `${curStart}-${curEnd} sur ${pagination.total_count}`
  }
  const handleChangePage = (event, newPage) => {
    getItems({ page: newPage + 1 })
  }

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(event.target.value)
    getItems()
  }

  return (
    <TablePagination
      component="div"
      count={pagination.total_count}
      rowsPerPage={perPage}
      labelRowsPerPage="Objets par page : "
      labelDisplayedRows={labelDisplayedRows}
      page={pagination.current - 1}
      backIconButtonProps={{
        'aria-label': 'Page précédente',
      }}
      nextIconButtonProps={{
        'aria-label': 'Page suivante',
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default Pagination
