/* eslint-disable import/prefer-default-export */
import { getCardsWithSearchRansack } from 'api/domains/admin/cards'
import { setLoadingAction } from 'domains/control/actions'
import { isEmpty, reduce } from 'lodash'
import { SET_INITIAL_STATE, SET_QUERY } from './types'

export const getCardsWithSearchAction =
  (query, callbacks = {}) =>
  (dispatch) => {
    const resQuery = reduce(
      query,
      (res, value, key) => {
        if (!isEmpty(value)) {
          res[`q[${key}]`] = value
        }
        return res
      },
      {},
    )

    if (isEmpty(resQuery)) {
      return callbacks.onEmptyQuery()
    }

    const finalCallback = {
      ...callbacks,
      onBefore: async () => dispatch(setLoadingAction(true)),
      onAfter: async () => dispatch(setLoadingAction(false)),
    }

    return getCardsWithSearchRansack(resQuery)(finalCallback)
  }

export const setInitialStateAction = () => ({
  type: SET_INITIAL_STATE,
})

export const setQueryAction = (payload) => ({
  type: SET_QUERY,
  payload,
})
