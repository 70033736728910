import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Box, Tabs, Tab, makeStyles } from '@material-ui/core'
import { logOut } from 'api/utils/authentication'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: 60,
    maxHeight: 60,
  },
  rightAlign: {
    marginLeft: 'auto',
  },
}))

const getInitialTabValue = () => {
  const { pathname } = window.location
  if (pathname.startsWith('/criterias')) {
    return 1
  }
  if (pathname.startsWith('/templates')) {
    return 2
  }
  if (pathname.startsWith('/audit_missions')) {
    return 3
  }
  if (pathname.startsWith('/link-referential-to-cards')) {
    return 4
  }
  if (pathname.startsWith('/auditlink')) {
    return 5
  }
  return 1
}

const Topbar = () => {
  const [tabValue, setTabValue] = useState(getInitialTabValue())
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <AppBar position="static" className={classes.tabBar}>
        <Tabs
          value={tabValue}
          onChange={(e, value) => setTabValue(value)}
          aria-label="Menu principal"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value={1} label="Critères" component={Link} to="/criterias" />
          <Tab value={2} label="Templates" component={Link} to="/templates" />
          <Tab
            value={3}
            label="Missions d'audit"
            component={Link}
            to="/audit_missions"
          />
          <Tab
            value={4}
            label="Liaison de référentiel à des lieux"
            component={Link}
            to="/link-referential-to-cards"
          />
          <Tab
            value={5}
            label="Liaison de référentiel à des audits"
            component={Link}
            to="/link-referential-to-audits"
          />
          <Tab
            value={6}
            className={classes.rightAlign}
            label="Se déconnecter"
            onClick={async () => {
              await logOut()
              window.location.reload()
            }}
          />
        </Tabs>
      </AppBar>
    </Box>
  )
}

export default Topbar
