import { setLoadingAction, popSnackbarAction } from 'domains/control/actions'
import { push } from 'connected-react-router'
import { logOut } from 'api/utils/authentication'
import { getCurrentUser } from 'api/domains/users/users'
import { signInWithEmailAndPassword } from 'api/domains/users/authentication'
import { CRITERIA_INDEX_URL } from 'domains/criterias/urls'
import { find } from 'lodash'
import { SET_FORM_VALUES } from './types'
import { signInFormValuesSelector } from './selectors'

export const setFormValuesAction = (formValues) => ({
  type: SET_FORM_VALUES,
  payload: formValues,
})

const checkRolesAction = async (res, dispatch) => {
  if (!find(res.roles, { name: 'admin' })) {
    logOut()
    dispatch(
      popSnackbarAction(
        "Erreur d'authentification (Vous n'êtes pas autorisé à accéder à ce service)",
        'error',
      ),
    )
  } else {
    dispatch(popSnackbarAction('Vous êtes bien connecté', 'success'))
    dispatch(push(CRITERIA_INDEX_URL()))
  }
}

export const fetchCurrentUserRolesAction = (dispatch) =>
  getCurrentUser()({
    onSuccess: (res) => checkRolesAction(res, dispatch),
    onFailure: async () => {
      logOut()
      dispatch(
        popSnackbarAction(
          "Erreur d'authentification (Impossible de récupérer votre profil)",
          'error',
        ),
      )
    },
  })

export const submitFormAction = () => (dispatch, getState) => {
  const formValues = signInFormValuesSelector(getState())
  const { email, password } = formValues

  signInWithEmailAndPassword(
    email,
    password,
  )({
    onBefore: async () => dispatch(setLoadingAction(true)),
    onSuccess: async () => fetchCurrentUserRolesAction(dispatch),
    onFailure: async () =>
      dispatch(
        popSnackbarAction(
          "Erreur d'authentification (mauvais utilisateur ou mot de passe)",
          'error',
        ),
      ),
    onAfter: async () => dispatch(setLoadingAction(false)),
  })
}
