import React from 'react'
import theme from 'env/theme'
import { makeStyles } from '@material-ui/styles'
import AuditifPartial from 'assets/icons/AuditifPartial'
import Auditif from 'assets/icons/Auditif'
import FemmeEnceinte from 'assets/icons/FemmeEnceinte'
import HandicapPsychique from 'assets/icons/HandicapPsychique'
import Moteur from 'assets/icons/Moteur'
import Senior from 'assets/icons/Senior'
import Visuel from 'assets/icons/Visuel'
import VisuelPartial from 'assets/icons/VisuelPartial'

const color = theme.palette.primary.main

const getHandicapIconsList = (handicaps) => {
  const res = []
  handicaps.forEach((handicap) => {
    switch (handicap) {
      case 1:
        res.push(<Moteur key={1} width={40} height={40} fill={color} />)
        break
      case 2:
        res.push(
          <HandicapPsychique key={2} width={40} height={40} fill={color} />,
        )
        break
      case 3:
        res.push(<Auditif key={3} width={40} height={40} fill={color} />)
        break
      case 4:
        res.push(<Visuel key={4} width={40} height={40} fill={color} />)
        break
      case 5:
        res.push(<VisuelPartial key={5} width={40} height={40} fill={color} />)
        break
      case 6:
        res.push(<AuditifPartial key={6} width={40} height={40} fill={color} />)
        break
      case 7:
        res.push(<FemmeEnceinte key={7} width={30} height={40} fill={color} />)
        break
      case 8:
        res.push(<Senior key={8} width={40} height={40} fill={color} />)
        break
      default:
        break
    }
  })

  return res
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}))

const HandicapIconsList = (props) => {
  const { handicaps } = props
  const classes = useStyles()

  const handicapIcons = getHandicapIconsList(handicaps)
  return (
    <div className={classes.root}>
      {handicapIcons.length > 0 ? handicapIcons : "Pas d'handicap affilié"}
    </div>
  )
}

export default HandicapIconsList
