import React from 'react'
import { Switch, FormControlLabel, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  checked: {},
  track: {},
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  formControlLabel: { display: 'flex', flex: 1 },

  label: {
    marginRight: theme.spacing(4),
  },
}))

const CustomizedSwitches = ({
  label,
  checked,
  onChange,
  value,
  attributeLabel,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Switch
            color="primary"
            checked={checked}
            onChange={onChange}
            value={value}
            classes={{
              root: classes.root,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
          />
        }
        label={attributeLabel}
      />
    </div>
  )
}

export default CustomizedSwitches
