import React from 'react'
import { IconButton, SnackbarContent, Snackbar } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    backgroundColor: theme.palette.secondary.main,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: theme.palette.secondary.main,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
  },
}))

const CustomizedSnackbars = (props) => {
  const { snackbar, setSnackbar } = props
  const classes = useStyles()

  if (!snackbar) return null
  const Icon = variantIcon[snackbar.type]

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbar('open', false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[snackbar.type || 'info']}
        aria-describedby="client-snackbar"
        message={
          <span className={classes.message}>
            {Icon && (
              <Icon className={`${classes.icon} ${classes.iconVariant}`} />
            )}
            {snackbar.message || ''}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export default CustomizedSnackbars
