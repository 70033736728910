/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { find, isEmpty } from 'lodash'

export const formatToTemplate = (template) => {
  let category = null
  const depthZeroCategories = find(template.categories, { depth: 0 })
  if (depthZeroCategories) {
    category = depthZeroCategories.id.toString()
  }

  let levelTwoCategory = null
  const depthOneCategories = find(template.categories, { depth: 1 })
  if (depthOneCategories) {
    levelTwoCategory = depthOneCategories.id.toString()
  }

  let levelThreeCategory = null
  const depthTowCategories = find(template.categories, { depth: 2 })
  if (depthTowCategories) {
    levelThreeCategory = depthTowCategories.id.toString()
  }

  let brands = null
  if (template.brands && template.brands.length) {
    brands = template.brands[0].id.toString()
  }

  let tags = ''
  if (template.tags && template.tags.length) {
    template.tags.forEach((tag, index) => {
      tags += tag.id + (index < template.tags.length - 1 ? ',' : '')
    })
  }

  let good_practice = null
  if (!isEmpty(template.good_practice)) {
    good_practice = template.good_practice.id.toString()
  }
  const { categories, ...rest } = template

  return {
    ...rest,
    category,
    level_two_category: levelTwoCategory,
    level_three_category: levelThreeCategory,
    brands,
    tags,
    good_practice,
  }
}
