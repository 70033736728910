/* eslint-disable no-alert */
import React from 'react'
import { connect } from 'react-redux'
import { Typography, makeStyles } from '@material-ui/core'
import { v4 as uid } from 'uuid'
import { isEmpty, find, filter, sortBy } from 'lodash'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import { CHOICES_TYPES } from 'domains/criterias/enums'
import { popConfirmationDialogAction } from 'domains/control/actions'
import Choice from './Choice'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  answerTypeSelect: {
    flex: 2,
    paddingRight: theme.spacing(2),
  },
  addAnswerButton: {
    width: '100%',
  },
  row: {
    display: 'flex',
  },
  choices: {
    marginBottom: theme.spacing(2),
  },
}))

const ChoicesForm = (props) => {
  const { formValues, setFormElement, popConfirmationDialog } = props
  const classes = useStyles()

  const onDeleteLinkedCriteria = (criteriaToDelete, choiceToUpdate) => {
    const newChoices = JSON.parse(JSON.stringify(formValues.criteria_choices))

    newChoices.forEach((choice) => {
      const newChoice = choice
      if (newChoice.value === choiceToUpdate) {
        newChoice.linked_criterias = filter(
          newChoice.linked_criterias,
          (criteria) => criteria.id !== criteriaToDelete,
        )
      }
    })

    setFormElement('criteria_choices', newChoices)
  }

  const moveChoice = (index, direction) => {
    const newCriteriaChoices = formValues.criteria_choices.slice(0)
    const firstSwapped = find(newCriteriaChoices, {
      choice_index: index,
    })
    const secondSwapped =
      direction === 'up'
        ? find(newCriteriaChoices, {
            choice_index: index - 1,
          })
        : find(newCriteriaChoices, {
            choice_index: index + 1,
          })

    if (secondSwapped === undefined) {
      return null
    }

    const temp = firstSwapped.choice_index
    firstSwapped.choice_index = secondSwapped.choice_index
    secondSwapped.choice_index = temp

    return setFormElement('criteria_choices', newCriteriaChoices)
  }

  const reindexChoices = (choices) =>
    choices.map((choice, i) => ({ ...choice, choice_index: i }))

  const changeDescription = (choiceToUpdate, description) => {
    const newCriteriaChoices = formValues.criteria_choices.slice(0)
    newCriteriaChoices.forEach((choice) => {
      const newChoice = choice
      if (newChoice.value === choiceToUpdate.value) {
        newChoice.description = description
      }
    })

    setFormElement('criteria_choices', newCriteriaChoices)
  }

  const changeLabel = (choiceToUpdate, label) => {
    const newCriteriaChoices = formValues.criteria_choices.slice(0)
    newCriteriaChoices.forEach((choice) => {
      const newChoice = choice
      if (newChoice.value === choiceToUpdate.value) {
        newChoice.label = label
      }
    })

    setFormElement('criteria_choices', newCriteriaChoices)
  }

  const deleteChoice = (choiceToDelete) => {
    let newCriteriaChoices = formValues.criteria_choices.slice(0)
    newCriteriaChoices = filter(
      newCriteriaChoices,
      (choice) => choice.value !== choiceToDelete.value,
    )
    setFormElement('criteria_choices', reindexChoices(newCriteriaChoices))
  }

  const pushNewChoice = () => {
    const newCriteriaChoices = formValues.criteria_choices.slice(0)
    newCriteriaChoices.push({
      value: uid(),
      label: '',
      description: '',
      choice_index: newCriteriaChoices.length,
      linked_criterias: [],
    })
    setFormElement('criteria_choices', newCriteriaChoices)
  }

  const handleTypeChange = (e) => {
    setFormElement('criteria_type', e.target.value)
    if (e.target.value === 'text') {
      setFormElement('criteria_choices', [])
    }
  }

  const confirmDeleteAnswer = (choice) => {
    popConfirmationDialog(
      'Modification de critère',
      'Êtes-vous sûr de vouloir supprimer cette réponse ?',
      () => {
        deleteChoice(choice)
      },
      () => {},
    )
  }

  const sortedChoices = sortBy(
    formValues.criteria_choices,
    (choice) => choice.choice_index,
  )

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5">
        Réponses
      </Typography>
      <div className={classes.row}>
        <Select
          className={classes.answerTypeSelect}
          options={CHOICES_TYPES}
          value={formValues.criteria_type || ''}
          onChange={handleTypeChange}
          label="Type de réponse attendue"
        />
      </div>

      <div className={classes.choices}>
        {sortedChoices.map((choice, index) => {
          if (formValues.criteria_type === 'text') {
            return null
          }
          return (
            <Choice
              key={choice.value}
              formValues={formValues}
              index={index + 1}
              choice={choice}
              isFirst={index === 0}
              isLast={index + 1 === sortedChoices.length}
              onDelete={() => confirmDeleteAnswer(choice)}
              moveChoice={moveChoice}
              onChangeLabel={(e) => changeLabel(choice, e.target.value)}
              onChangeDescription={(e) =>
                changeDescription(choice, e.target.value)
              }
              onDeleteLinkedCriteria={onDeleteLinkedCriteria}
              setFormElement={setFormElement}
            />
          )
        })}
      </div>
      {!isEmpty(formValues.criteria_type) &&
        formValues.criteria_type !== 'text' && (
          <Button
            className={classes.addAnswerButton}
            iconLeft="add"
            onClick={() => pushNewChoice()}
          >
            Ajouter une réponse
          </Button>
        )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  popConfirmationDialog: (title, message, onAgree, onCancel) =>
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel)),
})

export default connect(null, mapDispatchToProps)(ChoicesForm)
