/* eslint-disable no-alert */
import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import Controls from 'components/molecules/Controls'
import Delete from '@material-ui/icons/Delete'
import { popConfirmationDialogAction } from 'domains/control/actions'
import { loadingSelector } from 'domains/control/selectors'

const useStyles = makeStyles((theme) => ({
  ref: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
}))

const Criteria = (props) => {
  const {
    section,
    criteriaWrapper,
    isFirst,
    isLast,
    loading,
    moveCriteriaInSection,
    onDeleteCriteria,
    popConfirmationDialog,
  } = props
  const classes = useStyles()

  const displayArrow =
    get(section, 'accessibility_section_criteria_maps', []).length > 1

  const confirmDeleteCriteria = () => {
    popConfirmationDialog(
      'Modification du référentiel',
      'Êtes-vous sûr de vouloir supprimer ce critère de cette section ?',
      () => onDeleteCriteria(criteriaWrapper.criteria.id, section.id),
      () => {},
    )
  }

  return (
    <Grid container spacing={2}>
      {displayArrow && (
        <Grid item xs={2} sm={2}>
          <Controls
            disabled={loading}
            disabledUp={isFirst}
            disabledDown={isLast}
            variant="outlined"
            actionUp={() => {
              moveCriteriaInSection(
                section.accessibility_section_index,
                criteriaWrapper.criteria_index,
                'up',
              )
            }}
            actionDown={() => {
              moveCriteriaInSection(
                section.accessibility_section_index,
                criteriaWrapper.criteria_index,
                'down',
              )
            }}
          />
        </Grid>
      )}
      <Grid item xs={8} sm={8}>
        <Typography className={classes.ref}>
          {criteriaWrapper.criteria.ref}
        </Typography>
        <Typography>{criteriaWrapper.criteria.question}</Typography>
      </Grid>
      <Grid item xs={2} sm={2}>
        <IconButton color="secondary" onClick={confirmDeleteCriteria}>
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  popConfirmationDialog: (title, message, onAgree, onCancel) =>
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel)),
})

const criteriaPropsAreEqual = (prev, next) => {
  return (
    prev.loading === next.loading &&
    prev.criteriaWrapper.id === next.criteriaWrapper.id &&
    prev.isFirst === next.isFirst &&
    prev.isLast === next.isLast
  )
}
const MemoizedCriteria = React.memo(Criteria, criteriaPropsAreEqual)

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedCriteria)
