import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {},
})

const Text = (props) => {
  const { className } = props
  const classes = useStyles()
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TextField className={`${classes.root} ${className}`} {...props} />
}

export default Text
