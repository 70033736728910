import {
  METHOD_GET,
  METHOD_PATCH,
  METHOD_POST,
  METHOD_DELETE,
} from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from '.'

const suffix = 'criterias'

export const getCriterias = (query_params = {}) =>
  nsfw(METHOD_GET, suffix, query_params)

export const getCriteriaById = (id, query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/${id}`, query_params)

export const searchCriterias = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/search`, query_params)

export const createCriteria = (body, query_params = {}) =>
  nsfw(METHOD_POST, suffix, query_params, body)

export const duplicateCriteria = (id, query_params = {}) =>
  nsfw(METHOD_POST, `${suffix}/${id}/duplicate`, query_params)

export const updateCriteria = (id, body, query_params = {}) =>
  nsfw(METHOD_PATCH, `${suffix}/${id}`, query_params, body)

export const deleteCriteria = (id, query_params = {}) =>
  nsfw(METHOD_DELETE, `${suffix}/${id}`, query_params)
