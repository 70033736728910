import React from 'react'
import { FormGroup, Checkbox, FormLabel } from '@material-ui/core'
import AuditifPartial from 'assets/icons/AuditifPartial'
import Auditif from 'assets/icons/Auditif'
import FemmeEnceinte from 'assets/icons/FemmeEnceinte'
import HandicapPsychique from 'assets/icons/HandicapPsychique'
import Moteur from 'assets/icons/Moteur'
import Senior from 'assets/icons/Senior'
import Visuel from 'assets/icons/Visuel'
import VisuelPartial from 'assets/icons/VisuelPartial'
import { handicapsEnum } from 'domains/cards/enums'
import { find, without } from 'lodash'
import theme from 'env/theme'

const containsSlug = (handicaps, slug) =>
  handicaps.length === 0
    ? false
    : handicaps.includes(find(handicapsEnum, { slug }).id)

const getIcon = (slug, checked) => {
  const defaultWidth = 50
  const defaultHeight = 50
  const defaultFill = checked
    ? theme.palette.primary.main
    : theme.palette.grey[400]

  switch (slug) {
    case 'moteur':
      return (
        <Moteur
          width={defaultWidth}
          height={defaultHeight}
          fill={defaultFill}
        />
      )
    case 'psychique':
      return (
        <HandicapPsychique
          width={defaultWidth}
          height={defaultHeight}
          fill={defaultFill}
        />
      )
    case 'mal-entendant':
      return (
        <Auditif
          width={defaultWidth}
          height={defaultHeight}
          fill={defaultFill}
        />
      )
    case 'mal-voyant':
      return (
        <Visuel
          width={defaultWidth}
          height={defaultHeight}
          fill={defaultFill}
        />
      )
    case 'mal-voyant-partiel':
      return (
        <VisuelPartial
          width={defaultWidth}
          height={defaultHeight}
          fill={defaultFill}
        />
      )
    case 'mal-entendant-partiel':
      return (
        <AuditifPartial
          width={defaultWidth}
          height={defaultHeight}
          fill={defaultFill}
        />
      )
    case 'femme-enceinte':
      return <FemmeEnceinte width={35} height={35} fill={defaultFill} />
    case 'senior':
      return (
        <Senior
          width={defaultWidth}
          height={defaultHeight}
          fill={defaultFill}
        />
      )
    default:
      return null
  }
}

const generateCheckboxes = (handicaps, handleChange) =>
  handicapsEnum.map((h) => (
    <Checkbox
      key={h.id}
      color="primary"
      checked={containsSlug(handicaps, h.slug)}
      onChange={(e) => {
        handleChange(e.target.checked, h.slug)
      }}
      icon={getIcon(h.slug, false)}
      checkedIcon={getIcon(h.slug, true)}
      value={h.slug}
    />
  ))

const HandicapSelect = (props) => {
  const { handicaps, setFormElement } = props
  const handleChange = (checked, attr) => {
    let handicapsCopy = handicaps.slice(0)
    if (checked) {
      handicapsCopy.push(find(handicapsEnum, { slug: attr }).id)
    } else {
      const handicapIp = find(handicapsEnum, { slug: attr }).id
      handicapsCopy = without(handicapsCopy, handicapIp)
    }
    setFormElement('handicaps', handicapsCopy)
  }

  return (
    <div>
      <FormLabel component="legend">Handicaps relatifs</FormLabel>
      <FormGroup row>{generateCheckboxes(handicaps, handleChange)}</FormGroup>
    </div>
  )
}

export default HandicapSelect
