export const brandsToOptions = (brands) =>
  brands.map((brand) => ({
    label: brand.name,
    value: brand.id,
  }))

export const formatBrands = (brands) => {
  if (brands.length === 0) {
    return ''
  }
  return brands[0].name
}
