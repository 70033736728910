/* eslint-disable no-console */
export const logSuccessfulResponse = (
  endpoint,
  { method, headers, body },
  res,
) => {
  if (window.env.NODE_ENV !== 'production') {
    console.groupCollapsed(
      `%c 🤟 ${method} ${endpoint}`,
      'color: green; font-weight: bold;',
    )
    console.table(headers)
    console.log('BODY : ', body || 'no-content')
    console.log('Reponse : ', res)
    console.groupEnd()
  }
}

export const logFailureResponse = (
  endpoint,
  { method, headers, body },
  res,
) => {
  if (window.env.NODE_ENV !== 'production') {
    console.groupCollapsed(
      `%c 👎 ${method} ${endpoint}`,
      'color: red; font-weight: bold;',
    )
    console.table(headers)
    console.log('Body : ', body || 'no-content')
    console.log('Response : ', res)
    console.groupEnd()
  }
}
