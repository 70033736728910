import { SET_INITIAL_STATE, SET_QUERY } from './types'

const initialState = {
  query: {
    search_text: '',
    subscription_user_email_ucont: '',
    categories_translations_name_ucont: '',
    localisation_translations_address_ucont: '',
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState
    case SET_QUERY:
      return {
        ...state,
        query: action.payload || {},
      }
    default:
      return state
  }
}

export default reducer
