/* eslint-disable no-unneeded-ternary */
import moment from 'moment'
import { get } from 'lodash'
import jwt from 'jwt-simple'
import { METHOD_POST } from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from './main'

const suffix = 'users'

export const getProviderAuthToken = (provider, body, query_params = {}) =>
  nsfw(
    METHOD_POST,
    `${suffix}/${provider}/authentication_token`,
    query_params,
    body,
  )

export const getAuthToken = (body, query_params = {}) =>
  nsfw(METHOD_POST, `${suffix}/authentication_token`, query_params, body)

export const getJwtAuthToken = (body, query_params = {}) =>
  nsfw(METHOD_POST, `${suffix}/authentication_token_jwt`, query_params, body)

const TOKEN_KEY = 'authToken'
const JWT_SECRET = get(window, 'env.REACT_APP_JWT_SECRET', '123456789')

const encodeThenSetAuthToken = (token) => {
  localStorage.setItem(
    TOKEN_KEY,
    jwt.encode(
      {
        value: token,
        expires_at: moment().add(3, 'day').endOf('day'),
      },
      JWT_SECRET,
    ),
  )
}

export const signInWithEmailAndPassword =
  (email, password) =>
  ({ onBefore, onAfter, onSuccess, onFailure }) =>
    getAuthToken({
      user: {
        email,
        password,
      },
    })({
      onBefore: onBefore ? onBefore : () => Promise.resolve(),
      onAfter: onAfter ? onAfter : () => Promise.resolve(),
      onSuccess: async (res) => {
        encodeThenSetAuthToken(res.authentication_token)
        return onSuccess()
      },
      onFailure: onFailure ? onFailure : () => Promise.resolve(),
    })
