import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import HandicapSelect from 'components/atoms/HandicapSelect'
import MultilineText from 'components/atoms/MultilineText'
import Switch from 'components/atoms/Switch'
import Text from 'components/atoms/Text'
import PaperWithPadding from 'components/templates/PaperWithPadding'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  switches: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullWidth: { width: '100%' },
}))

const AttributesForm = (props) => {
  const { formValues, setFormElement } = props
  const classes = useStyles()

  return (
    <PaperWithPadding padding={2} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Text
            className={classes.fullWidth}
            error={formValues.form_submitted_once && formValues.ref === ''}
            label="Référence"
            value={formValues.ref || ''}
            onChange={(e) => {
              setFormElement('ref', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Text
            className={classes.fullWidth}
            error={formValues.form_submitted_once && formValues.name === ''}
            label="Intitulé du critère"
            value={formValues.name || ''}
            onChange={(e) => {
              setFormElement('name', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <MultilineText
            className={classes.fullWidth}
            error={
              formValues.form_submitted_once && formValues.explanation === ''
            }
            label="Description du critère"
            value={formValues.explanation || ''}
            maxRows={4}
            onChange={(e) => {
              setFormElement('explanation', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Text
            className={classes.fullWidth}
            error={formValues.form_submitted_once && formValues.question === ''}
            label="Question posée"
            value={formValues.question || ''}
            onChange={(e) => {
              setFormElement('question', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HandicapSelect
            setFormElement={setFormElement}
            handicaps={formValues.handicaps}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.switches}>
            <Switch
              label="Statut"
              attributeLabel="Publié"
              onChange={(e) => {
                setFormElement('published', e.target.checked)
              }}
              checked={formValues.published}
            />
            <Switch
              label="Archivé"
              attributeLabel="Archivé"
              onChange={(e) => {
                setFormElement('archived', e.target.checked)
              }}
              checked={formValues.archived}
            />
          </div>
        </Grid>
      </Grid>
    </PaperWithPadding>
  )
}

export default AttributesForm
