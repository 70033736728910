import {
  getCriterias,
  searchCriterias,
  duplicateCriteria,
  createCriteria,
  deleteCriteria,
  getCriteriaById,
  updateCriteria,
} from 'api/domains/admin/criterias'

import { isEmpty } from 'lodash'
import { push } from 'connected-react-router'
import {
  popConfirmationDialogAction,
  setLoadingAction,
  popSnackbarAction,
} from 'domains/control/actions'
import {
  flushFormAction,
  setFormElementAction,
} from 'domains/forms/criteria/create/actions'
import { CRITERIA_EDIT_URL } from './urls'
import { formatErrorsCriteriaForm } from './formatters'
import {
  SET_CRITERIAS,
  SET_SELECTED_CRITERIA,
  SET_PER_PAGE,
  SET_SEARCH,
} from './types'
import { criteriaPerPageSelector, criteriaSearchSelector } from './selectors'

const FIRST_PAGE = 1

const setCriteriasAction = (payload) => ({
  type: SET_CRITERIAS,
  payload,
})

export const setSelectedCriteriaAction = (payload) => ({
  type: SET_SELECTED_CRITERIA,
  payload,
})

export const setCriteriasPerPageAction = (payload) => ({
  type: SET_PER_PAGE,
  payload,
})

export const setCriteriaSearchAction = (payload) => ({
  type: SET_SEARCH,
  payload,
})

export const getCriteriasAction =
  (urlParams = {}, callbacks = {}) =>
  (dispatch, getState) => {
    const { page = FIRST_PAGE, per_page: perPage } = urlParams

    const onBefore = async () => {
      dispatch(setLoadingAction(true))
      if (callbacks.onBefore) callbacks.onBefore()
    }
    const onSuccess = async (res) => {
      dispatch(setCriteriasAction(res))
      if (callbacks.onSuccess) callbacks.onSuccess()
    }
    const onAfter = async () => {
      dispatch(setLoadingAction(false))
      if (callbacks.onAfter) callbacks.onAfter()
    }

    const params = {
      page: page || FIRST_PAGE,
      per_page: perPage || criteriaPerPageSelector(getState()),
    }

    if (!isEmpty(criteriaSearchSelector(getState()))) {
      params['q[translations_name_or_translations_explanation_or_ref_cont]'] =
        criteriaSearchSelector(getState())
      return searchCriterias(params)({
        onBefore,
        onSuccess,
        onAfter,
        ...callbacks,
      })
    }

    return getCriterias(params)({
      onBefore,
      onSuccess,
      onAfter,
      ...callbacks,
    })
  }

export const getCriteriasWithSearchAction =
  (query, callbacks) => (dispatch) => {
    const finalCallbacks = {
      ...callbacks,
      onBefore: async () => dispatch(setLoadingAction(true)),
      onAfter: async () => dispatch(setLoadingAction(false)),
    }
    return searchCriterias({
      'q[translations_name_or_translations_explanation_or_ref_cont]': query,
      'q[archived_eq]': false,
    })(finalCallbacks)
  }

export const getCriteriaByIdAction =
  (id, callbacks = {}) =>
  () =>
    getCriteriaById(id)(callbacks)

export const duplicateCriteriaAction = (id) => (dispatch) => {
  dispatch(
    popConfirmationDialogAction(
      "Duplication d'un critère",
      "Êtes-vous sûr de vouloir dupliquer ce critère ? Si vous confirmez, Vous serez directement redirigé vers la page d'édition de celui-ci",
      () => {
        const onSuccess = async (res) =>
          dispatch(push(CRITERIA_EDIT_URL(res.id)))
        const onBefore = async () => dispatch(setLoadingAction(true))
        const onAfter = async () => dispatch(setLoadingAction(false))
        return duplicateCriteria(id)({ onBefore, onSuccess, onAfter })
      },
      () => null,
    ),
  )
}

export const createCriteriaAction = () => (dispatch, getState) => {
  dispatch(setFormElementAction('form_submitted_once', true))
  const criteria = JSON.parse(JSON.stringify(getState().forms.criteria.create))
  criteria.criteria_choices_attributes = {}
  criteria.criteria_choices.forEach((choice, index) => {
    criteria.criteria_choices_attributes[`${index}`] = {
      ...choice,
      linked_criteria_ids: choice.linked_criterias.map(
        (linkedCriteria) => linkedCriteria.id,
      ),
    }
  })

  return createCriteria({ criteria })({
    onBefore: async () => dispatch(setLoadingAction(true)),
    onSuccess: async () => {
      dispatch(push('/criterias'))
      dispatch(flushFormAction())
      dispatch(popSnackbarAction('Le critère a bien été créé', 'success'))
    },
    onFailure: async (res) =>
      dispatch(
        popSnackbarAction(
          formatErrorsCriteriaForm(
            'Erreur(s) lors de la création du critère',
            res.errors,
          ),
          'error',
        ),
      ),
    onAfter: async () => dispatch(setLoadingAction(false)),
  })
}

export const updateCriteriaAction = (id, body, callbacks) => () =>
  updateCriteria(id, body)(callbacks)

export const deleteCriteriaAction = (id) => (dispatch) =>
  dispatch(
    popConfirmationDialogAction(
      "Suppression d'un critère",
      'Êtes-vous sûr de vouloir supprimer ce critère ? \n Cette opération est irréversible',
      () =>
        deleteCriteria(id)({
          onBefore: async () => dispatch(setLoadingAction(true)),
          onSuccess: async () => {
            dispatch(getCriteriasAction())
          },
          onFailure: async (res) =>
            dispatch(
              popSnackbarAction(
                formatErrorsCriteriaForm(
                  'Erreur(s) lors de la suppression du critère',
                  res.errors,
                ),
                'error',
              ),
            ),
          onAfter: async () => dispatch(setLoadingAction(false)),
        }),
      () => null,
    ),
  )
