/* eslint-disable import/prefer-default-export */
import { findIndex, omit, map } from 'lodash'

export const arrayWasTouched = (firstArray, secondArray) => {
  let touched = false

  if (firstArray.length !== secondArray.length) {
    touched = true
  }

  secondArray.forEach((handicap) => {
    if (!firstArray.includes(handicap)) {
      touched = true
    }
  })

  return touched
}

const getChoiceAddChanges = (res, originalChoices, updatedChoices) => {
  updatedChoices.forEach((updatedChoice) => {
    const found =
      findIndex(
        originalChoices,
        (originalChoice) => updatedChoice.id === originalChoice.id,
      ) !== -1
    if (!found) {
      res[`${Object.keys(res).length}`] = {
        ...updatedChoice,
      }
    }
  })
}

const getChoiceUpdateChanges = (res, originalChoices, updatedChoices) => {
  updatedChoices.forEach((updatedChoice) => {
    const foundIndex = findIndex(
      originalChoices,
      (originalChoice) => updatedChoice.id === originalChoice.id,
    )
    if (foundIndex !== -1) {
      let touched = false
      const updatedIndex = Object.keys(res).length
      res[updatedIndex] = { id: originalChoices[foundIndex].id }

      map(omit(updatedChoice, ['linked_criteria']), (attr, key) => {
        if (attr !== originalChoices[foundIndex][key]) {
          res[updatedIndex][key] = attr
          touched = true
        }
      })

      if (
        arrayWasTouched(
          updatedChoice.linked_criterias,
          originalChoices[foundIndex].linked_criterias,
        )
      ) {
        const linkedCriteriasIds = updatedChoice.linked_criterias.map(
          (c) => c.id,
        )

        res[updatedIndex].linked_criteria_ids = linkedCriteriasIds
        touched = true
      }

      if (!touched) {
        delete res[updatedIndex]
      }
    }
  })
}

export const getChoiceDestroyChanges = (
  res,
  originalChoices,
  updatedChoices,
) => {
  originalChoices.forEach((originalChoice) => {
    const found =
      findIndex(
        updatedChoices,
        (updatedChoice) => updatedChoice.id === originalChoice.id,
      ) !== -1
    if (!found) {
      res[`${Object.keys(res).length}`] = {
        id: originalChoice.id,
        _destroy: true,
      }
    }
  })
}

export const parseChoicesChanges = (originalChoices, updatedChoices) => {
  const res = {}

  getChoiceDestroyChanges(res, originalChoices, updatedChoices)
  getChoiceAddChanges(res, originalChoices, updatedChoices)
  getChoiceUpdateChanges(res, originalChoices, updatedChoices)

  return res
}
