import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  color_primary: {
    color: theme.palette.primary.main,
  },
}))

const BooleanIcon = ({ value }) => {
  const classes = useStyles()

  return value ? (
    <Check fontSize="small" className={classes.color_primary} />
  ) : (
    <Close fontSize="small" className={classes.color_primary} />
  )
}

export default BooleanIcon
