import { push } from 'connected-react-router'

import { popSnackbarAction, setLoadingAction } from 'domains/control/actions'
import { formatErrorsCriteriaForm } from 'domains/criterias/formatters'
import { omit, map } from 'lodash'
import {
  getCriteriaByIdAction,
  updateCriteriaAction,
} from 'domains/criterias/actions'
import { CRITERIA_INDEX_URL } from 'domains/criterias/urls'
import { arrayWasTouched, parseChoicesChanges } from './parsers'

export const setFormElementAction = (element, value) => ({
  type: 'SET_CRIT_EDIT_FORM',
  element,
  value,
})

export const setCriteriaToEditAction = (criteria) => ({
  type: 'SET_CRITERIA_TO_EDIT',
  payload: criteria,
})

export const fetchCriteriaToEditAction = (criteriaId) => (dispatch) =>
  dispatch(
    getCriteriaByIdAction(criteriaId, {
      onBefore: async () => dispatch(setLoadingAction(true)),
      onSuccess: async (criteria) =>
        dispatch(setCriteriaToEditAction(criteria)),
      onAfter: async () => dispatch(setLoadingAction(false)),
    }),
  )

export const updateFormCriteriaAction = () => (dispatch, getState) => {
  const original = JSON.parse(
    JSON.stringify(getState().forms.criteria.edit.original_criteria),
  )
  const updated = JSON.parse(
    JSON.stringify(
      omit(getState().forms.criteria.edit, [
        'original_criteria',
        'criteria_search',
      ]),
    ),
  )
  let choiceChanges = null

  const body = {}

  map(omit(updated, ['criteria_choices', 'handicaps']), (attr, key) => {
    if (attr !== original[key]) {
      body[key] = attr
    }
  })

  if (arrayWasTouched(original.handicaps, updated.handicaps)) {
    body.handicaps = updated.handicaps
  }

  choiceChanges = parseChoicesChanges(
    original.criteria_choices,
    updated.criteria_choices,
  )

  if (Object.keys(choiceChanges).length !== 0) {
    body.criteria_choices_attributes = choiceChanges
  }

  dispatch(
    updateCriteriaAction(updated.id, body, {
      onBefore: async () => {
        dispatch(setLoadingAction(true))
        dispatch(setFormElementAction('form_submitted_once', true))
      },
      onSuccess: async () => {
        dispatch(push(CRITERIA_INDEX_URL()))
        dispatch(popSnackbarAction('Le critère a bien été modifié', 'success'))
      },
      onFailure: async (res) =>
        dispatch(
          popSnackbarAction(
            formatErrorsCriteriaForm(
              'Erreur(s) lors de la mise à jour du critère',
              res.errors,
            ),
            'error',
          ),
        ),
      onAfter: async () => dispatch(setLoadingAction(false)),
    }),
  )
}
