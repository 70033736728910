/* eslint-disable no-useless-escape */
import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: ({ wrap }) => ({
    display: 'flex',
    flexDirection: wrap ? 'column' : 'row',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
  }),
  title: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flex: 'auto',
    lineHeight: 1.8,
    whiteSpace: 'normal',
  },
}))

const DetailRow = (props) => {
  const { title, children, wrap } = props
  const classes = useStyles({ wrap })

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="overline" component="span">
        {typeof title === 'string' ? `${title} :` : title}
      </Typography>
      {typeof children === 'string' || isEmpty(children) ? (
        <Typography
          className={classes.content}
          component="span"
          variant="body1"
        >
          {isEmpty(children) ? '<<Non renseigné>>' : children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  )
}

export default DetailRow
