import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from 'components/atoms/Button'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
})

const ButtonLink = (props) => {
  const { to, children, className, buttonClassName, ...rest } = props
  const classes = useStyles()

  return (
    <Link className={`${classes.link} ${className}`} to={to}>
      <Button
        variant="contained"
        color="primary"
        className={buttonClassName}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </Button>
    </Link>
  )
}

export default ButtonLink
