import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {},
})

const MultilineText = ({ label, value, onChange, maxRows, className = '' }) => {
  const classes = useStyles()

  return (
    <TextField
      id="multiline-static"
      label={label}
      value={value}
      onChange={onChange}
      multiline
      maxRows={maxRows || 2}
      className={`${classes.root} ${className}`}
    />
  )
}

export default MultilineText
