import React from 'react'
import { Typography, Box, Divider, makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import DetailRow from 'components/molecules/DetailRow'

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  skeletonKanbanContainer: { display: 'flex', justifyContent: 'space-between' },
  skeletonText: { display: 'flex', flex: 'auto' },
  skeletonKanbanItem: { display: 'flex' },
}))

const SkeletonFull = () => {
  const classes = useStyles()

  return (
    <PaperWithPadding>
      <Typography gutterBottom variant="h5">
        <Skeleton
          variant="rect"
          height={40}
          classes={{ root: classes.skeletonText }}
        />
      </Typography>
      <Divider className={classes.divider} />
      <DetailRow title="Titre">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Token">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Archivée">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Active">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Termine le">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Opérateur">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Status">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Créé le">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <DetailRow title="Mis à jour le">
        <Skeleton variant="text" classes={{ root: classes.skeletonText }} />
      </DetailRow>
      <Divider className={classes.divider} />
      <Box className={classes.skeletonKanbanContainer}>
        <Skeleton
          variant="rect"
          height={200}
          width={300}
          classes={{ root: classes.skeletonKanbanItem }}
        />
        <Skeleton
          variant="rect"
          height={200}
          width={300}
          classes={{ root: classes.skeletonKanbanItem }}
        />
        <Skeleton
          variant="rect"
          height={200}
          width={300}
          classes={{ root: classes.skeletonKanbanItem }}
        />
        <Skeleton
          variant="rect"
          height={200}
          width={300}
          classes={{ root: classes.skeletonKanbanItem }}
        />
      </Box>
    </PaperWithPadding>
  )
}

export default SkeletonFull
