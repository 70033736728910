import _ from 'lodash'
import { setLoadingAction, popSnackbarAction } from 'domains/control/actions'
import { updateAuditMission } from 'api/domains/admin/audit_missions'
import { formatErrorsAuditMissionForm } from 'domains/audit_missions/formatters'
import {
  SET_FORM,
  SET_SELECTED_CARDS,
  SET_SELECTED_CARD_ITEM,
  SET_INITIAL_STATE,
} from './constants'

export const setFormElementAction = (element, value) => ({
  type: SET_FORM,
  element,
  value,
})

export const setSelectedCardsAction = (payload) => ({
  type: SET_SELECTED_CARDS,
  payload,
})

export const setSelectedCardItemAction = (payload) => ({
  type: SET_SELECTED_CARD_ITEM,
  payload,
})

export const setInitialStateAction = (payload) => ({
  type: SET_INITIAL_STATE,
  payload,
})

export const updateAuditMissionAction = (auditMissionId, callbacks) => {
  const compareAndFormatAudits = (newCards, previousAudits) => {
    const res = {}

    const newCardIds = _.map(newCards, (c) => c.id)
    const previousCardIds = _.map(previousAudits, (a) => a.card.id)

    const cardIdsToDestroy = _.difference(previousCardIds, newCardIds)
    const cardIdsToCreate = _.difference(newCardIds, previousCardIds)
    const cardIdsToUpdate = _.intersection(newCardIds, previousCardIds)

    cardIdsToDestroy.forEach((cardId) => {
      const auditToDestroy = _.find(
        previousAudits,
        (audit) => audit.card.id === cardId,
      )
      res[`${Object.keys(res).length}`] = {
        id: auditToDestroy.id,
        card_id: cardId,
        _destroy: true,
      }
    })

    cardIdsToCreate.forEach((cardId) => {
      const cardToAudit = _.find(newCards, (c) => c.id === cardId)

      res[`${Object.keys(res).length}`] = {
        card_id: cardId,
        forbidden_features: cardToAudit.forbidden_features
          ? cardToAudit.forbidden_features
          : [],
      }
    })

    cardIdsToUpdate.forEach((cardId) => {
      const previousAudit = _.find(previousAudits, (a) => a.card.id === cardId)

      const nextCard = _.find(newCards, (c) => c.id === cardId)

      if (
        !_.isEqual(
          previousAudit.forbidden_features,
          nextCard.forbidden_features,
        )
      ) {
        res[`${Object.keys(res).length}`] = {
          id: previousAudit.id,
          forbidden_features: nextCard.forbidden_features,
        }
      }
    })

    return res
  }

  return (dispatch, getState) => {
    const newAuditMission = JSON.parse(
      JSON.stringify(getState().forms.audit_mission.edit),
    )
    const previousAuditMission = JSON.parse(
      JSON.stringify(newAuditMission.initial_state),
    )

    const {
      form_submitted_once: formSubmittedOnce,
      selected_cards: selectedCards,
      audits,
      cards_dialog: cardsDialog,
      initial_state: initialState,
      ...eligiblesAttributes
    } = newAuditMission

    const body = {
      ...eligiblesAttributes,
      audits_attributes: compareAndFormatAudits(
        selectedCards,
        previousAuditMission.audits,
      ),
    }
    return updateAuditMission(
      auditMissionId,
      body,
    )({
      ...callbacks,
      onBefore: async () => {
        dispatch(setFormElementAction('form_submitted_once', true))
        dispatch(setLoadingAction(true))
      },
      onSuccess: async () => {
        dispatch(
          popSnackbarAction(
            "La mission d'audit a bien été modifiée",
            'success',
          ),
        )

        callbacks.onAfterSuccess()
      },
      onFailure: async (res) =>
        dispatch(
          popSnackbarAction(
            formatErrorsAuditMissionForm(
              "Erreur(s) lors de la modification de la mission d'audit",
              res.errors,
            ),
            'error',
          ),
        ),
      onAfter: async () => dispatch(setLoadingAction(false)),
    })
  }
}
