import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import AuditMissionForm from 'components/organisms/AuditMissionForm'
import { popConfirmationDialogAction } from 'domains/control/actions'
import {
  flushFormAction,
  setFormElementAction,
  setSelectedCardItemAction,
  setSelectedCardsAction,
} from 'domains/forms/audit_mission/create/actions'
import { makeStyles } from '@material-ui/styles'
import Header from 'components/molecules/Header'
import { createAuditMissionAction } from 'domains/audit_missions/actions'
import { auditMissionCreateFormSelector } from 'domains/forms/audit_mission/create/selectors'
import ButtonLink from 'components/atoms/ButtonLink'
import Button from 'components/atoms/Button'
import WithPadding from 'components/templates/WithPadding'
import { AUDIT_MISSION_INDEX_URL } from 'domains/audit_missions/urls'
import WithWarningOnModification from 'components/templates/WithWarningOnModification'

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
  },
})

const Create = (props) => {
  const classes = useStyles()
  const {
    auditMission,
    createAuditMission,
    flushForm,
    popConfirmationDialog,
    setSelectedCardItem,
    setFormElement,
    setSelectedCards,
  } = props

  useEffect(() => {
    flushForm()
  }, [flushForm])

  const [skipUpdate, setSkipUpdate] = useState(false)

  const confirmFlush = () => {
    popConfirmationDialog(
      'Réinitialisation du formulaire',
      'Êtes-vous sûr de vouloir réinitialiser le formulaire ? Tous les changements seront perdus.',
      () => flushForm(),
      () => {},
    )
  }

  const actions = [
    <ButtonLink variant="outlined" to={AUDIT_MISSION_INDEX_URL()}>
      Retour
    </ButtonLink>,
    <Button
      disabled={auditMission.updated}
      key="save"
      onClick={() => {
        setSkipUpdate(true)
        createAuditMission()
      }}
    >
      Enregistrer
    </Button>,
  ]

  return (
    <WithPadding className={classes.root}>
      <WithWarningOnModification
        skip={skipUpdate}
        updated={auditMission.updated}
      />
      <Header
        title="Création d'une mission d'audit"
        actions={actions}
        alignContent="right"
      />
      <AuditMissionForm
        formValues={auditMission}
        setSelectedCardItem={setSelectedCardItem}
        confirmFlush={confirmFlush}
        setFormElement={setFormElement}
        setSelectedCards={setSelectedCards}
      />
      <hr className={classes.separator} />
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  auditMission: auditMissionCreateFormSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  flushForm: () => {
    dispatch(flushFormAction())
  },
  popConfirmationDialog: (title, message, onAgree, onCancel) => {
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel))
  },
  setSelectedCardItem: (card) => dispatch(setSelectedCardItemAction(card)),
  setFormElement: (el, value) => dispatch(setFormElementAction(el, value)),
  createAuditMission: () => dispatch(createAuditMissionAction()),
  setSelectedCards: (cards) => dispatch(setSelectedCardsAction(cards)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Create)
