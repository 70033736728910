/* eslint-disable no-param-reassign */
import React from 'react'
import { connect } from 'react-redux'
import { Box, Typography, Button, makeStyles } from '@material-ui/core'
import { publishAllAuditsAction } from 'domains/audits/actions'
import { auditStatusEnum } from 'domains/audits/enums'
import KanbanColumn from 'domains/audits/displayers/AuditKanban/KanbanColumn'
import PublishAllAuditDialog from 'domains/audits/displayers/AuditKanban/PublishAllAuditDialog'
import { reduce, has, map, invert } from 'lodash'

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    listStyleType: 'none',
    padding: 0,
  },
  columnItem: { display: 'inline-block', width: '475px', padding: 0 },
  columnHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  columnContent: {
    height: theme.spacing(100),
    overflowY: 'scroll',
    padding: theme.spacing(1),
  },
}))

const statusToIndex = {
  not_submitted: 0,
  in_progress: 1,
  waiting_for_review: 2,
  rejected: 3,
  accepted: 4,
  published: 5,
}

const indexToStatus = invert(statusToIndex)

const getAuditsByType = (audits) =>
  reduce(
    audits,
    (result, item) => {
      if (!has(result, statusToIndex[item.status])) {
        result[statusToIndex[item.status]] = []
      }
      result[statusToIndex[item.status]].push(item)
      return result
    },
    {},
  )

const AuditKanban = (props) => {
  const { audits: allAudits, refreshAuditMission, publishAllAudits } = props

  const classes = useStyles()

  const auditsByType = getAuditsByType(allAudits)

  const [openPublishDialog, setOpenPublishDialog] = React.useState(false)

  const publishAudits = (audits) => {
    publishAllAudits(audits, { onAfter: async () => refreshAuditMission() })
  }

  return (
    <ul className={classes.columnContainer}>
      {map(auditsByType, (audits, key) => (
        <li className={classes.columnItem} key={key}>
          <Box className={classes.columnHeader}>
            <Box>
              <Typography variant="h6" component="h4">
                {auditStatusEnum(indexToStatus[key])} ({audits.length})
              </Typography>
            </Box>
            <Box>
              {key === '4' && (
                <>
                  <PublishAllAuditDialog
                    open={openPublishDialog}
                    handleDialogClose={() => setOpenPublishDialog(false)}
                    handleDialogConfirm={() => publishAudits(audits)}
                  />

                  <Button onClick={() => setOpenPublishDialog(true)}>
                    Publier tous les audits
                  </Button>
                </>
              )}
            </Box>
          </Box>
          <Box className={classes.columnContent}>
            {map(audits, (audit) => (
              <KanbanColumn
                key={audit.id}
                audit={audit}
                refreshAuditMission={refreshAuditMission}
              />
            ))}
          </Box>
        </li>
      ))}
    </ul>
  )
}

const mapDispatchToProps = (dispatch) => ({
  publishAllAudits: (audits, callbacks) =>
    dispatch(publishAllAuditsAction(audits, callbacks)),
})

export default connect(null, mapDispatchToProps)(AuditKanban)
