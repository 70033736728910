import React from 'react'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const Dialog = (props) => {
  const { open, setOpen, title = 'default', children = null, ...rest } = props

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MUIDialog onClose={() => setOpen(false)} open={open} {...rest}>
      <MUIDialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </MUIDialogTitle>
      {children}
    </MUIDialog>
  )
}

export default Dialog
