import React from 'react'
import { Grid, Box, Typography, Divider, makeStyles } from '@material-ui/core'
import { map } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  actions: ({ alignContent }) => ({
    flex: 1,
    display: 'flex',
    justifyContent: alignContent === 'right' ? 'flex-end' : 'flex-start',
  }),
}))

const Header = (props) => {
  const { title, actions, alignContent = 'left' } = props
  const classes = useStyles({ alignContent })

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={4}>
            {map(actions, (action, index) => (
              <Grid item key={index}>
                {action}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </Box>
  )
}

export default Header
