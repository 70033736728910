import {
  METHOD_POST,
  METHOD_GET,
  METHOD_PATCH,
  METHOD_DELETE,
} from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from './main'

const suffix = 'users'

export const getCurrentUser = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/me`, query_params)

export const updateCurrentUser = (query_params = {}) =>
  nsfw(METHOD_PATCH, `${suffix}/me`, query_params)

// NEEDFEEDBACK
// export const updateCurrentUserEmail??? = (body, query_params = {}) =>
//   nsfw(METHOD_PATCH, `${suffix}/email`, query_params, body)

export const deleteUser = (body, query_params = {}) =>
  nsfw(METHOD_DELETE, `${suffix}`, query_params, body)

export const getCurrentUserRoles = (body, query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/roles`, query_params, body)

export const checkCurrentUserRoles = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/has_roles`, query_params)

export const createUser = (body, query_params = {}) =>
  nsfw(METHOD_POST, `${suffix}`, query_params, body)

export const checkEmailAvailability = (body, query_params = {}) =>
  nsfw(METHOD_POST, `${suffix}/email_available`, query_params, body)
