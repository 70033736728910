/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Typography, Grid, makeStyles } from '@material-ui/core'
import Header from 'components/molecules/Header'
import Button from 'components/atoms/Button'
import {
  setSelectedTemplateAction,
  setSelectedAuditsAction,
  linkAuditsAndTemplateAction,
} from 'domains/forms/link_referential_to_audits/actions'
import { popConfirmationDialogAction } from 'domains/control/actions'
import { linkReferentialToAuditFormValuesSelector } from 'domains/forms/link_referential_to_audits/selectors'
import WithPadding from 'components/templates/WithPadding'
import AuditSearcher from 'components/organisms/AuditSearcher'
import TemplateSearcher from 'components/organisms/TemplateSearcher'
import { filter, map, includes } from 'lodash'
import AuditSimpleTable from 'domains/audits/displayers/AuditSimpleTable'
import FullTemplate from 'domains/templates/displayers/Full'
import PaperWithPadding from 'components/templates/PaperWithPadding'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  auditTable: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const generateActionButtons = ({
  canDisplayButton,
  popConfirmationDialog,
  linkAuditsAndTemplate,
}) => {
  const onClickAction = () => {
    popConfirmationDialog(
      "Liaison d'un template",
      'Êtes-vous sûr de vouloir lier ces audits à ce template ? Les référentiels existants seront écrasés',
      () => linkAuditsAndTemplate(),
      () => null,
    )
  }

  return canDisplayButton
    ? [
        <Button onClick={() => onClickAction()}>
          Lier les audits au template
        </Button>,
      ]
    : []
}

const Main = (props) => {
  const {
    formValues,
    setSelectedTemplate,
    setSelectedAudits,
    popConfirmationDialog,
    linkAuditsAndTemplate,
  } = props

  const classes = useStyles()
  const [auditSearcherOpen, setAuditSearcherOpen] = useState(false)
  const [templateSearcherOpen, setTemplateSearcherOpen] = useState(false)

  const canDisplayButton =
    formValues.selected_audits.length !== 0 && formValues.selected_template

  const handleOnSelectAudits = (audit) => {
    const selectedAuditsIds = map(
      formValues.selected_audits,
      (selectedAudit) => selectedAudit.id,
    )
    if (includes(selectedAuditsIds, audit.id)) {
      setSelectedAudits(
        filter(
          formValues.selected_audits,
          (selectedAudit) => selectedAudit.id !== audit.id,
        ),
      )
    } else {
      setSelectedAudits([...formValues.selected_audits, audit])
    }
  }

  const handleSelectAllAudits = (audits) => setSelectedAudits(audits)

  const handleOnSelectTemplate = (template) => {
    setSelectedTemplate(template)
    setTemplateSearcherOpen(false)
  }

  return (
    <WithPadding className={classes.root}>
      <Header
        title="Lier un référentiel à des audits"
        actions={generateActionButtons({
          canDisplayButton,
          popConfirmationDialog,
          linkAuditsAndTemplate,
        })}
        alignContent="right"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <PaperWithPadding className={classes.auditSelectContainer}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography gutterBottom variant="h5">
                  Liste des audits
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={() => setAuditSearcherOpen(true)}>
                  Ajouter des audits
                </Button>
              </Grid>
              <Grid item xs={12}>
                <AuditSimpleTable
                  className={classes.auditTable}
                  onSelect={handleOnSelectAudits}
                  audits={formValues.selected_audits}
                />
              </Grid>
            </Grid>
          </PaperWithPadding>
        </Grid>
        <Grid item xs={12} md={4}>
          {formValues.selected_template ? (
            <FullTemplate
              template={formValues.selected_template}
              onClick={() => setTemplateSearcherOpen(true)}
              noAction
            />
          ) : (
            <Button onClick={() => setTemplateSearcherOpen(true)}>
              Choisir un template
            </Button>
          )}
        </Grid>
      </Grid>
      <AuditSearcher
        open={auditSearcherOpen}
        setOpen={setAuditSearcherOpen}
        onSelect={handleOnSelectAudits}
        onSelectAll={handleSelectAllAudits}
        selectedAudits={formValues.selected_audits}
      />
      <TemplateSearcher
        open={templateSearcherOpen}
        setOpen={setTemplateSearcherOpen}
        onSelect={handleOnSelectTemplate}
      />
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  formValues: linkReferentialToAuditFormValuesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplate: (payload) =>
    dispatch(setSelectedTemplateAction(payload)),
  setSelectedAudits: (payload) => dispatch(setSelectedAuditsAction(payload)),
  linkAuditsAndTemplate: () => dispatch(linkAuditsAndTemplateAction()),
  popConfirmationDialog: (title, message, onAgree, onCancel) =>
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
