export const labelAuditMissionEnum = (key) => {
  switch (key) {
    case 'description':
      return "Description de la mission d'audit"
    case 'name':
      return "Intitulé de la mission d'audit"
    case 'ref':
      return 'Référence'
    case 'criteria_choices.label':
      return "Label d'un choix"
    default:
      return ''
  }
}

export const AUDIT_FEATURES_TYPES = [
  { value: 'general', label: 'Modifications générales' },
  { value: 'location', label: 'Localisation' },
  { value: 'referential', label: "Référentiel d'accessibilité" },
  { value: 'accessibility', label: 'Accessibilité Générale' },
  { value: 'photos', label: "Galerie d'images" },
]
