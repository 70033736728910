import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Button from 'components/atoms/Button'
import Header from 'components/molecules/Header'
import TemplateForm from 'components/organisms/TemplateForm'
import { makeStyles } from '@material-ui/styles'
import { popSnackbarAction } from 'domains/control/actions'
import { loadingSelector } from 'domains/control/selectors'
import {
  setFormElementAction,
  fetchTemplateToEditAction,
  updateFormTemplateAction,
} from 'domains/forms/template/edit/actions'
import { editTemplateFormValuesSelector } from 'domains/forms/template/edit/selectors'
import { getCriteriaByIdAction } from 'domains/criterias/actions'
import ButtonLink from 'components/atoms/ButtonLink'
import WithPadding from 'components/templates/WithPadding'
import WithWarningOnModification from 'components/templates/WithWarningOnModification'

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
})

const Edit = (props) => {
  const classes = useStyles()
  const {
    computedMatch: {
      params: { templateId },
    },
    loading,
    formValues,
    setFormElement,
    fetchTemplateToEdit,
    updateTemplate,
  } = props

  const [skipUpdate, setSkipUpdate] = useState(false)

  useEffect(() => {
    fetchTemplateToEdit(templateId)
  }, [fetchTemplateToEdit, templateId])

  const actions = [
    <ButtonLink variant="outlined" to="/templates/">
      Retour
    </ButtonLink>,
    <Button
      disabled={loading || formValues.updated}
      onClick={() => {
        setSkipUpdate(true)
        updateTemplate()
      }}
    >
      Enregistrer
    </Button>,
  ]

  return (
    <WithPadding className={classes.root}>
      <WithWarningOnModification
        skip={skipUpdate}
        updated={formValues.updated}
      />
      <Header
        title="Modifier un template"
        alignContent="right"
        actions={actions}
      />
      <TemplateForm formValues={formValues} setFormElement={setFormElement} />
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  formValues: editTemplateFormValuesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setFormElement: (element, value) => {
    dispatch(setFormElementAction(element, value))
  },
  fetchTemplateToEdit: (templateId) => {
    dispatch(fetchTemplateToEditAction(templateId))
  },
  getCriteriaById: (id, callbacks) =>
    dispatch(getCriteriaByIdAction(id, callbacks)),
  updateTemplate: () => {
    dispatch(updateFormTemplateAction())
  },
  popSnackbar: (message, type) => {
    dispatch(popSnackbarAction(message, type))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
