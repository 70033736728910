/* eslint-disable camelcase */
import { combineReducers } from 'redux'
import criteria from './criteria'
import template from './template'
import audit_mission from './audit_mission'
import card_link from './card_link/reducer'
import link_referential_to_audits from './link_referential_to_audits/reducer'
import sign_in from './sign_in/reducer'

const forms = combineReducers({
  criteria,
  template,
  audit_mission,
  card_link,
  link_referential_to_audits,
  sign_in,
})

export default forms
