/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const HandicapPsychique = (props) => (
  <svg viewBox="0 0 1190.55 841.89" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M540.698 589.025c-19.375-8.509-38.52-15.181-55.803-25.1-12.124-6.955-22.91-17.308-32.075-28.039-8.695-10.182-6.928-30.246 1.829-38.298 10.225-9.402 21.769-6.784 33.555-2.094 26.139 10.397 52.503 20.229 83.252 32.004-13.506-47.818 12.12-61.182 49.309-65.621-7.108-19.225-14.814-36.82-20.111-55.113-9.098-31.413-18.512-62.923-24.499-95.007-9.502-50.925-18.603-101.696-41.962-148.624-3.713-7.458-8.594-15.115-9.398-23.041-.962-9.484 4.588-17.578 14.941-19.772 10.129-2.146 19.642-.299 24.62 9.871 7.881 16.094 15.302 32.435 22.315 48.926 15.438 36.292 20.903 74.971 28.265 113.406 7.892 41.191 15.195 82.802 32.661 121.74 6.627 14.774 10.215 31.148 13.465 47.145 4.497 22.146-18.953 38.408-35.749 42.255-4.766 1.092-9.526 2.2-13.405 3.097-.556 11.274.652 22.435-2.027 32.567-3.733 14.112-19.121 20.188-35.692 16.008-25.904-6.534-51.739-13.348-78.439-18.229 10.134 4.459 20.037 9.587 30.459 13.22 15.221 5.304 30.853 9.417 46.239 14.263 10.278 3.235 17.087 21.027 9.592 32.872-13.591 21.478-7.164 42.755-4.563 64.864 3.515 29.896-7.724 50.716-40.411 59.256-54.237 14.169-106.41 4.993-154.421-21.456-28.363-15.626-53.526-37.187-79.558-56.838-7.463-5.634-14.095-13.576-11.184-24.567 4.498-16.977 24.538-22.01 37.411-9.531 34.901 33.828 73.947 61.763 122.096 71.689 22.979 4.737 47.628 2.783 71.399 1.533 9.138-.479 16.149-6.297 12.729-20.22-5.964-24.252-11.807-49.598 5.16-73.166z" />
      <path d="M849.488 475.836c-2.371 51.177-15.328 100.153-36.924 145.833-22.961 48.566-59.603 85.326-113.292 100.026-35.579 9.741-71.597 9.144-107.134-2.464-10.645-3.476-14.424-11.04-13.336-20.709 1.197-10.65 6.882-18.925 19.188-18.054 19.877 1.406 39.671 4.624 59.553 5.178 40.557 1.131 70.408-19.396 94.902-49.653 34.946-43.172 45.74-94.902 52.267-148.055 1.064-8.677.627-17.629.05-26.4-1.245-18.888 6.475-25.822 34.35-27.6-8.935-42.499-.398-83.828 11.592-126.24-10.103 0-17.386.085-24.666-.019-12.985-.185-21.22-8.126-21.141-21.042.272-44.188-1.34-88.207-11.165-131.513-1.575-6.948-3.539-13.947-6.433-20.426-6.398-14.319-4.165-23.19 8.233-28.984 12.308-5.751 27.425-.63 31.031 12.605 6.587 24.175 12.798 48.629 16.472 73.369 3.066 20.644 2.557 41.854 3.057 62.827.185 7.722 1.943 11.395 10.725 12.032 24.984 1.813 41.529 26.07 35.678 50.844-5.262 22.282-12.083 44.37-15.053 66.96-2.097 15.96.332 32.699 2.117 48.939 1.988 18.097-11.39 35.986-30.071 42.546zM500.784 343.724c-18.383-1.984-36.935-3.01-55.099-6.231-17.215-3.053-21.312-17.155-9.305-29.709 14.367-15.024 32.448-24.383 52.784-26.836 11.918-1.438 21.361 7.794 26.044 18.013 4.51 9.84 7.893 21.429 7.343 32.019-.594 11.435-11.091 14.103-21.767 12.744z" />
      <path d="M697.056 341.772c-11.228-.984-22.952-.26-33.567-3.348-16.49-4.798-16.818-13.835-8.871-24.172 13.261-17.249 30.629-31.432 54.805-31.34 18.111.067 38.801 26.682 34.474 44.24-2.65 10.755-11.405 13.038-20.691 13.558-8.667.485-17.382.103-26.076.103-.026.319-.05.64-.074.959zM653.449 593.401c-16.83-1.766-29.191-1.159-39.992-4.972-5.69-2.009-11.35-11.504-11.906-18.055-.369-4.341 7.708-10.469 13.204-13.842 3.336-2.047 9.324-1.505 13.493-.17 19.784 6.346 38.516.226 57.329-4.186 16.414-3.85 23.038-1.89 26.176 11.847 2.868 12.561-5.635 18.318-16.756 20.697-15.339 3.283-30.709 6.423-41.548 8.681z" />
    </g>
  </svg>
)

export default HandicapPsychique
