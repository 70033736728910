import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLogged } from 'api/utils/authentication'

const ProtectedRoute = (props) => {
  const { exact, path, component: Component } = props
  return (
    <Route
      exact={exact}
      path={path}
      render={() => {
        if (isLogged()) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component {...props} />
        }
        return <Redirect to={{ pathname: '/sign_in' }} />
      }}
    />
  )
}

export default ProtectedRoute
