/* eslint-disable no-unneeded-ternary */
import React from 'react'
import {
  Box,
  Divider,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import ExitToApp from '@material-ui/icons/ExitToApp'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import DetailRow from 'components/molecules/DetailRow'
import KanbanAuditActionButtons from 'domains/audits/displayers/AuditKanban/ActionButtons'
import { auditStatusEnum } from 'domains/audits/enums'
import { openInNewTab } from 'domains/audits/utils'
import {
  formatAuditSubmitter,
  formatAuditModerator,
  formatAuditCardTitle,
  formatAuditTitle,
} from 'domains/audits/formatters'

const useStyles = makeStyles((theme) => ({
  root: ({ color }) => ({
    backgroundColor: color ? color : 'inherit',
    marginBottom: theme.spacing(1),
  }),
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  title: () => ({
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}))

const KanbanColumn = (props) => {
  const { audit, refreshAuditMission } = props
  const theme = useTheme()

  let color = null

  if (audit.status === 'not_submitted') {
    color = `${theme.palette.common.greys.light}10`
  }
  if (audit.status === 'published') {
    color = `${theme.palette.common.green}20`
  }
  if (audit.status === 'rejected') {
    color = `${theme.palette.error.main}20`
  }

  const classes = useStyles({ color })

  return (
    <PaperWithPadding padding={1} className={classes.root}>
      <Box className={classes.header}>
        <DetailRow title="Titre du lieu (audit)">
          <Box
            className={classes.title}
            onClick={() => openInNewTab('audit', audit.id)}
          >
            <Typography component="a" title="Ouvrir dans un nouvel onglet">
              {formatAuditTitle(audit)}
              <ExitToApp color="inherit" fontSize="small" />
            </Typography>
          </Box>
        </DetailRow>
      </Box>
      <Box className={classes.header}>
        <DetailRow title="Titre du lieu (card)">
          <Box
            className={classes.title}
            onClick={() => openInNewTab('card', audit.card.slug)}
          >
            <Typography component="a" title="Ouvrir dans un nouvel onglet">
              {formatAuditCardTitle(audit)}
              <ExitToApp color="inherit" fontSize="small" />
            </Typography>
          </Box>
        </DetailRow>
      </Box>
      <Box>
        <DetailRow title="Statut de l'audit">
          {auditStatusEnum(audit.status)}
        </DetailRow>
      </Box>

      {audit.submitted && (
        <>
          <Box>
            <DetailRow title="Demande de validation">
              {formatAuditSubmitter(audit)}
            </DetailRow>
          </Box>
        </>
      )}

      {audit.approved && (
        <>
          <Box>
            <DetailRow title="Modération">
              {formatAuditModerator(audit)}
            </DetailRow>
          </Box>
        </>
      )}

      <Divider className={classes.divider} />
      <KanbanAuditActionButtons
        audit={audit}
        refreshAuditMission={refreshAuditMission}
      />
    </PaperWithPadding>
  )
}

export default KanbanColumn
