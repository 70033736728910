import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: ({ padding }) => theme.spacing(padding),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}))

const PaperWithPadding = ({
  padding = 4,
  className = '',
  onClick = null,
  children,
}) => {
  const classes = useStyles({ padding })

  return (
    <Paper onClick={onClick} className={`${classes.root} ${className}`}>
      {children}
    </Paper>
  )
}

export default PaperWithPadding
