import {
  SET_INITIAL_STATE,
  SET_QUERY,
  SET_AUDITS_PUBLISHED_DONE_PROGRESS,
} from './types'

const initialState = {
  query: {
    search_text: '',
  },
  auditsPublishedDoneProgress: 0.0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return initialState
    case SET_QUERY:
      return {
        ...state,
        query: action.payload || {},
      }
    case SET_AUDITS_PUBLISHED_DONE_PROGRESS:
      return {
        ...state,
        auditsPublishedDoneProgress: action.payload || 0.0,
      }
    default:
      return state
  }
}

export default reducer
