import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Typography, Fab, Divider, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Visibility from '@material-ui/icons/Visibility'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import { includes } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import DetailRow from 'components/molecules/DetailRow'
import BooleanIcon from 'components/atoms/BooleanIcon'
import { AUDIT_MISSION_SHOW_URL, AUDIT_MISSION_EDIT_URL } from '../urls'
import {
  setSelectedAuditMissionAction,
  deleteAuditMissionAction,
  getAuditMissionsAction,
  updateAuditMissionAction,
  getAuditMissionByIdAction,
} from '../actions'
import { getAuditMissionStatus } from '../formatters'

moment.locale('fr')

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  fab: {
    marginLeft: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  list: {
    margin: 0,
  },
  link: {
    marginLeft: theme.spacing(1),
  },
  linkItem: {
    display: 'flex',
  },
}))

const InDialog = (props) => {
  const {
    auditMission,
    getAuditMissions,
    getAuditMissionById,
    updateAuditMission,
    deleteAuditMission,
    setSelectedAuditMission,
    pushUrl,
    setOpen,
  } = props
  const classes = useStyles()

  const handleShow = (id) => {
    pushUrl(AUDIT_MISSION_SHOW_URL(id))
    setSelectedAuditMission(null)
  }

  const handleEdit = (id) => {
    pushUrl(AUDIT_MISSION_EDIT_URL(id))
    setSelectedAuditMission(null)
  }

  const refreshAuditMission = () => {
    getAuditMissions()
    getAuditMissionById(auditMission.id, {
      onSuccess: async (res) => {
        setSelectedAuditMission(res)
      },
    })
  }

  const switchStatus = async () => {
    let newStatus = null
    switch (auditMission.status) {
      case 'pending':
        newStatus = 'in_progress'
        break
      case 'in_progress':
        newStatus = 'finish'
        break
      case 'finish':
        newStatus = 'pending'
        break
      default:
        newStatus = 'pending'
        break
    }

    updateAuditMission(
      auditMission.id,
      {
        status: newStatus,
      },
      {
        onSuccess: async () => refreshAuditMission(),
      },
    )
  }

  const switchActive = async () => {
    updateAuditMission(
      auditMission.id,
      {
        active: !auditMission.active,
      },
      {
        onSuccess: async () => refreshAuditMission(),
      },
    )
  }

  const switchArchived = async () => {
    updateAuditMission(
      auditMission.id,
      {
        archived: !auditMission.archived,
      },
      {
        onSuccess: async () => refreshAuditMission(),
      },
    )
  }

  return (
    auditMission && (
      <PaperWithPadding>
        <Typography gutterBottom variant="h5">
          {`Mission d'audit ${auditMission.ref}`}
        </Typography>
        <Divider className={classes.divider} />
        <DetailRow title="Titre">{auditMission.title}</DetailRow>
        <DetailRow title="Token">
          <CopyToClipboard
            text={`${window.env.REACT_APP_AUDIT_SIGNIN_URL_PREFIX}${auditMission.token}`}
          >
            <Button variant="outlined" size="small">
              Copier le lien de connexion
            </Button>
          </CopyToClipboard>
        </DetailRow>
        <DetailRow title="Archivé">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => switchArchived()}
          >
            <BooleanIcon value={auditMission.archived} />
          </Button>
        </DetailRow>
        <DetailRow title="Actif">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => switchActive()}
          >
            <BooleanIcon value={auditMission.active} />
          </Button>
        </DetailRow>
        <DetailRow title="Status">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => switchStatus()}
          >
            {getAuditMissionStatus(auditMission.status)}
          </Button>
        </DetailRow>
        <DetailRow title="Opérateur">{auditMission.operator_name}</DetailRow>
        {auditMission.message && (
          <DetailRow title="Message">
            <Typography>{auditMission.message}</Typography>
          </DetailRow>
        )}
        <DetailRow title="Créé le">
          {moment(auditMission.created_at).format('L')}
        </DetailRow>
        <DetailRow title="Mis à jour le">
          {moment(auditMission.updated_at).format('L')}
        </DetailRow>
        <DetailRow title="Termine le">
          {moment(auditMission.ends_at).format('L')}
        </DetailRow>

        <Divider className={classes.divider} />
        <div className={classes.actions}>
          <Fab
            size="small"
            color="primary"
            aria-label="Voir"
            className={classes.fab}
            onClick={() => handleShow(auditMission.id)}
          >
            <Visibility />
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="Modifier"
            className={classes.fab}
            onClick={() => handleEdit(auditMission.id)}
          >
            <Edit />
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="Supprimer"
            disabled={includes(auditMission.ref, 'DO_NOT_DELETE')}
            className={classes.fab}
            onClick={() => {
              deleteAuditMission(auditMission.id)
              setOpen(false)
            }}
          >
            <Delete />
          </Fab>
        </div>
      </PaperWithPadding>
    )
  )
}

const mapDispatchToProps = (dispatch) => ({
  getAuditMissions: (callbacks) => dispatch(getAuditMissionsAction(callbacks)),
  getAuditMissionById: (id, callbacks) =>
    dispatch(getAuditMissionByIdAction(id, callbacks)),
  updateAuditMission: (id, body, callbacks) =>
    dispatch(updateAuditMissionAction(id, body, callbacks)),
  setSelectedAuditMission: (auditMission) =>
    dispatch(setSelectedAuditMissionAction(auditMission)),
  deleteAuditMission: (id) => dispatch(deleteAuditMissionAction(id)),
  pushUrl: (url) => dispatch(push(url)),
})

export default connect(null, mapDispatchToProps)(InDialog)
