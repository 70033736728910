/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Typography, Grid, makeStyles } from '@material-ui/core'
import Header from 'components/molecules/Header'
import Button from 'components/atoms/Button'
import {
  setSelectedTemplateAction,
  setSelectedCardsAction,
  bindCardsAndTemplateAction,
} from 'domains/forms/card_link/actions'
import { popConfirmationDialogAction } from 'domains/control/actions'
import { cardLinkFormValuesSelector } from 'domains/forms/card_link/selectors'
import WithPadding from 'components/templates/WithPadding'
import CardSearcher from 'components/organisms/CardSearcher'
import TemplateSearcher from 'components/organisms/TemplateSearcher'
import { filter } from 'lodash'
import CardSimpleTable from 'domains/cards/displayers/SimpleTable'
import FullTemplate from 'domains/templates/displayers/Full'
import PaperWithPadding from 'components/templates/PaperWithPadding'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTable: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
}))

const generateActionButtons = ({
  canDisplayButton,
  popConfirmationDialog,
  bindCardsAndTemplate,
}) => {
  const onClickAction = () => {
    popConfirmationDialog(
      "Liaison d'un template",
      'Êtes-vous sûr de vouloir lier ces lieux à ce template ? Les référentiels existants seront écrasés',
      () => bindCardsAndTemplate(),
      () => null,
    )
  }

  return canDisplayButton
    ? [
        <Button onClick={() => onClickAction()}>
          Lier les lieux au template
        </Button>,
      ]
    : []
}

const Main = (props) => {
  const {
    formValues,
    setSelectedTemplate,
    setSelectedCards,
    popConfirmationDialog,
    bindCardsAndTemplate,
  } = props

  const classes = useStyles()
  const [cardSearcherOpen, setCardSearcherOpen] = useState(false)
  const [templateSearcherOpen, setTemplateSearcherOpen] = useState(false)

  const canDisplayButton =
    formValues.selected_cards.length !== 0 && formValues.selected_template

  const handleOnSelectCards = (card) => {
    const selectedCardsIds = formValues.selected_cards.map(
      (selectedCard) => selectedCard.id,
    )
    if (selectedCardsIds.includes(card.id)) {
      setSelectedCards(
        filter(
          formValues.selected_cards,
          (selectedCard) => selectedCard.id !== card.id,
        ),
      )
    } else {
      setSelectedCards([...formValues.selected_cards, card])
    }
  }

  const handleOnSelectTemplate = (template) => {
    setSelectedTemplate(template)
    setTemplateSearcherOpen(false)
  }

  return (
    <WithPadding className={classes.root}>
      <Header
        title="Lier un référentiel à des lieux"
        actions={generateActionButtons({
          canDisplayButton,
          popConfirmationDialog,
          bindCardsAndTemplate,
        })}
        alignContent="right"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <PaperWithPadding>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Liste des lieux</Typography>
              </Grid>
              <Grid item>
                <Button onClick={() => setCardSearcherOpen(true)}>
                  Ajouter des lieux
                </Button>
              </Grid>
            </Grid>
            <CardSimpleTable
              className={classes.cardTable}
              onSelect={handleOnSelectCards}
              cards={formValues.selected_cards}
            />
          </PaperWithPadding>
        </Grid>
        <Grid item xs={12} md={3}>
          {formValues.selected_template && (
            <FullTemplate
              template={formValues.selected_template}
              onClick={() => setTemplateSearcherOpen(true)}
              noAction
            />
          )}
          {!formValues.selected_template && (
            <Button onClick={() => setTemplateSearcherOpen(true)}>
              Choisir un template
            </Button>
          )}
        </Grid>
      </Grid>
      <CardSearcher
        open={cardSearcherOpen}
        setOpen={setCardSearcherOpen}
        onSelect={handleOnSelectCards}
        selectedCards={formValues.selected_cards}
      />
      <TemplateSearcher
        open={templateSearcherOpen}
        setOpen={setTemplateSearcherOpen}
        onSelect={handleOnSelectTemplate}
      />
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  formValues: cardLinkFormValuesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplate: (payload) => {
    dispatch(setSelectedTemplateAction(payload))
  },
  setSelectedCards: (payload) => {
    dispatch(setSelectedCardsAction(payload))
  },
  bindCardsAndTemplate: () => {
    dispatch(bindCardsAndTemplateAction())
  },
  popConfirmationDialog: (title, message, onAgree, onCancel) => {
    dispatch(popConfirmationDialogAction(title, message, onAgree, onCancel))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
