/* eslint-disable import/prefer-default-export */
export const labelTemplateEnum = (key) => {
  switch (key) {
    case 'description':
      return 'Description du template'
    case 'name':
      return 'Intitulé du template'
    case 'ref':
      return 'Référence'
    case 'criteria_choices.label':
      return "Label d'un choix"
    default:
      return ''
  }
}
