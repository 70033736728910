import { getHeaders, getUrl } from 'api/utils/getters'
import mainFetchWrapper from 'api/fetch'

const getNamespaceHeaders = (method) => ({
  method,
  headers: getHeaders('picto-admin'),
})

export const namespaceFetchWrapper =
  (method, endpoint, urlParams, body = null) =>
  (callbacks = {}) =>
    mainFetchWrapper(
      getUrl(endpoint, urlParams),
      getNamespaceHeaders(method),
      callbacks,
      body,
    )
