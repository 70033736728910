import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Typography,
  Box,
  Fab,
  Divider,
  Button,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import { includes } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Skeleton from '@material-ui/lab/Skeleton'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import DetailRow from 'components/molecules/DetailRow'
import BooleanIcon from 'components/atoms/BooleanIcon'
import AuditKanban from 'domains/audits/displayers/AuditKanban'
import SkeletonAuditMissionFull from 'domains/audit_missions/skeleton/Full'
import { getAuditMissionStatus } from 'domains/audit_missions/formatters'
import { AUDIT_MISSION_EDIT_URL } from 'domains/audit_missions/urls'
import {
  setSelectedAuditMissionAction,
  deleteAuditMissionAction,
  updateAuditMissionAction,
  getAuditMissionByIdParamsAction,
} from '../actions'

moment.locale('fr')

const useStyles = makeStyles((theme) => ({
  fab: {
    marginLeft: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Full = (props) => {
  const {
    auditMission,
    getAuditMissionById,
    updateAuditMission,
    deleteAuditMission,
    setSelectedAuditMission,
    pushUrl,
    setOpen,
  } = props
  const classes = useStyles()

  const [anchorMenuArchivedEl, setAnchorMenuArchivedEl] = React.useState(null)
  const [anchorMenuActiveEl, setAnchorMenuActiveEl] = React.useState(null)
  const [anchorMenuStatusEl, setMenuStatusAnchorEl] = React.useState(null)

  const handleMenuArchivedOpen = (event) =>
    setAnchorMenuArchivedEl(event.currentTarget)
  const handleMenuArchivedClose = () => setAnchorMenuArchivedEl(null)

  const handleMenuActiveOpen = (event) =>
    setAnchorMenuActiveEl(event.currentTarget)
  const handleMenuActiveClose = () => setAnchorMenuActiveEl(null)

  const handleMenuStatusOpen = (event) =>
    setMenuStatusAnchorEl(event.currentTarget)
  const handleMenuStatusClose = () => setMenuStatusAnchorEl(null)

  const handleEdit = (id) => {
    pushUrl(AUDIT_MISSION_EDIT_URL(id))
    setSelectedAuditMission(null)
  }

  const refreshAuditMission = () => getAuditMissionById(auditMission.id)

  const switchStatus = async (status) => {
    if (
      !includes(['pending', 'in_progress', 'finish'], status) ||
      auditMission.status === status
    ) {
      return
    }

    updateAuditMission(
      auditMission.id,
      {
        status,
      },
      {
        onSuccess: async () => refreshAuditMission(),
      },
    )
  }

  const switchActive = async (markForActive) => {
    if (auditMission.active === markForActive) return
    updateAuditMission(
      auditMission.id,
      {
        active: markForActive,
      },
      {
        onSuccess: async () => refreshAuditMission(),
      },
    )
  }

  const switchArchived = async (markForArchived) => {
    if (auditMission.archived === markForArchived) return
    updateAuditMission(
      auditMission.id,
      {
        archived: markForArchived,
      },
      {
        onSuccess: async () => refreshAuditMission(),
      },
    )
  }
  if (!auditMission) return <SkeletonAuditMissionFull />

  return (
    <PaperWithPadding>
      <Typography gutterBottom variant="h5">
        {auditMission ? (
          `Mission d'audit ${auditMission.ref}`
        ) : (
          <Skeleton variant="text" />
        )}
      </Typography>
      <Divider className={classes.divider} />
      <DetailRow title="Titre">{auditMission.title}</DetailRow>
      <DetailRow title="Token">
        <CopyToClipboard
          text={`${window.env.REACT_APP_AUDIT_SIGNIN_URL_PREFIX}${auditMission.token}`}
        >
          <Button variant="outlined" size="small">
            Copier le lien de connexion
          </Button>
        </CopyToClipboard>
      </DetailRow>
      <DetailRow title="Archivée">
        <Box>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            aria-controls="menu-archived"
            aria-haspopup="true"
            onClick={handleMenuArchivedOpen}
          >
            <BooleanIcon value={auditMission.archived} />
          </Button>
          <Menu
            id="menu-archived"
            anchorEl={anchorMenuArchivedEl}
            keepMounted
            open={Boolean(anchorMenuArchivedEl)}
            onClose={handleMenuArchivedClose}
          >
            <MenuItem
              onClick={() => {
                switchArchived(true)
                handleMenuArchivedClose()
              }}
            >
              Marquer cette mission d&apos;audit comme archivé
            </MenuItem>
            <MenuItem
              onClick={() => {
                switchArchived(false)
                handleMenuArchivedClose()
              }}
            >
              Marquer cette mission d&apos;audit comme non-archivé
            </MenuItem>
          </Menu>
        </Box>
      </DetailRow>
      <DetailRow title="Active">
        <Box>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            aria-controls="menu-active"
            aria-haspopup="true"
            onClick={handleMenuActiveOpen}
          >
            <BooleanIcon value={auditMission.active} />
          </Button>
          <Menu
            id="menu-active"
            anchorEl={anchorMenuActiveEl}
            keepMounted
            open={Boolean(anchorMenuActiveEl)}
            onClose={handleMenuActiveClose}
          >
            <MenuItem
              onClick={() => {
                switchActive(true)
                handleMenuActiveClose()
              }}
            >
              Marquer cette mission d&apos;audit comme active
            </MenuItem>
            <MenuItem
              onClick={() => {
                switchActive(false)
                handleMenuActiveClose()
              }}
            >
              Marquer cette mission d&apos;audit comme inactive
            </MenuItem>
          </Menu>
        </Box>
      </DetailRow>
      <DetailRow title="Termine le">
        {moment(auditMission.ends_at).format('L')}
      </DetailRow>
      <DetailRow title="Opérateur">{auditMission.operator_name}</DetailRow>
      <DetailRow title="Status">
        <Box>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            aria-controls="menu-status"
            aria-haspopup="true"
            onClick={handleMenuStatusOpen}
          >
            {getAuditMissionStatus(auditMission.status)}
          </Button>
          <Menu
            id="menu-status"
            anchorEl={anchorMenuStatusEl}
            keepMounted
            open={Boolean(anchorMenuStatusEl)}
            onClose={handleMenuStatusClose}
          >
            <MenuItem
              onClick={() => {
                switchStatus('pending')
                handleMenuStatusClose()
              }}
            >
              Marquer cette mission d&apos;audit comme &quot;En attente&quot;
            </MenuItem>
            <MenuItem
              onClick={() => {
                switchStatus('in_progress')
                handleMenuStatusClose()
              }}
            >
              Marquer cette mission d&apos;audit comme &quot;En cours&quot;
            </MenuItem>
            <MenuItem
              onClick={() => {
                switchStatus('finish')
                handleMenuArchivedClose()
              }}
            >
              Marquer cette mission d&apos;audit comme &quot;Terminer&quot;
            </MenuItem>
          </Menu>
        </Box>
      </DetailRow>
      {auditMission.message && (
        <DetailRow title="Message">
          <Typography>{auditMission.message}</Typography>
        </DetailRow>
      )}
      <DetailRow title="Créé le">
        {moment(auditMission.created_at).format('L')}
      </DetailRow>
      <DetailRow title="Mis à jour le">
        {moment(auditMission.updated_at).format('L')}
      </DetailRow>
      <AuditKanban
        audits={auditMission.audits}
        refreshAuditMission={refreshAuditMission}
      />

      <Divider className={classes.divider} />
      <div className={classes.actions}>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => handleEdit(auditMission.id)}
        >
          <Edit />
        </Fab>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => {
            deleteAuditMission(auditMission.id)
            setOpen(false)
          }}
        >
          <Delete />
        </Fab>
      </div>
    </PaperWithPadding>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getAuditMissionById: (id, callbacks) =>
    dispatch(
      getAuditMissionByIdParamsAction(id, { view: 'kanban' }, callbacks),
    ),
  updateAuditMission: (id, body, callbacks) =>
    dispatch(updateAuditMissionAction(id, body, callbacks)),
  setSelectedAuditMission: (auditMission) =>
    dispatch(setSelectedAuditMissionAction(auditMission)),
  deleteAuditMission: (id) => dispatch(deleteAuditMissionAction(id)),
  pushUrl: (url) => dispatch(push(url)),
})

export default connect(null, mapDispatchToProps)(Full)
