/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { forEach, map, includes } from 'lodash'

let loopHasBeenFound = false

const hasInfiniteLoop = (choices, idToAvoid) => {
  forEach(choices, (choice) => {
    forEach(choice.linked_criterias, (criteria) => {
      if (criteria.id === idToAvoid) {
        loopHasBeenFound = true
      }

      if (!loopHasBeenFound) {
        hasInfiniteLoop(criteria.criteria_choices, idToAvoid)
      }
    })
  })
}

const hasCriteriaInRootChoice = (choiceCriteriaIsAppended, idToAvoid) => {
  const criteriaIds = map(
    choiceCriteriaIsAppended.linked_criterias,
    (criteria) => criteria.id,
  )

  return includes(criteriaIds, idToAvoid)
}

export const criteriaIsEligible = (
  choiceCriteriaIsAppended,
  criteriaToLink,
) => {
  loopHasBeenFound = false

  if (hasCriteriaInRootChoice(choiceCriteriaIsAppended, criteriaToLink.id)) {
    return {
      eligible: false,
      reason: `${criteriaToLink.ref} est déjà lié à cette réponse (duplicatat)`,
    }
  }

  hasInfiniteLoop([choiceCriteriaIsAppended], criteriaToLink.id)

  return {
    eligible: !loopHasBeenFound,
    reason: `${criteriaToLink.ref} est impossible à lier car il fait référence au critère en cours d'édition (boucle infinie)`,
  }
}
