import { combineReducers } from 'redux'
import create from './create/reducer'
import edit from './edit/reducer'

const forms = combineReducers({
  create,
  edit,
})

export default forms
