import React from 'react'
import { Typography, Input, MenuItem, Chip } from '@material-ui/core'
import { ArrowDropDown, Cancel, ArrowDropUp, Clear } from '@material-ui/icons'
import Select from 'react-select'

const Option = (props) => {
  const { option, onSelect, onFocus, isFocused, children } = props
  const handleClick = (event) => {
    onSelect(option, event)
  }

  return (
    <MenuItem
      onFocus={onFocus}
      selected={isFocused}
      onClick={handleClick}
      component="div"
    >
      {children}
    </MenuItem>
  )
}

const SelectWrapped = (props) => {
  const { classes, options, className, onChange, value } = props

  return (
    <Select
      value={value}
      onChange={onChange}
      optionComponent={Option}
      noResultsText={<Typography>Aucun résultat</Typography>}
      arrowRenderer={(arrowProps) =>
        arrowProps.isOpen ? <ArrowDropUp /> : <ArrowDropDown />
      }
      clearRenderer={() => <Clear />}
      valueComponent={(valueProps) => {
        const { value: valueCmp, children, onRemove } = valueProps
        const onDelete = (event) => {
          event.preventDefault()
          event.stopPropagation()
          onRemove(valueCmp)
        }

        if (onRemove) {
          return (
            <Chip
              tabIndex={-1}
              label={valueCmp.label}
              className={classes.chip}
              deleteIcon={<Cancel onTouchEnd={onDelete} />}
              onDelete={onDelete}
            />
          )
        }
        return <div className="Select-value">{children}</div>
      }}
      className={className}
      options={options}
    />
  )
}

const AutoComplete = (props) => {
  const { value, onChange, placeholder, options, className = '' } = props

  return (
    <div className={className}>
      <Input
        fullWidth
        inputComponent={SelectWrapped}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        id="react-select-single"
        inputProps={{
          name: 'react-select-single',
          instanceId: 'react-select-single',
          simpleValue: true,
          options,
        }}
      />
    </div>
  )
}

export default AutoComplete
