/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { IconButton, makeStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { debounce, isEmpty } from 'lodash'
import { loadingSelector } from 'domains/control/selectors'
import Header from 'components/molecules/Header'
import Text from 'components/atoms/Text'
import ButtonLink from 'components/atoms/ButtonLink'

const useStyles = makeStyles((theme) => ({
  flex: { display: 'flex' },
  textMargin: {
    marginTop: theme.spacing(2),
    width: theme.spacing(55),
  },
  loader: {
    width: 30,
    height: 30,
  },
  resultsContainer: {
    marginLeft: theme.spacing(2),
  },
  resultsTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  resultsText: {
    marginRight: theme.spacing(1),
  },
}))

const debouncedSearch = debounce((getItems) => {
  getItems({}, {})
}, 500)

const ResultContainer = ({ setItemSearch, getItems, resultsText }) => {
  const classes = useStyles()
  if (isEmpty(resultsText)) return null

  return (
    <div className={classes.resultsTextContainer}>
      <p className={classes.resultsText}>{resultsText}</p>
      <IconButton
        color="primary"
        onClick={() => {
          setItemSearch('')
          getItems()
        }}
      >
        <Close />
      </IconButton>
    </div>
  )
}

const TableHeader = ({
  title,
  createUrl,
  pagination,
  search,
  setItemSearch,
  getItems,
  loading,
}) => {
  const classes = useStyles({ loading })

  const handleChange = (e) => {
    setItemSearch(e.target.value)
    debouncedSearch(getItems)
  }

  const resultsText =
    !isEmpty(pagination) && !isEmpty(search)
      ? `${pagination.total_count} résultat(s) pour "${search}"`
      : null

  const actions = [
    <ButtonLink key="add" to={createUrl} iconLeft="add">
      Créer
    </ButtonLink>,
    setItemSearch && (
      <div className={classes.flex}>
        <Text
          className={classes.textMargin}
          placeholder="Rechercher par référence, intitulé, catégories, marques ..."
          value={search}
          onChange={handleChange}
        />
        {!loading && (
          <ResultContainer
            resultsText={resultsText}
            setItemSearch={setItemSearch}
            getItems={getItems}
          />
        )}
      </div>
    ),
  ]

  return <Header title={title} actions={actions} />
}

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
})

export default connect(mapStateToProps, null)(TableHeader)
