import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { TextField, Typography, makeStyles } from '@material-ui/core'
import Dialog from 'components/templates/Dialog'
import CriteriaSimpleTable from 'domains/criterias/displayers/SimpleTable'
import { getCriteriasWithSearchAction } from 'domains/criterias/actions'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import { debounce, isEmpty } from 'lodash'
import { loadingSelector } from 'domains/control/selectors'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  text_field: {
    // minWidth: theme.spacing(20),
    width: '100%',
  },
  no_results: {
    marginTop: theme.spacing(2),
  },
}))

const CriteriaSearcher = (props) => {
  const debouncerRef = useRef()
  const { open, setOpen, onSelect, getCriteriasWithSearch } = props
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])

  const classes = useStyles()

  useEffect(() => {
    debouncerRef.current = debounce(
      (search) =>
        getCriteriasWithSearch(search, {
          onSuccess: async (res) => setResults(res.criterias),
        }),
      800,
    )
    return () => {
      debouncerRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (e) => {
    setQuery(e.target.value)
    debouncerRef.current(e.target.value)
  }

  return (
    <Dialog
      maxWidth={false}
      open={open}
      setOpen={setOpen}
      title="Trouver un critère"
    >
      <PaperWithPadding>
        <div className={classes.header}>
          <TextField
            className={classes.text_field}
            label="Rechercher un critère"
            value={query}
            onChange={onChange}
            autoFocus
          />
        </div>
        <CriteriaSimpleTable criterias={results} onSelect={onSelect} />
        {isEmpty(results) && (
          <Typography className={classes.no_results} align="center">
            Aucun résultat
          </Typography>
        )}
      </PaperWithPadding>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getCriteriasWithSearch: (query, callbacks) => {
    dispatch(getCriteriasWithSearchAction(query, callbacks))
  },
})

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(CriteriaSearcher)
