import { isEmpty } from 'lodash'

export const goodPracticesToOptions = (goodPractices) =>
  goodPractices.map((goodPractice) => ({
    label: `[${goodPractice.ref}] ${goodPractice.title} ${
      goodPractice.is_default ? ' (PAR DEFAUT)' : ''
    }`,
    value: goodPractice.id,
  }))

export const formatGoodPractice = (goodPractice) => {
  if (isEmpty(goodPractice)) {
    return ''
  }
  return `[${goodPractice.ref}] ${goodPractice.title} ${
    goodPractice.is_default ? ' (PAR DEFAUT)' : ''
  }`
}
