import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import Table from 'components/organisms/Table'
import TableHeader from 'components/molecules/TableHeader'
import { TEMPLATE_CREATE_URL } from 'domains/templates/urls'
import {
  templateListSelector,
  templatePaginationSelector,
  templatePerPageSelector,
  templateSearchSelector,
  selectedTemplateSelector,
} from 'domains/templates/selectors'
import {
  getTemplatesAction,
  setTemplatesPerPageAction,
  setSelectedTemplateAction,
  setTemplateSearchAction,
} from 'domains/templates/actions'
import { formatCategories } from 'domains/categories/formatters'
import { formatBrands } from 'domains/brands/formatters'
import { formatGoodPractice } from 'domains/good_practices/formatters'
import WithPadding from 'components/templates/WithPadding'
import Dialog from 'components/templates/Dialog'
import FullTemplate from 'domains/templates/displayers/Full'
import BooleanIcon from 'components/atoms/BooleanIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const columns = [
  { id: 'ref', disablePadding: false, label: 'Référence' },
  { id: 'name', disablePadding: false, label: 'Intitulé' },
  { id: 'status', disablePadding: false, label: 'Status' },
  {
    id: 'confidentiality',
    disablePadding: false,
    label: 'Privé',
  },
  {
    id: 'categories',
    disablePadding: false,
    label: 'Catégories',
  },
  {
    id: 'brands',
    disablePadding: false,
    label: 'Marques',
  },
  { id: 'good_practice', disablePadding: false, label: 'Bonnes Pratiques' },
]

const cellFormat = (item) => [
  item.ref,
  item.name,
  <BooleanIcon value={item.published} />,
  <BooleanIcon value={item.private} />,
  formatCategories(item.categories),
  formatBrands(item.brands),
  formatGoodPractice(item.good_practice),
]

const Index = (props) => {
  const {
    templates,
    selectedTemplate,
    pagination,
    perPage,
    search,
    getTemplates,
    setSelectedTemplate,
    setTemplateSearch,
    setTemplatesPerPage,
  } = props

  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    getTemplates()
  }, [getTemplates])

  const onRowClick = (item) => {
    setSelectedTemplate(item)
    setDialogOpen(true)
  }

  return (
    <WithPadding className={classes.root}>
      <TableHeader
        title="Liste des templates"
        createUrl={TEMPLATE_CREATE_URL()}
        pagination={pagination}
        search={search}
        setItemSearch={setTemplateSearch}
        getItems={getTemplates}
      />
      <Table
        onRowClick={onRowClick}
        items={templates}
        columns={columns}
        cellFormat={cellFormat}
        setItemsPerPage={setTemplatesPerPage}
        getItems={getTemplates}
        pagination={pagination}
        perPage={perPage}
      />
      <Dialog open={dialogOpen} setOpen={setDialogOpen} title="Template">
        <FullTemplate template={selectedTemplate} setOpen={setDialogOpen} />
      </Dialog>
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  templates: templateListSelector(state),
  selectedTemplate: selectedTemplateSelector(state),
  pagination: templatePaginationSelector(state),
  perPage: templatePerPageSelector(state),
  search: templateSearchSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (urlParams, callbacks) =>
    dispatch(getTemplatesAction(urlParams, callbacks)),
  setTemplatesPerPage: (perPage) =>
    dispatch(setTemplatesPerPageAction(perPage)),
  setSelectedTemplate: (template) =>
    dispatch(setSelectedTemplateAction(template)),
  setTemplateSearch: (search) => dispatch(setTemplateSearchAction(search)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Index)
