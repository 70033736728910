import { getBrands } from 'api/domains/public/brands'
import { SET_BRANDS } from './types'

export const setBrandsAction = (payload) => ({
  type: SET_BRANDS,
  payload,
})

export const getBrandsAction =
  (callbacks = {}) =>
  (dispatch) => {
    const onSuccess = async (res) => dispatch(setBrandsAction(res.brands))

    return getBrands({ per_page: 200 })({
      onSuccess,
      ...callbacks,
    })
  }
