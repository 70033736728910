import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Button from 'components/atoms/Button'
import Header from 'components/molecules/Header'
import CriteriaForm from 'components/organisms/CriteriaForm'
import { makeStyles } from '@material-ui/styles'
import {
  setFormElementAction,
  fetchCriteriaToEditAction,
  updateFormCriteriaAction,
} from 'domains/forms/criteria/edit/actions'
import { editCriteriaFormValuesSelector } from 'domains/forms/criteria/edit/selectors'
import ButtonLink from 'components/atoms/ButtonLink'
import WithPadding from 'components/templates/WithPadding'
import WithWarningOnModification from 'components/templates/WithWarningOnModification'

const useStyles = makeStyles({
  root: {
    paddingTop: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
})

const Edit = (props) => {
  const classes = useStyles()
  const {
    formValues,
    computedMatch: {
      params: { criteriaId },
    },
    setFormElement,
    fetchCriteriaToEdit,
    updateCriteria,
  } = props

  const [skipUpdate, setSkipUpdate] = useState(false)

  useEffect(() => {
    fetchCriteriaToEdit(criteriaId)
  }, [fetchCriteriaToEdit, criteriaId])

  const actions = [
    <ButtonLink variant="outlined" to="/criterias/">
      Retour
    </ButtonLink>,
    <Button
      disabled={formValues.updated}
      onClick={() => {
        setSkipUpdate(true)
        updateCriteria()
      }}
    >
      Enregistrer
    </Button>,
  ]

  return (
    <WithPadding className={classes.root}>
      <WithWarningOnModification
        skip={skipUpdate}
        updated={formValues.updated}
      />
      <Header
        title="Modifier un critère"
        alignContent="right"
        actions={actions}
      />
      <CriteriaForm formValues={formValues} setFormElement={setFormElement} />
    </WithPadding>
  )
}

const mapStateToProps = (state) => ({
  formValues: editCriteriaFormValuesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  setFormElement: (element, value) => {
    dispatch(setFormElementAction(element, value))
  },
  fetchCriteriaToEdit: (criteriaId) => {
    dispatch(fetchCriteriaToEditAction(criteriaId))
  },
  updateCriteria: () => {
    dispatch(updateFormCriteriaAction())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
