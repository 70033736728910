import { push } from 'connected-react-router'
import { createReferentialForAudits } from 'api/domains/admin/referential_templates'
import { popSnackbarAction, setLoadingAction } from 'domains/control/actions'
import { linkReferentialToAuditFormValuesSelector } from './selectors'

export const setSelectedTemplateAction = (payload) => ({
  type: 'LinkReferentialToAudits/SET_SELECTED_TEMPLATE',
  payload,
})

export const setSelectedAuditsAction = (payload) => ({
  type: 'LinkReferentialToAudits/SET_SELECTED_AUDITS',
  payload,
})

export const linkAuditsAndTemplateAction = () => (dispatch, getState) => {
  dispatch(setLoadingAction(true))
  const formValues = linkReferentialToAuditFormValuesSelector(getState())
  const templateId = formValues.selected_template.id
  const auditArray = formValues.selected_audits.map((card) => card.id)

  return createReferentialForAudits(templateId, { audit_ids: auditArray })({
    onSuccess: async () => {
      dispatch(setSelectedTemplateAction(null))
      dispatch(setSelectedAuditsAction([]))
      dispatch(push('/templates'))
      dispatch(
        popSnackbarAction(
          'Le référentiel à  été lié aux audits avec succès',
          'success',
        ),
      )
    },
    onAfter: async () => dispatch(setLoadingAction(false)),
  })
}
