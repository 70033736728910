export const getPictoAccessUrl = (url) =>
  `${window.env.REACT_APP_PICTOACCESS_URL}/${url}`

export const openInNewTab = (type, slug) => {
  let ressource = ''
  if (type === 'card') {
    ressource = 'cards'
  } else if (type === 'audit') {
    ressource = 'audits'
  }

  const tab = window.open(getPictoAccessUrl(`${ressource}/${slug}`), '_blank')
  tab.focus()
}
