import React from 'react'
import { Route, Switch as BaseSwitch, Redirect } from 'react-router-dom'
import flattenChildren from 'react-flatten-children'
import CriteriasIndex from 'components/pages/criterias/Index'
import CriteriaCreate from 'components/pages/criterias/Create'
import CriteriaEdit from 'components/pages/criterias/Edit'
import TemplatesIndex from 'components/pages/templates/Index'
import TemplateCreate from 'components/pages/templates/Create'
import TemplateEdit from 'components/pages/templates/Edit'
import AuditMissionsIndex from 'components/pages/audit_missions/Index'
import AuditMissionsCreate from 'components/pages/audit_missions/Create'
import AuditMissionsEdit from 'components/pages/audit_missions/Edit'
import AuditMissionsShow from 'components/pages/audit_missions/Show'
import LinkReferentialToCardsIndex from 'components/pages/card_link/Index'
import LinkReferentialToAuditsIndex from 'components/pages/link_referential_to_audits/Index'
import SignInIndex from 'components/pages/sign_in/Index'
import ProtectedRoute from 'components/atoms/ProtectedRoute'

const Switch = ({ children }) => (
  <BaseSwitch>{flattenChildren(children)}</BaseSwitch>
)

const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/criterias" />} />
    <ProtectedRoute exact path="/criterias" component={CriteriasIndex} />
    <ProtectedRoute path="/criterias/new/" component={CriteriaCreate} />
    <ProtectedRoute
      path="/criterias/:criteriaId/edit/"
      component={CriteriaEdit}
    />
    <ProtectedRoute
      exact
      path="/audit_missions"
      component={AuditMissionsIndex}
    />
    <ProtectedRoute
      path="/audit_missions/new/"
      component={AuditMissionsCreate}
    />
    <ProtectedRoute
      path="/audit_missions/:interventionId/edit"
      component={AuditMissionsEdit}
    />
    <ProtectedRoute
      path="/audit_missions/:interventionId"
      component={AuditMissionsShow}
    />
    <ProtectedRoute exact path="/templates" component={TemplatesIndex} />
    <ProtectedRoute path="/templates/new/" component={TemplateCreate} />
    <ProtectedRoute
      path="/templates/:templateId/edit"
      component={TemplateEdit}
    />
    <ProtectedRoute
      exact
      path="/link-referential-to-cards"
      component={LinkReferentialToCardsIndex}
    />
    <ProtectedRoute
      exact
      path="/link-referential-to-audits"
      component={LinkReferentialToAuditsIndex}
    />
    <Route exact path="/sign_in" component={SignInIndex} />
  </Switch>
)

export default Routes
