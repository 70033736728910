import moment from 'moment'
import { get } from 'lodash'
import 'moment/locale/fr'

moment.locale('fr')

export const formatAuditTitle = (audit) =>
  get(audit, 'audit.content.display_title', null) ||
  get(audit, 'audit.content.title', null)

export const formatAuditCardTitle = (audit) =>
  get(audit, 'card.content.display_title', null) ||
  get(audit, 'card.content.title', null)

export const formatAuditSubmitter = (audit) => {
  if (audit.submitted) {
    return `Le ${moment(audit.submitted_at).format('L')} par "${
      audit.submitted_by
    }"`
  }
  return '-'
}

export const formatAuditModerator = (audit) => {
  if (audit.approved && audit.approved_at) {
    return `Le ${moment(audit.approved_at).format('L')} par ${get(
      audit,
      'approver.email',
      null,
    )}`
  }
  return '-'
}

export const formatChatMessage = (message) => {
  return `${moment(message.postedAt).format('lll')}`
}

export const formatAuditMissionReference = (audit) =>
  get(audit, 'audit_mission.ref', null)

export const hasReferential = (audit) =>
  get(audit, 'audit.accessibility_description.referential.id', null) !== null &&
  get(audit, 'audit.accessibility_description.referential.answers', {})
    .length !== 0
