/* eslint-disable import/prefer-default-export */
export const auditStatusEnum = (status) => {
  switch (status) {
    case 'not_submitted':
      return 'Non soumis'
    case 'in_progress':
      return 'En cours de réalisation'
    case 'waiting_for_review':
      return 'En attente de validation'
    case 'rejected':
      return 'Rejeté'
    case 'accepted':
      return 'Accepté'
    case 'published':
      return 'Publié'
    default:
      return 'Inconnu'
  }
}
