/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { debounce, isEmpty } from 'lodash'
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core'
import Dialog from 'components/templates/Dialog'
import PaperWithPadding from 'components/templates/PaperWithPadding'
import {
  getCardsWithSearchAction,
  setQueryAction,
  setInitialStateAction,
} from 'domains/cards/actions'
import CardSimpleTable from 'domains/cards/displayers/SimpleTable'
import { cardSearchQuerySelector } from 'domains/cards/selectors'
import { loadingSelector } from 'domains/control/selectors'

const useStyles = makeStyles((theme) => ({
  text_field: {
    width: '100%',
  },
  no_results: {
    marginTop: theme.spacing(2),
  },
}))

const DEBOUNCE_TIMER = 1000

const CardSearcher = (props) => {
  const debouncerRef = useRef()
  const {
    open,
    loading,
    query,
    setOpen,
    selectedCards,
    onSelect,
    getCardsWithSearch,
    setCardSearchInitialState,
    setCardSearchQuery,
  } = props
  const {
    search_text,
    subscription_user_email_ucont,
    categories_translations_name_ucont,
    localisation_translations_address_ucont,
  } = query
  const [results, setResults] = useState([])

  const classes = useStyles()
  useEffect(() => {
    setCardSearchInitialState()
  }, [setCardSearchInitialState])

  useEffect(() => {
    debouncerRef.current = debounce(
      (search) =>
        getCardsWithSearch(search, {
          onSuccess: async (res) => setResults(res.cards),
          onEmptyQuery: () => setResults([]),
        }),
      DEBOUNCE_TIMER,
    )
    return () => {
      debouncerRef.current = null
    }
  }, [getCardsWithSearch])

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      setOpen={setOpen}
      title="Trouver un lieu"
    >
      <PaperWithPadding>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item>
            <TextField
              disabled={loading}
              className={classes.text_field}
              label="Rechercher un lieu"
              value={search_text}
              onChange={(e) => {
                const newQuery = { ...query, search_text: e.target.value }
                setCardSearchQuery(newQuery)
                debouncerRef.current(newQuery)
              }}
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              disabled={loading}
              className={classes.text_field}
              label="Email de l'utilisateur possèdant la licence"
              value={subscription_user_email_ucont}
              onChange={(e) => {
                const newQuery = {
                  ...query,
                  subscription_user_email_ucont: e.target.value,
                }
                setCardSearchQuery(newQuery)
                debouncerRef.current(newQuery)
              }}
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              disabled={loading}
              className={classes.text_field}
              label="Catégorie"
              value={categories_translations_name_ucont}
              onChange={(e) => {
                const newQuery = {
                  ...query,
                  categories_translations_name_ucont: e.target.value,
                }
                setCardSearchQuery(newQuery)
                debouncerRef.current(newQuery)
              }}
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              disabled={loading}
              className={classes.text_field}
              label="Adresse (ville, code postale, rue...)"
              value={localisation_translations_address_ucont}
              onChange={(e) => {
                const newQuery = {
                  ...query,
                  localisation_translations_address_ucont: e.target.value,
                }
                setCardSearchQuery(newQuery)
                debouncerRef.current(newQuery)
              }}
              autoFocus
            />
          </Grid>
        </Grid>
        <CardSimpleTable
          cards={results}
          onSelect={onSelect}
          selectedCards={selectedCards}
          multiSelect
        />
        {isEmpty(results) && (
          <Typography className={classes.no_results} align="center">
            Aucun résultat
          </Typography>
        )}
      </PaperWithPadding>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getCardsWithSearch: (query, callbacks) =>
    dispatch(getCardsWithSearchAction(query, callbacks)),
  setCardSearchInitialState: () => dispatch(setInitialStateAction()),
  setCardSearchQuery: (query) => dispatch(setQueryAction(query)),
})

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  query: cardSearchQuerySelector(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardSearcher)
