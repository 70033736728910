import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const initSentry = () => {
  Sentry.init({
    dsn: 'https://39c79d2a3bc4456ebf0cd37325305695@sentry.unisc.fr/13',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_APP_ENV,
    tracesSampleRate: 1.0,
    headers: {
      token_header: 'e4c8be96921e11eba62f0242ac170005',
    },
    ignoreErrors: ['You are not authorized to access this page'],
  })
}

export default initSentry
