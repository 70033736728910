import React from 'react'
import { Table, TableBody, Paper, makeStyles } from '@material-ui/core'
import { sortBy } from 'lodash'
import Head from './Head'
import Row from './Row'
import Pagination from './Pagination'

const useStyles = makeStyles({
  overflowXScroll: { overflowX: 'scroll' },
})

const EnhancedTable = (props) => {
  const {
    items,
    columns,
    pagination,
    cellFormat,
    perPage,
    setItemsPerPage,
    setSelectedItem,
    getItems,
    onRowClick,
  } = props

  const classes = useStyles()

  const getBody = () =>
    sortBy(items, (item) => new Date(item.updated_at).getTime())
      .reverse()
      .map((item) => (
        <Row
          onRowClick={onRowClick}
          key={item.id}
          item={item}
          cellFormat={cellFormat}
          setSelectedItem={setSelectedItem}
        />
      ))

  return (
    items.length > 0 &&
    pagination && (
      <Paper className={classes.overflowXScroll}>
        <Table aria-labelledby="">
          <Head columns={columns} />
          <TableBody>{getBody()}</TableBody>
        </Table>
        <Pagination
          pagination={pagination}
          perPage={perPage}
          getItems={getItems}
          setItemsPerPage={setItemsPerPage}
        />
      </Paper>
    )
  )
}

export default EnhancedTable
