import React from 'react'
import { Typography } from '@material-ui/core'
import { map } from 'lodash'
import { labelAuditMissionEnum } from './enums'

export const getAuditMissionStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'En attente'
    case 'in_progress':
      return 'En cours'
    case 'finish':
      return 'Terminé'
    default:
      return 'Inconnu'
  }
}

export const formatErrorsAuditMissionForm = (title, errors = {}) => {
  const getAllErrors = () => {
    const subErrors = []
    map(Object.keys(errors), (key) =>
      errors[key].forEach((error) => {
        const errorMsg = `"${labelAuditMissionEnum(key)}" ${error}`
        subErrors.push(<Typography key={key}>{errorMsg}</Typography>)
      }),
    )

    return subErrors
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title || ''}
      </Typography>
      {getAllErrors()}
    </div>
  )
}

export const auditMissionToRedux = (mission) => ({
  ...mission,
  selected_cards: map(mission.audits, (audit) => ({
    forbidden_features: audit.forbidden_features,
    ...audit.card,
  })),
})
