import moment from 'moment'
import jwt from 'jwt-simple'
import { get } from 'lodash'

const TOKEN_KEY = 'authToken'
const JWT_SECRET = get(window, 'env.REACT_APP_JWT_SECRET', '123456789')

const getDecodedAuthToken = () => {
  try {
    return jwt.decode(localStorage.getItem(TOKEN_KEY), JWT_SECRET)
  } catch (error) {
    return null
  }
}

export const getXAuthToken = () => {
  const authToken = getDecodedAuthToken()
  return authToken ? authToken.value : null
}

const clearAuthToken = () => localStorage.removeItem(TOKEN_KEY)

export const logOut = async () => clearAuthToken()

export const isLogged = () => {
  const token = getDecodedAuthToken()
  if (
    !token ||
    !token.value ||
    !token.expires_at ||
    moment().isAfter(token.expires_at)
  ) {
    clearAuthToken()
    return false
  }

  return true
}
