import {
  getReferentialTemplates as getTemplates,
  searchReferentialTemplates as searchTemplates,
  duplicateReferentialTemplate as duplicateTemplate,
  deleteReferentialTemplate as deleteTemplate,
  getReferentialTemplateById,
  updateReferentialTemplate,
  createReferentialTemplate,
} from 'api/domains/admin/referential_templates'
import { isEmpty } from 'lodash'
import { push } from 'connected-react-router'
import {
  popConfirmationDialogAction,
  setLoadingAction,
} from 'domains/control/actions'
import {
  SET_TEMPLATES,
  SET_SELECTED_TEMPLATE,
  SET_PER_PAGE,
  SET_SEARCH,
} from './types'
import { templatePerPageSelector, templateSearchSelector } from './selectors'
import { TEMPLATE_EDIT_URL } from './urls'

const firstPage = 1

const setTemplatesAction = (payload) => ({
  type: SET_TEMPLATES,
  payload,
})

export const setSelectedTemplateAction = (payload) => ({
  type: SET_SELECTED_TEMPLATE,
  payload,
})

export const setTemplatesPerPageAction = (payload) => ({
  type: SET_PER_PAGE,
  payload,
})

export const setTemplateSearchAction = (payload) => ({
  type: SET_SEARCH,
  payload,
})

export const getTemplatesAction =
  (urlParams = {}, callbacks = {}) =>
  (dispatch, getState) => {
    const onBefore = async () => dispatch(setLoadingAction(true))
    const onSuccess = async (res) => dispatch(setTemplatesAction(res))
    const onAfter = async () => dispatch(setLoadingAction(false))

    const params = {
      page: urlParams.page || firstPage,
      per_page: urlParams.per_page || templatePerPageSelector(getState()),
    }

    if (!isEmpty(templateSearchSelector(getState()))) {
      params[
        'q[categories_translations_name_or_brands_translations_name_or_translations_name_or_ref_ucont]'
      ] = templateSearchSelector(getState())
      return searchTemplates(params)({
        onSuccess,
        onBefore,
        onAfter,
        ...callbacks,
      })
    }
    return getTemplates(params)({
      onBefore,
      onAfter,
      onSuccess,
      ...callbacks,
    })
  }

export const getTemplatesWithSearchAction = (query, callbacks) => () =>
  searchTemplates({
    'q[categories_translations_name_or_brands_translations_name_or_translations_name_or_ref_ucont]':
      query,
  })(callbacks)

export const getTemplateByIdAction =
  (id, callbacks = {}) =>
  () =>
    getReferentialTemplateById(id)(callbacks)

export const duplicateTemplateAction = (id) => (dispatch) => {
  dispatch(
    popConfirmationDialogAction(
      "Duplication d'un template",
      "Êtes-vous sûr de vouloir dupliquer ce template ? Si vous confirmez, Vous serez directement redirigé vers la page d'édition de celui-ci",
      () => {
        const onSuccess = async (res) =>
          dispatch(push(TEMPLATE_EDIT_URL(res.id)))
        const onBefore = async () => dispatch(setLoadingAction(true))
        const onAfter = async () => dispatch(setLoadingAction(false))
        return duplicateTemplate(id)({ onBefore, onSuccess, onAfter })
      },
      () => null,
    ),
  )
}

export const updateTemplateAction =
  (id, body, callbacks = {}) =>
  () =>
    updateReferentialTemplate(id, { referential_template: body })(callbacks)

export const deleteTemplateAction = (id) => (dispatch) => {
  dispatch(
    popConfirmationDialogAction(
      "Suppression d'un template",
      'Êtes-vous sûr de vouloir supprimer ce template ? \n Cette opération est irréversible',
      () => {
        deleteTemplate(id)({
          onBefore: async () => dispatch(setLoadingAction(true)),
          onSuccess: async () => dispatch(getTemplatesAction()),
          onAfter: async () => dispatch(setLoadingAction(false)),
        })
      },
      () => null,
    ),
  )
}

export const createTemplateAction = (body, callbacks) => () =>
  createReferentialTemplate(body)(callbacks)
