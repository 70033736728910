import { METHOD_GET } from 'api/constants/methods'
import { namespaceFetchWrapper as nsfw } from '.'

const suffix = 'tags'

export const getTags = (query_params = {}) =>
  nsfw(METHOD_GET, suffix, query_params)

export const getTagBySlug = (slug, query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/${slug}`, query_params)

export const getUsedTags = (query_params = {}) =>
  nsfw(METHOD_GET, `${suffix}/used`, query_params)
