import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import TemplatePreview from 'components/organisms/TemplatePreview'
import AttributesForm from './AttributesForm'
import SectionsForm from './SectionsForm'

const useStyles = makeStyles({})

const TemplateForm = (props) => {
  const { formValues, setFormElement } = props
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AttributesForm template={formValues} setFormElement={setFormElement} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SectionsForm
          template={formValues}
          setFormElement={setFormElement}
          className={classes.sectionsForm}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TemplatePreview
          accessibilitySections={formValues.accessibility_sections}
          formValues={formValues}
        />
      </Grid>
    </Grid>
  )
}

export default TemplateForm
