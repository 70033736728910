import moment from 'moment'
import { SET_GOOD_PRACTICES } from './types'

const initialState = {
  list: [],
  expiresAt: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GOOD_PRACTICES:
      return {
        ...state,
        list: action.payload || [],
        expiresAt: moment().add(10, 'm').format(),
      }
    default:
      return state
  }
}

export default reducer
