import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getAuditMissionByIdParamsAction,
  setAuditMissionsPerPageAction,
  setSelectedAuditMissionAction,
  setAuditMissionSearchAction,
} from 'domains/audit_missions/actions'
import { selectedAuditMissionSelector } from 'domains/audit_missions/selectors'
import FullAuditMission from 'domains/audit_missions/displayers/Full'

const Show = (props) => {
  const {
    computedMatch: {
      params: { interventionId },
    },
    selectedAuditMission,
    getAuditMissionById,
  } = props

  useEffect(() => {
    getAuditMissionById(interventionId)
  }, [getAuditMissionById, interventionId])

  return (
    <>
      <FullAuditMission auditMission={selectedAuditMission} />
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedAuditMission: selectedAuditMissionSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  getAuditMissionById: (urlParams, callbacks) =>
    dispatch(
      getAuditMissionByIdParamsAction(urlParams, { view: 'kanban' }, callbacks),
    ),
  setAuditMissionsPerPage: (perPage) =>
    dispatch(setAuditMissionsPerPageAction(perPage)),
  setSelectedAuditMission: (auditMission) =>
    dispatch(setSelectedAuditMissionAction(auditMission)),
  setAuditMissionSearch: (search) =>
    dispatch(setAuditMissionSearchAction(search)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Show)
