/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import React, { useEffect } from 'react'
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  warningTextRed: {
    color: theme.palette.common.red,
  },
  warningTextYellow: {
    color: theme.palette.common.orange,
  },
}))

const PublishAuditDialog = ({
  open,
  handleDialogClose,
  handleDialogConfirm,
}) => {
  const [duplicateAuditChecked, setDuplicateAuditChecked] = React.useState(true)
  const classes = useStyles()

  useEffect(() => {
    setDuplicateAuditChecked(true)
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Publication de l&apos;audit
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1">
            Êtes-vous sûr de vouloir publier cet audit ?
          </Typography>
          <Typography variant="body1" className={classes.warningTextYellow}>
            Les données de l&apos;audit écraserons les données de la page,
            référentiel compris.
          </Typography>
          <Typography variant="body1">
            Nous vous conseillons de prévisualiser l&apos;audit avant de le
            publier.
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={duplicateAuditChecked}
                  onClick={() =>
                    setDuplicateAuditChecked(!duplicateAuditChecked)
                  }
                />
              }
              label="Faire une copie des données de l'établissement dans un audit avant la publication."
            />
          </Typography>

          {!duplicateAuditChecked && (
            <Typography variant="caption" className={classes.warningTextRed}>
              Attention, en décochant cette option, aucune copie des données de
              l&apos;état actuel de l&apos;établissement ne sera sauvegardé
              (photos, référentiel, etc.)
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDialogClose}>
          Annuler
        </Button>
        <Button
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleDialogConfirm.bind(null, duplicateAuditChecked)}
          color="secondary"
          variant="contained"
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PublishAuditDialog
