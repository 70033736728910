import { createTheme } from '@material-ui/core/styles'
import { amber } from '@material-ui/core/colors'

export default createTheme({
  palette: {
    primary: {
      main: '#81c25d',
      light: '#9ace7d',
      dark: '#5a8741',
      contrastText: '#FFF',
    },
    secondary: amber,
    common: {
      yellow: '#FFE500',
      lightYellow: '#FFF067',
      orange: '#FF7F00',
      green: '#00FFA3',
      red: '#FF3981',
      blue: '#4DFFFF',
      black: '#000000',
      white: '#FFFFFF',
      greys: {
        veryDark: '#2D2D2D',
        dark: '#464646',
        medium: '#414141',
        mediumLight: '#686868',
        light: '#737373',
        lighter: '#F3F3F3',
        veryLight: '#FCFCFC',
      },
    },
    error: {
      main: '#f92515',
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiPaper: {
      elevation: 4,
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: { padding: 16 },
    },
    MuiPaper: {
      root: {
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
      },
      rounded: {
        borderRadius: 4,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 20,
        padding: '12px 24px',
        lineHeight: '20px',
      },
      contained: {
        boxShadow: '0px 8px 30px rgba(0,0,0, 0.1)',
      },
      label: {
        textTransform: 'none',
        fontWeight: 'bold',
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: '8px',
        fontSize: '0.725rem',
        fontWeight: 550,
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0px 25px 40px rgba(0,0,0, 0.1)',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
})
