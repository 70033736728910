const ApiCommonBaseError = (error) => Error(`API - ${error}`)
export const ParamTypeError = (key) =>
  ApiCommonBaseError(
    `Le paramètre "${key}" est invalide, un paramètre ne peut pas être une fonction ou un objet`,
  )
export const CallbackTypeError = (key) =>
  ApiCommonBaseError(`Le callback "${key}" n'est pas une Promise`)

export const BodyNotObjectError = (type) =>
  ApiCommonBaseError(
    `Le "body" de la requête doit être un Object, reçu : "${type}"`,
  )

export const logError = (msg) => console.error(msg)
